import { useConfig } from '@seek/libs-shared';
import { useMelwaysLink } from '@seek/melways-react';
import { useTranslations } from '@vocab/react';
import {
  Box,
  Card,
  Columns,
  Column,
  Inline,
  Rating,
  Strong,
  Text,
} from 'braid-design-system';
import { useNavigate } from 'react-router';
import { ROOT_PATH } from '../../paths';
import translations from './.vocab';

export const CompanySearchCard = ({
  name,
  logo,
  averageCompanyRating,
  reviewsCount,
  slug,
}: {
  name: string;
  logo: string;
  averageCompanyRating: number;
  reviewsCount: number;
  slug: string;
}) => {
  const { t } = useTranslations(translations);
  const navigate = useNavigate();
  const { language } = useConfig();
  const localize = useMelwaysLink();

  return (
    <Box
      onClick={() => {
        window.scroll(0, 0);
        navigate(
          `${localize({
            path: `/${ROOT_PATH}/${slug}`,
            language,
          })}`,
        );
      }}
      cursor="pointer"
    >
      <Card>
        <Columns
          space={['medium', 'large']}
          alignY="center"
          collapseBelow="tablet"
        >
          <Column width="content">
            <img
              src={logo}
              style={{
                width: '100px',
                height: '65px',
                objectFit: 'contain',
              }}
            />
          </Column>
          <Column>
            <Box style={{ maxWidth: '328px' }}>
              <Text weight="strong">{name}</Text>
            </Box>
          </Column>
          <Column width="content">
            {reviewsCount > 2 ? (
              <Inline space="xxsmall" alignY="center">
                <Rating
                  rating={averageCompanyRating}
                  variant="minimal"
                  size="small"
                />
                <Text size="small">
                  <Strong>&middot;</Strong>
                </Text>
                <Text size="small">
                  {t('reviewsCount reviews', {
                    reviewsCount: reviewsCount.toString(),
                  })}
                </Text>
              </Inline>
            ) : null}
          </Column>
        </Columns>
      </Card>
    </Box>
  );
};
