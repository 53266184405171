import {
  type ApolloClient,
  ApolloProvider,
  type NormalizedCacheObject,
} from '@apollo/client';
import {
  type Logger,
  LoggerProvider,
  ConfigProvider,
  AppContextProvider,
} from '@seek/libs-shared';
import { VocabProvider } from '@vocab/react';
import { BraidProvider } from 'braid-design-system';
import { MelwaysProvider } from '@seek/melways-react';
import theme from 'braid-design-system/themes/seekJobs';
import { Outlet } from 'react-router-dom';

import { CustomLink } from './shared/components/CustomLink';
import { AuthProvider } from './shared/providers/authProvider';
import { ServiceProvider } from './shared/providers/serviceProvider';
import type { Services } from './shared/services';
import type { AppConfig } from './shared/config';
import { FeatureFlagProvider } from './shared/providers/featureFlagProvider';

interface AppProps {
  client: ApolloClient<NormalizedCacheObject>;
  config: AppConfig;
  services: Services;
  logger: Logger;
}

export const App = ({ client, config, services, logger }: AppProps) => (
  <ConfigProvider config={config}>
    <MelwaysProvider.Server
      site={config.siteName as any}
      url={config.absoluteUrl}
      alternative={config.consolidated ? 'subDomainRegion' : undefined}
      staging={config.environment === 'staging'}
    >
      <LoggerProvider logger={logger}>
        <ApolloProvider client={client}>
          <ServiceProvider services={services}>
            <AppContextProvider>
              <AuthProvider>
                <FeatureFlagProvider>
                  <BraidProvider theme={theme} linkComponent={CustomLink}>
                    <VocabProvider
                      language={config.language}
                      locale={config.locale}
                    >
                      <Outlet context={{ client, config }} />
                    </VocabProvider>
                  </BraidProvider>
                </FeatureFlagProvider>
              </AuthProvider>
            </AppContextProvider>
          </ServiceProvider>
        </ApolloProvider>
      </LoggerProvider>
    </MelwaysProvider.Server>
  </ConfigProvider>
);
