
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3JrL0xpZmUgYmFsYW5jZSI6IldvcmsvTGlmZSBiYWxhbmNlIiwiQ2FyZWVyIGRldmVsb3BtZW50IjoiQ2FyZWVyIGRldmVsb3BtZW50IiwiQmVuZWZpdHMgJiBwZXJrcyI6IkJlbmVmaXRzICYgcGVya3MiLCJNYW5hZ2VtZW50IjoiTWFuYWdlbWVudCIsIldvcmtpbmcgZW52aXJvbm1lbnQiOiJXb3JraW5nIGVudmlyb25tZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3JrL0xpZmUgYmFsYW5jZSI6IldvcmsvTGlmZSBiYWxhbmNlIiwiQ2FyZWVyIGRldmVsb3BtZW50IjoiQ2FyZWVyIGRldmVsb3BtZW50IiwiQmVuZWZpdHMgJiBwZXJrcyI6IkJlbmVmaXRzICYgcGVya3MiLCJNYW5hZ2VtZW50IjoiTWFuYWdlbWVudCIsIldvcmtpbmcgZW52aXJvbm1lbnQiOiJXb3JraW5nIGVudmlyb25tZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3JrL0xpZmUgYmFsYW5jZSI6IuC5g+C4meC5geC4meC4p+C4hOC4tOC4lOC4hOC4p+C4suC4oeC4quC4oeC4lOC4uOC4peC5g+C4meC4iuC4teC4p+C4tOC4leC5geC4peC4sOC4geC4suC4o+C4l+C5jeC4suC4h+C4suC4meC4guC4reC4h+C5gOC4o+C4siIsIkNhcmVlciBkZXZlbG9wbWVudCI6IuC4hOC4p+C4suC4oeC4geC5ieC4suC4p+C4q+C4meC5ieC4suC4l+C4suC4h+C4reC4suC4iuC4teC4niIsIkJlbmVmaXRzICYgcGVya3MiOiLguKrguKfguLHguKrguJTguLTguIHguLLguKPguYHguKXguLDguJzguKXguJvguKPguLDguYLguKLguIrguJnguYwiLCJNYW5hZ2VtZW50Ijoi4LiB4Liy4Lij4LiI4Lix4LiU4LiB4Liy4LijIiwiV29ya2luZyBlbnZpcm9ubWVudCI6IuC4muC4o+C4o+C4ouC4suC4geC4suC4qOC5g+C4meC4geC4suC4o+C4l+C4s+C4h+C4suC4mSIsIkRpdmVyc2l0eSAmIGVxdWFsIG9wcG9ydHVuaXR5Ijoi4LiE4Lin4Liy4Lih4Lir4Lil4Liy4LiB4Lir4Lil4Liy4Lii4LmB4Lil4Liw4LmC4Lit4LiB4Liy4Liq4LiX4Li14LmI4LmA4LiX4LmI4Liy4LmA4LiX4Li14Lii4Lih4LiB4Lix4LiZIn0=!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3JrL0xpZmUgYmFsYW5jZSI6IuC5g+C4meC5geC4meC4p+C4hOC4tOC4lOC4hOC4p+C4suC4oeC4quC4oeC4lOC4uOC4peC5g+C4meC4iuC4teC4p+C4tOC4leC5geC4peC4sOC4geC4suC4o+C4l+C5jeC4suC4h+C4suC4meC4guC4reC4h+C5gOC4o+C4siIsIkNhcmVlciBkZXZlbG9wbWVudCI6IuC4hOC4p+C4suC4oeC4geC5ieC4suC4p+C4q+C4meC5ieC4suC4l+C4suC4h+C4reC4suC4iuC4teC4niIsIkJlbmVmaXRzICYgcGVya3MiOiLguKrguKfguLHguKrguJTguLTguIHguLLguKPguYHguKXguLDguJzguKXguJvguKPguLDguYLguKLguIrguJnguYwiLCJNYW5hZ2VtZW50Ijoi4LiB4Liy4Lij4LiI4Lix4LiU4LiB4Liy4LijIiwiV29ya2luZyBlbnZpcm9ubWVudCI6IuC4muC4o+C4o+C4ouC4suC4geC4suC4qOC5g+C4meC4geC4suC4o+C4l+C4s+C4h+C4suC4mSIsIkRpdmVyc2l0eSAmIGVxdWFsIG9wcG9ydHVuaXR5Ijoi4LiE4Lin4Liy4Lih4Lir4Lil4Liy4LiB4Lir4Lil4Liy4Lii4LmB4Lil4Liw4LmC4Lit4LiB4Liy4Liq4LiX4Li14LmI4LmA4LiX4LmI4Liy4LmA4LiX4Li14Lii4Lih4LiB4Lix4LiZIn0=!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3JrL0xpZmUgYmFsYW5jZSI6Iktlc2VpbWJhbmdhbiBoaWR1cCBkYW4gcGVrZXJqYWFuIiwiQ2FyZWVyIGRldmVsb3BtZW50IjoiUGVuZ2VtYmFuZ2FuIGthcmllciIsIkJlbmVmaXRzICYgcGVya3MiOiJUdW5qYW5nYW4gJiBrZXVudHVuZ2FuIiwiTWFuYWdlbWVudCI6IlBlbmdlbG9sYWFuIiwiV29ya2luZyBlbnZpcm9ubWVudCI6IkxpbmdrdW5nYW4ga2VyamEiLCJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSI6IktlYmVyYWdhbWFuICYga2VzZW1wYXRhbiB5YW5nIHNhbWEifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3JrL0xpZmUgYmFsYW5jZSI6Iktlc2VpbWJhbmdhbiBoaWR1cCBkYW4gcGVrZXJqYWFuIiwiQ2FyZWVyIGRldmVsb3BtZW50IjoiUGVuZ2VtYmFuZ2FuIGthcmllciIsIkJlbmVmaXRzICYgcGVya3MiOiJUdW5qYW5nYW4gJiBrZXVudHVuZ2FuIiwiTWFuYWdlbWVudCI6IlBlbmdlbG9sYWFuIiwiV29ya2luZyBlbnZpcm9ubWVudCI6IkxpbmdrdW5nYW4ga2VyamEiLCJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSI6IktlYmVyYWdhbWFuICYga2VzZW1wYXRhbiB5YW5nIHNhbWEifQ==!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3JrL0xpZmUgYmFsYW5jZSI6IldvcmsvTGlmZSBiYWxhbmNlIiwiQ2FyZWVyIGRldmVsb3BtZW50IjoiQ2FyZWVyIGRldmVsb3BtZW50IiwiQmVuZWZpdHMgJiBwZXJrcyI6IkJlbmVmaXRzICYgcGVya3MiLCJNYW5hZ2VtZW50IjoiTWFuYWdlbWVudCIsIldvcmtpbmcgZW52aXJvbm1lbnQiOiJXb3JraW5nIGVudmlyb25tZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3JrL0xpZmUgYmFsYW5jZSI6IldvcmsvTGlmZSBiYWxhbmNlIiwiQ2FyZWVyIGRldmVsb3BtZW50IjoiQ2FyZWVyIGRldmVsb3BtZW50IiwiQmVuZWZpdHMgJiBwZXJrcyI6IkJlbmVmaXRzICYgcGVya3MiLCJNYW5hZ2VtZW50IjoiTWFuYWdlbWVudCIsIldvcmtpbmcgZW52aXJvbm1lbnQiOiJXb3JraW5nIGVudmlyb25tZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3JrL0xpZmUgYmFsYW5jZSI6IldvcmsvTGlmZSBiYWxhbmNlIiwiQ2FyZWVyIGRldmVsb3BtZW50IjoiQ2FyZWVyIGRldmVsb3BtZW50IiwiQmVuZWZpdHMgJiBwZXJrcyI6IkJlbmVmaXRzICYgcGVya3MiLCJNYW5hZ2VtZW50IjoiTWFuYWdlbWVudCIsIldvcmtpbmcgZW52aXJvbm1lbnQiOiJXb3JraW5nIGVudmlyb25tZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3JrL0xpZmUgYmFsYW5jZSI6IldvcmsvTGlmZSBiYWxhbmNlIiwiQ2FyZWVyIGRldmVsb3BtZW50IjoiQ2FyZWVyIGRldmVsb3BtZW50IiwiQmVuZWZpdHMgJiBwZXJrcyI6IkJlbmVmaXRzICYgcGVya3MiLCJNYW5hZ2VtZW50IjoiTWFuYWdlbWVudCIsIldvcmtpbmcgZW52aXJvbm1lbnQiOiJXb3JraW5nIGVudmlyb25tZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3JrL0xpZmUgYmFsYW5jZSI6IldvcmsvTGlmZSBiYWxhbmNlIiwiQ2FyZWVyIGRldmVsb3BtZW50IjoiQ2FyZWVyIGRldmVsb3BtZW50IiwiQmVuZWZpdHMgJiBwZXJrcyI6IkJlbmVmaXRzICYgcGVya3MiLCJNYW5hZ2VtZW50IjoiTWFuYWdlbWVudCIsIldvcmtpbmcgZW52aXJvbm1lbnQiOiJXb3JraW5nIGVudmlyb25tZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3JrL0xpZmUgYmFsYW5jZSI6IldvcmsvTGlmZSBiYWxhbmNlIiwiQ2FyZWVyIGRldmVsb3BtZW50IjoiQ2FyZWVyIGRldmVsb3BtZW50IiwiQmVuZWZpdHMgJiBwZXJrcyI6IkJlbmVmaXRzICYgcGVya3MiLCJNYW5hZ2VtZW50IjoiTWFuYWdlbWVudCIsIldvcmtpbmcgZW52aXJvbm1lbnQiOiJXb3JraW5nIGVudmlyb25tZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3JrL0xpZmUgYmFsYW5jZSI6IldvcmsvTGlmZSBiYWxhbmNlIiwiQ2FyZWVyIGRldmVsb3BtZW50IjoiQ2FyZWVyIGRldmVsb3BtZW50IiwiQmVuZWZpdHMgJiBwZXJrcyI6IkJlbmVmaXRzICYgcGVya3MiLCJNYW5hZ2VtZW50IjoiTWFuYWdlbWVudCIsIldvcmtpbmcgZW52aXJvbm1lbnQiOiJXb3JraW5nIGVudmlyb25tZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3JrL0xpZmUgYmFsYW5jZSI6IldvcmsvTGlmZSBiYWxhbmNlIiwiQ2FyZWVyIGRldmVsb3BtZW50IjoiQ2FyZWVyIGRldmVsb3BtZW50IiwiQmVuZWZpdHMgJiBwZXJrcyI6IkJlbmVmaXRzICYgcGVya3MiLCJNYW5hZ2VtZW50IjoiTWFuYWdlbWVudCIsIldvcmtpbmcgZW52aXJvbm1lbnQiOiJXb3JraW5nIGVudmlyb25tZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3JrL0xpZmUgYmFsYW5jZSI6IldvcmsvTGlmZSBiYWxhbmNlIiwiQ2FyZWVyIGRldmVsb3BtZW50IjoiQ2FyZWVyIGRldmVsb3BtZW50IiwiQmVuZWZpdHMgJiBwZXJrcyI6IkJlbmVmaXRzICYgcGVya3MiLCJNYW5hZ2VtZW50IjoiTWFuYWdlbWVudCIsIldvcmtpbmcgZW52aXJvbm1lbnQiOiJXb3JraW5nIGVudmlyb25tZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3JrL0xpZmUgYmFsYW5jZSI6IldvcmsvTGlmZSBiYWxhbmNlIiwiQ2FyZWVyIGRldmVsb3BtZW50IjoiQ2FyZWVyIGRldmVsb3BtZW50IiwiQmVuZWZpdHMgJiBwZXJrcyI6IkJlbmVmaXRzICYgcGVya3MiLCJNYW5hZ2VtZW50IjoiTWFuYWdlbWVudCIsIldvcmtpbmcgZW52aXJvbm1lbnQiOiJXb3JraW5nIGVudmlyb25tZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3JrL0xpZmUgYmFsYW5jZSI6IldvcmsvTGlmZSBiYWxhbmNlIiwiQ2FyZWVyIGRldmVsb3BtZW50IjoiQ2FyZWVyIGRldmVsb3BtZW50IiwiQmVuZWZpdHMgJiBwZXJrcyI6IkJlbmVmaXRzICYgcGVya3MiLCJNYW5hZ2VtZW50IjoiTWFuYWdlbWVudCIsIldvcmtpbmcgZW52aXJvbm1lbnQiOiJXb3JraW5nIGVudmlyb25tZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3JrL0xpZmUgYmFsYW5jZSI6IldvcmsvTGlmZSBiYWxhbmNlIiwiQ2FyZWVyIGRldmVsb3BtZW50IjoiQ2FyZWVyIGRldmVsb3BtZW50IiwiQmVuZWZpdHMgJiBwZXJrcyI6IkJlbmVmaXRzICYgcGVya3MiLCJNYW5hZ2VtZW50IjoiTWFuYWdlbWVudCIsIldvcmtpbmcgZW52aXJvbm1lbnQiOiJXb3JraW5nIGVudmlyb25tZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3JrL0xpZmUgYmFsYW5jZSI6IldvcmsvTGlmZSBiYWxhbmNlIiwiQ2FyZWVyIGRldmVsb3BtZW50IjoiQ2FyZWVyIGRldmVsb3BtZW50IiwiQmVuZWZpdHMgJiBwZXJrcyI6IkJlbmVmaXRzICYgcGVya3MiLCJNYW5hZ2VtZW50IjoiTWFuYWdlbWVudCIsIldvcmtpbmcgZW52aXJvbm1lbnQiOiJXb3JraW5nIGVudmlyb25tZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3JrL0xpZmUgYmFsYW5jZSI6IldvcmsvTGlmZSBiYWxhbmNlIiwiQ2FyZWVyIGRldmVsb3BtZW50IjoiQ2FyZWVyIGRldmVsb3BtZW50IiwiQmVuZWZpdHMgJiBwZXJrcyI6IkJlbmVmaXRzICYgcGVya3MiLCJNYW5hZ2VtZW50IjoiTWFuYWdlbWVudCIsIldvcmtpbmcgZW52aXJvbm1lbnQiOiJXb3JraW5nIGVudmlyb25tZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3JrL0xpZmUgYmFsYW5jZSI6IldvcmsvTGlmZSBiYWxhbmNlIiwiQ2FyZWVyIGRldmVsb3BtZW50IjoiQ2FyZWVyIGRldmVsb3BtZW50IiwiQmVuZWZpdHMgJiBwZXJrcyI6IkJlbmVmaXRzICYgcGVya3MiLCJNYW5hZ2VtZW50IjoiTWFuYWdlbWVudCIsIldvcmtpbmcgZW52aXJvbm1lbnQiOiJXb3JraW5nIGVudmlyb25tZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3JrL0xpZmUgYmFsYW5jZSI6IldvcmsvTGlmZSBiYWxhbmNlIiwiQ2FyZWVyIGRldmVsb3BtZW50IjoiQ2FyZWVyIGRldmVsb3BtZW50IiwiQmVuZWZpdHMgJiBwZXJrcyI6IkJlbmVmaXRzICYgcGVya3MiLCJNYW5hZ2VtZW50IjoiTWFuYWdlbWVudCIsIldvcmtpbmcgZW52aXJvbm1lbnQiOiJXb3JraW5nIGVudmlyb25tZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3JrL0xpZmUgYmFsYW5jZSI6IldvcmsvTGlmZSBiYWxhbmNlIiwiQ2FyZWVyIGRldmVsb3BtZW50IjoiQ2FyZWVyIGRldmVsb3BtZW50IiwiQmVuZWZpdHMgJiBwZXJrcyI6IkJlbmVmaXRzICYgcGVya3MiLCJNYW5hZ2VtZW50IjoiTWFuYWdlbWVudCIsIldvcmtpbmcgZW52aXJvbm1lbnQiOiJXb3JraW5nIGVudmlyb25tZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3JrL0xpZmUgYmFsYW5jZSI6IldvcmsvTGlmZSBiYWxhbmNlIiwiQ2FyZWVyIGRldmVsb3BtZW50IjoiQ2FyZWVyIGRldmVsb3BtZW50IiwiQmVuZWZpdHMgJiBwZXJrcyI6IkJlbmVmaXRzICYgcGVya3MiLCJNYW5hZ2VtZW50IjoiTWFuYWdlbWVudCIsIldvcmtpbmcgZW52aXJvbm1lbnQiOiJXb3JraW5nIGVudmlyb25tZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3JrL0xpZmUgYmFsYW5jZSI6IuC5g+C4meC5geC4meC4p+C4hOC4tOC4lOC4hOC4p+C4suC4oeC4quC4oeC4lOC4uOC4peC5g+C4meC4iuC4teC4p+C4tOC4leC5geC4peC4sOC4geC4suC4o+C4l+C5jeC4suC4h+C4suC4meC4guC4reC4h+C5gOC4o+C4siIsIkNhcmVlciBkZXZlbG9wbWVudCI6IuC4hOC4p+C4suC4oeC4geC5ieC4suC4p+C4q+C4meC5ieC4suC4l+C4suC4h+C4reC4suC4iuC4teC4niIsIkJlbmVmaXRzICYgcGVya3MiOiLguKrguKfguLHguKrguJTguLTguIHguLLguKPguYHguKXguLDguJzguKXguJvguKPguLDguYLguKLguIrguJnguYwiLCJNYW5hZ2VtZW50Ijoi4LiB4Liy4Lij4LiI4Lix4LiU4LiB4Liy4LijIiwiV29ya2luZyBlbnZpcm9ubWVudCI6IuC4muC4o+C4o+C4ouC4suC4geC4suC4qOC5g+C4meC4geC4suC4o+C4l+C4s+C4h+C4suC4mSIsIkRpdmVyc2l0eSAmIGVxdWFsIG9wcG9ydHVuaXR5Ijoi4LiE4Lin4Liy4Lih4Lir4Lil4Liy4LiB4Lir4Lil4Liy4Lii4LmB4Lil4Liw4LmC4Lit4LiB4Liy4Liq4LiX4Li14LmI4LmA4LiX4LmI4Liy4LmA4LiX4Li14Lii4Lih4LiB4Lix4LiZIn0=!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3JrL0xpZmUgYmFsYW5jZSI6IuC5g+C4meC5geC4meC4p+C4hOC4tOC4lOC4hOC4p+C4suC4oeC4quC4oeC4lOC4uOC4peC5g+C4meC4iuC4teC4p+C4tOC4leC5geC4peC4sOC4geC4suC4o+C4l+C5jeC4suC4h+C4suC4meC4guC4reC4h+C5gOC4o+C4siIsIkNhcmVlciBkZXZlbG9wbWVudCI6IuC4hOC4p+C4suC4oeC4geC5ieC4suC4p+C4q+C4meC5ieC4suC4l+C4suC4h+C4reC4suC4iuC4teC4niIsIkJlbmVmaXRzICYgcGVya3MiOiLguKrguKfguLHguKrguJTguLTguIHguLLguKPguYHguKXguLDguJzguKXguJvguKPguLDguYLguKLguIrguJnguYwiLCJNYW5hZ2VtZW50Ijoi4LiB4Liy4Lij4LiI4Lix4LiU4LiB4Liy4LijIiwiV29ya2luZyBlbnZpcm9ubWVudCI6IuC4muC4o+C4o+C4ouC4suC4geC4suC4qOC5g+C4meC4geC4suC4o+C4l+C4s+C4h+C4suC4mSIsIkRpdmVyc2l0eSAmIGVxdWFsIG9wcG9ydHVuaXR5Ijoi4LiE4Lin4Liy4Lih4Lir4Lil4Liy4LiB4Lir4Lil4Liy4Lii4LmB4Lil4Liw4LmC4Lit4LiB4Liy4Liq4LiX4Li14LmI4LmA4LiX4LmI4Liy4LmA4LiX4Li14Lii4Lih4LiB4Lix4LiZIn0=!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3JrL0xpZmUgYmFsYW5jZSI6Iktlc2VpbWJhbmdhbiBoaWR1cCBkYW4gcGVrZXJqYWFuIiwiQ2FyZWVyIGRldmVsb3BtZW50IjoiUGVuZ2VtYmFuZ2FuIGthcmllciIsIkJlbmVmaXRzICYgcGVya3MiOiJUdW5qYW5nYW4gJiBrZXVudHVuZ2FuIiwiTWFuYWdlbWVudCI6IlBlbmdlbG9sYWFuIiwiV29ya2luZyBlbnZpcm9ubWVudCI6IkxpbmdrdW5nYW4ga2VyamEiLCJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSI6IktlYmVyYWdhbWFuICYga2VzZW1wYXRhbiB5YW5nIHNhbWEifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3JrL0xpZmUgYmFsYW5jZSI6Iktlc2VpbWJhbmdhbiBoaWR1cCBkYW4gcGVrZXJqYWFuIiwiQ2FyZWVyIGRldmVsb3BtZW50IjoiUGVuZ2VtYmFuZ2FuIGthcmllciIsIkJlbmVmaXRzICYgcGVya3MiOiJUdW5qYW5nYW4gJiBrZXVudHVuZ2FuIiwiTWFuYWdlbWVudCI6IlBlbmdlbG9sYWFuIiwiV29ya2luZyBlbnZpcm9ubWVudCI6IkxpbmdrdW5nYW4ga2VyamEiLCJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSI6IktlYmVyYWdhbWFuICYga2VzZW1wYXRhbiB5YW5nIHNhbWEifQ==!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3JrL0xpZmUgYmFsYW5jZSI6IldvcmsvTGlmZSBiYWxhbmNlIiwiQ2FyZWVyIGRldmVsb3BtZW50IjoiQ2FyZWVyIGRldmVsb3BtZW50IiwiQmVuZWZpdHMgJiBwZXJrcyI6IkJlbmVmaXRzICYgcGVya3MiLCJNYW5hZ2VtZW50IjoiTWFuYWdlbWVudCIsIldvcmtpbmcgZW52aXJvbm1lbnQiOiJXb3JraW5nIGVudmlyb25tZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3JrL0xpZmUgYmFsYW5jZSI6IldvcmsvTGlmZSBiYWxhbmNlIiwiQ2FyZWVyIGRldmVsb3BtZW50IjoiQ2FyZWVyIGRldmVsb3BtZW50IiwiQmVuZWZpdHMgJiBwZXJrcyI6IkJlbmVmaXRzICYgcGVya3MiLCJNYW5hZ2VtZW50IjoiTWFuYWdlbWVudCIsIldvcmtpbmcgZW52aXJvbm1lbnQiOiJXb3JraW5nIGVudmlyb25tZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3JrL0xpZmUgYmFsYW5jZSI6IlvFtMO2w7bDtsWZxLcvxYHDrMOsw6zGkuG6veG6veG6vSDDn8SDxIPEg8aaxIPEg8SD4LiB4Li14LmJw6fhur3hur3hur1dIiwiQ2FyZWVyIGRldmVsb3BtZW50IjoiW8OHxIPEg8SDxZnhur3hur3hur3hur3hur3hur3FmSDGjOG6veG6veG6veG5veG6veG6veG6vcaaw7bDtsO2xqVtzILhur3hur3hur3guIHguLXguYnhua9dIiwiQmVuZWZpdHMgJiBwZXJrcyI6Ilvhup7hur3hur3hur3guIHguLXguYnhur3hur3hur3GksOsw6zDrOG5r8WhICYgxqXhur3hur3hur3FmcS3xaFdIiwiTWFuYWdlbWVudCI6IlvhuYLEg8SD4LiB4Li14LmJxIPEg8Sj4bq94bq9bcyC4bq94bq94LiB4Li14LmJ4bmvXSIsIldvcmtpbmcgZW52aXJvbm1lbnQiOiJbxbTDtsO2w7bFmcS3w6zDrMOs4LiB4Li14LmJxKMg4bq94bq94bq94LiB4Li14LmJ4bm9w6zDrMOsxZnDtsO2w7bguIHguLXguYltzILhur3hur3hur3guIHguLXguYnhua9dIiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJb4biKw6zDrOG5veG6veG6vcWZxaHDrMOs4bmvw73DvSAmIOG6veG6vXHMh8eax5rEg8SDxpogw7bDtsalxqXDtsO2xZnhua/Hmsea4LiB4Li14LmJw6zDrOG5r8O9w71dIn0=!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3JrL0xpZmUgYmFsYW5jZSI6IlvFtMO2w7bDtsWZxLcvxYHDrMOsw6zGkuG6veG6veG6vSDDn8SDxIPEg8aaxIPEg8SD4LiB4Li14LmJw6fhur3hur3hur1dIiwiQ2FyZWVyIGRldmVsb3BtZW50IjoiW8OHxIPEg8SDxZnhur3hur3hur3hur3hur3hur3FmSDGjOG6veG6veG6veG5veG6veG6veG6vcaaw7bDtsO2xqVtzILhur3hur3hur3guIHguLXguYnhua9dIiwiQmVuZWZpdHMgJiBwZXJrcyI6Ilvhup7hur3hur3hur3guIHguLXguYnhur3hur3hur3GksOsw6zDrOG5r8WhICYgxqXhur3hur3hur3FmcS3xaFdIiwiTWFuYWdlbWVudCI6IlvhuYLEg8SD4LiB4Li14LmJxIPEg8Sj4bq94bq9bcyC4bq94bq94LiB4Li14LmJ4bmvXSIsIldvcmtpbmcgZW52aXJvbm1lbnQiOiJbxbTDtsO2w7bFmcS3w6zDrMOs4LiB4Li14LmJxKMg4bq94bq94bq94LiB4Li14LmJ4bm9w6zDrMOsxZnDtsO2w7bguIHguLXguYltzILhur3hur3hur3guIHguLXguYnhua9dIiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJb4biKw6zDrOG5veG6veG6vcWZxaHDrMOs4bmvw73DvSAmIOG6veG6vXHMh8eax5rEg8SDxpogw7bDtsalxqXDtsO2xZnhua/Hmsea4LiB4Li14LmJw6zDrOG5r8O9w71dIn0=!"
        )
      )
      });
  
      export { translations as default };
    