import { Helmet } from 'react-helmet';
import type { MetaData } from './meta';

export const MetaTag = ({
  title,
  description,
  companyProfileImage,
  absoluteUrl,
  shouldDisableIndexing,
}: MetaData) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    {companyProfileImage && (
      <meta property="og:image" content={companyProfileImage} />
    )}
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    {shouldDisableIndexing && (
      <meta name="robots" content="noindex, noarchive" />
    )}
    <link rel="canonical" href={absoluteUrl} />
  </Helmet>
);
