
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiOiJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiLCJFdmVyeXRoaW5nIHlvdSBuZWVkIHRvIGtub3cgYWJvdXQgYSBjb21wYW55LCBhbGwgaW4gb25lIHBsYWNlIjoiRXZlcnl0aGluZyB5b3UgbmVlZCB0byBrbm93IGFib3V0IGEgY29tcGFueSwgYWxsIGluIG9uZSBwbGFjZSIsIk1vcmUgY29tcGFuaWVzIGFkZGVkIGRhaWx5IjoiTW9yZSBjb21wYW5pZXMgYWRkZWQgZGFpbHkifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiOiJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiLCJFdmVyeXRoaW5nIHlvdSBuZWVkIHRvIGtub3cgYWJvdXQgYSBjb21wYW55LCBhbGwgaW4gb25lIHBsYWNlIjoiRXZlcnl0aGluZyB5b3UgbmVlZCB0byBrbm93IGFib3V0IGEgY29tcGFueSwgYWxsIGluIG9uZSBwbGFjZSIsIk1vcmUgY29tcGFuaWVzIGFkZGVkIGRhaWx5IjoiTW9yZSBjb21wYW5pZXMgYWRkZWQgZGFpbHkifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiOiLguITguYnguJnguKvguLLguJrguKPguLTguKnguLHguJfguJfguLXguYjguYPguIrguYjguKrguLPguKvguKPguLHguJrguITguLjguJMiLCJFdmVyeXRoaW5nIHlvdSBuZWVkIHRvIGtub3cgYWJvdXQgYSBjb21wYW55LCBhbGwgaW4gb25lIHBsYWNlIjoi4LiX4Li44LiB4Liq4Li04LmI4LiH4LiX4Li14LmI4LiE4Li44LiT4LiI4Liz4LmA4Lib4LmH4LiZ4LiV4LmJ4Lit4LiH4LiX4Lij4Liy4Lia4LmA4LiB4Li14LmI4Lii4Lin4LiB4Lix4Lia4Lia4Lij4Li04Lip4Lix4LiXIOC4o+C4p+C4oeC5hOC4p+C5ieC5g+C4meC4l+C4teC5iOC5gOC4lOC4teC4ouC4pyIsIk1vcmUgY29tcGFuaWVzIGFkZGVkIGRhaWx5IjoiTW9yZSBjb21wYW5pZXMgYWRkZWQgZGFpbHkifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiOiLguITguYnguJnguKvguLLguJrguKPguLTguKnguLHguJfguJfguLXguYjguYPguIrguYjguKrguLPguKvguKPguLHguJrguITguLjguJMiLCJFdmVyeXRoaW5nIHlvdSBuZWVkIHRvIGtub3cgYWJvdXQgYSBjb21wYW55LCBhbGwgaW4gb25lIHBsYWNlIjoi4LiX4Li44LiB4Liq4Li04LmI4LiH4LiX4Li14LmI4LiE4Li44LiT4LiI4Liz4LmA4Lib4LmH4LiZ4LiV4LmJ4Lit4LiH4LiX4Lij4Liy4Lia4LmA4LiB4Li14LmI4Lii4Lin4LiB4Lix4Lia4Lia4Lij4Li04Lip4Lix4LiXIOC4o+C4p+C4oeC5hOC4p+C5ieC5g+C4meC4l+C4teC5iOC5gOC4lOC4teC4ouC4pyIsIk1vcmUgY29tcGFuaWVzIGFkZGVkIGRhaWx5IjoiTW9yZSBjb21wYW5pZXMgYWRkZWQgZGFpbHkifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiOiJUZW11a2FuIHBlcnVzYWhhYW4geWFuZyB0ZXBhdCB1bnR1ayBBbmRhIiwiRXZlcnl0aGluZyB5b3UgbmVlZCB0byBrbm93IGFib3V0IGEgY29tcGFueSwgYWxsIGluIG9uZSBwbGFjZSI6IlNlbXVhIHlhbmcgcGVybHUgZGlrZXRhaHVpIHRlbnRhbmcgcGVydXNhaGFhbiwgZGkgc2F0dSB0ZW1wYXQiLCJNb3JlIGNvbXBhbmllcyBhZGRlZCBkYWlseSI6Ik1vcmUgY29tcGFuaWVzIGFkZGVkIGRhaWx5In0=!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiOiJUZW11a2FuIHBlcnVzYWhhYW4geWFuZyB0ZXBhdCB1bnR1ayBBbmRhIiwiRXZlcnl0aGluZyB5b3UgbmVlZCB0byBrbm93IGFib3V0IGEgY29tcGFueSwgYWxsIGluIG9uZSBwbGFjZSI6IlNlbXVhIHlhbmcgcGVybHUgZGlrZXRhaHVpIHRlbnRhbmcgcGVydXNhaGFhbiwgZGkgc2F0dSB0ZW1wYXQiLCJNb3JlIGNvbXBhbmllcyBhZGRlZCBkYWlseSI6Ik1vcmUgY29tcGFuaWVzIGFkZGVkIGRhaWx5In0=!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiOiJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiLCJFdmVyeXRoaW5nIHlvdSBuZWVkIHRvIGtub3cgYWJvdXQgYSBjb21wYW55LCBhbGwgaW4gb25lIHBsYWNlIjoiRXZlcnl0aGluZyB5b3UgbmVlZCB0byBrbm93IGFib3V0IGEgY29tcGFueSwgYWxsIGluIG9uZSBwbGFjZSIsIk1vcmUgY29tcGFuaWVzIGFkZGVkIGRhaWx5IjoiTW9yZSBjb21wYW5pZXMgYWRkZWQgZGFpbHkifQ==!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiOiJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiLCJFdmVyeXRoaW5nIHlvdSBuZWVkIHRvIGtub3cgYWJvdXQgYSBjb21wYW55LCBhbGwgaW4gb25lIHBsYWNlIjoiRXZlcnl0aGluZyB5b3UgbmVlZCB0byBrbm93IGFib3V0IGEgY29tcGFueSwgYWxsIGluIG9uZSBwbGFjZSIsIk1vcmUgY29tcGFuaWVzIGFkZGVkIGRhaWx5IjoiTW9yZSBjb21wYW5pZXMgYWRkZWQgZGFpbHkifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiOiJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiLCJFdmVyeXRoaW5nIHlvdSBuZWVkIHRvIGtub3cgYWJvdXQgYSBjb21wYW55LCBhbGwgaW4gb25lIHBsYWNlIjoiRXZlcnl0aGluZyB5b3UgbmVlZCB0byBrbm93IGFib3V0IGEgY29tcGFueSwgYWxsIGluIG9uZSBwbGFjZSIsIk1vcmUgY29tcGFuaWVzIGFkZGVkIGRhaWx5IjoiTW9yZSBjb21wYW5pZXMgYWRkZWQgZGFpbHkifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiOiJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiLCJFdmVyeXRoaW5nIHlvdSBuZWVkIHRvIGtub3cgYWJvdXQgYSBjb21wYW55LCBhbGwgaW4gb25lIHBsYWNlIjoiRXZlcnl0aGluZyB5b3UgbmVlZCB0byBrbm93IGFib3V0IGEgY29tcGFueSwgYWxsIGluIG9uZSBwbGFjZSIsIk1vcmUgY29tcGFuaWVzIGFkZGVkIGRhaWx5IjoiTW9yZSBjb21wYW5pZXMgYWRkZWQgZGFpbHkifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiOiJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiLCJFdmVyeXRoaW5nIHlvdSBuZWVkIHRvIGtub3cgYWJvdXQgYSBjb21wYW55LCBhbGwgaW4gb25lIHBsYWNlIjoiRXZlcnl0aGluZyB5b3UgbmVlZCB0byBrbm93IGFib3V0IGEgY29tcGFueSwgYWxsIGluIG9uZSBwbGFjZSIsIk1vcmUgY29tcGFuaWVzIGFkZGVkIGRhaWx5IjoiTW9yZSBjb21wYW5pZXMgYWRkZWQgZGFpbHkifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiOiJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiLCJFdmVyeXRoaW5nIHlvdSBuZWVkIHRvIGtub3cgYWJvdXQgYSBjb21wYW55LCBhbGwgaW4gb25lIHBsYWNlIjoiRXZlcnl0aGluZyB5b3UgbmVlZCB0byBrbm93IGFib3V0IGEgY29tcGFueSwgYWxsIGluIG9uZSBwbGFjZSIsIk1vcmUgY29tcGFuaWVzIGFkZGVkIGRhaWx5IjoiTW9yZSBjb21wYW5pZXMgYWRkZWQgZGFpbHkifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiOiJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiLCJFdmVyeXRoaW5nIHlvdSBuZWVkIHRvIGtub3cgYWJvdXQgYSBjb21wYW55LCBhbGwgaW4gb25lIHBsYWNlIjoiRXZlcnl0aGluZyB5b3UgbmVlZCB0byBrbm93IGFib3V0IGEgY29tcGFueSwgYWxsIGluIG9uZSBwbGFjZSIsIk1vcmUgY29tcGFuaWVzIGFkZGVkIGRhaWx5IjoiTW9yZSBjb21wYW5pZXMgYWRkZWQgZGFpbHkifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiOiJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiLCJFdmVyeXRoaW5nIHlvdSBuZWVkIHRvIGtub3cgYWJvdXQgYSBjb21wYW55LCBhbGwgaW4gb25lIHBsYWNlIjoiRXZlcnl0aGluZyB5b3UgbmVlZCB0byBrbm93IGFib3V0IGEgY29tcGFueSwgYWxsIGluIG9uZSBwbGFjZSIsIk1vcmUgY29tcGFuaWVzIGFkZGVkIGRhaWx5IjoiTW9yZSBjb21wYW5pZXMgYWRkZWQgZGFpbHkifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiOiJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiLCJFdmVyeXRoaW5nIHlvdSBuZWVkIHRvIGtub3cgYWJvdXQgYSBjb21wYW55LCBhbGwgaW4gb25lIHBsYWNlIjoiRXZlcnl0aGluZyB5b3UgbmVlZCB0byBrbm93IGFib3V0IGEgY29tcGFueSwgYWxsIGluIG9uZSBwbGFjZSIsIk1vcmUgY29tcGFuaWVzIGFkZGVkIGRhaWx5IjoiTW9yZSBjb21wYW5pZXMgYWRkZWQgZGFpbHkifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiOiJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiLCJFdmVyeXRoaW5nIHlvdSBuZWVkIHRvIGtub3cgYWJvdXQgYSBjb21wYW55LCBhbGwgaW4gb25lIHBsYWNlIjoiRXZlcnl0aGluZyB5b3UgbmVlZCB0byBrbm93IGFib3V0IGEgY29tcGFueSwgYWxsIGluIG9uZSBwbGFjZSIsIk1vcmUgY29tcGFuaWVzIGFkZGVkIGRhaWx5IjoiTW9yZSBjb21wYW5pZXMgYWRkZWQgZGFpbHkifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiOiJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiLCJFdmVyeXRoaW5nIHlvdSBuZWVkIHRvIGtub3cgYWJvdXQgYSBjb21wYW55LCBhbGwgaW4gb25lIHBsYWNlIjoiRXZlcnl0aGluZyB5b3UgbmVlZCB0byBrbm93IGFib3V0IGEgY29tcGFueSwgYWxsIGluIG9uZSBwbGFjZSIsIk1vcmUgY29tcGFuaWVzIGFkZGVkIGRhaWx5IjoiTW9yZSBjb21wYW5pZXMgYWRkZWQgZGFpbHkifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiOiJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiLCJFdmVyeXRoaW5nIHlvdSBuZWVkIHRvIGtub3cgYWJvdXQgYSBjb21wYW55LCBhbGwgaW4gb25lIHBsYWNlIjoiRXZlcnl0aGluZyB5b3UgbmVlZCB0byBrbm93IGFib3V0IGEgY29tcGFueSwgYWxsIGluIG9uZSBwbGFjZSIsIk1vcmUgY29tcGFuaWVzIGFkZGVkIGRhaWx5IjoiTW9yZSBjb21wYW5pZXMgYWRkZWQgZGFpbHkifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiOiJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiLCJFdmVyeXRoaW5nIHlvdSBuZWVkIHRvIGtub3cgYWJvdXQgYSBjb21wYW55LCBhbGwgaW4gb25lIHBsYWNlIjoiRXZlcnl0aGluZyB5b3UgbmVlZCB0byBrbm93IGFib3V0IGEgY29tcGFueSwgYWxsIGluIG9uZSBwbGFjZSIsIk1vcmUgY29tcGFuaWVzIGFkZGVkIGRhaWx5IjoiTW9yZSBjb21wYW5pZXMgYWRkZWQgZGFpbHkifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiOiJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiLCJFdmVyeXRoaW5nIHlvdSBuZWVkIHRvIGtub3cgYWJvdXQgYSBjb21wYW55LCBhbGwgaW4gb25lIHBsYWNlIjoiRXZlcnl0aGluZyB5b3UgbmVlZCB0byBrbm93IGFib3V0IGEgY29tcGFueSwgYWxsIGluIG9uZSBwbGFjZSIsIk1vcmUgY29tcGFuaWVzIGFkZGVkIGRhaWx5IjoiTW9yZSBjb21wYW5pZXMgYWRkZWQgZGFpbHkifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiOiJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiLCJFdmVyeXRoaW5nIHlvdSBuZWVkIHRvIGtub3cgYWJvdXQgYSBjb21wYW55LCBhbGwgaW4gb25lIHBsYWNlIjoiRXZlcnl0aGluZyB5b3UgbmVlZCB0byBrbm93IGFib3V0IGEgY29tcGFueSwgYWxsIGluIG9uZSBwbGFjZSIsIk1vcmUgY29tcGFuaWVzIGFkZGVkIGRhaWx5IjoiTW9yZSBjb21wYW5pZXMgYWRkZWQgZGFpbHkifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiOiJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiLCJFdmVyeXRoaW5nIHlvdSBuZWVkIHRvIGtub3cgYWJvdXQgYSBjb21wYW55LCBhbGwgaW4gb25lIHBsYWNlIjoiRXZlcnl0aGluZyB5b3UgbmVlZCB0byBrbm93IGFib3V0IGEgY29tcGFueSwgYWxsIGluIG9uZSBwbGFjZSIsIk1vcmUgY29tcGFuaWVzIGFkZGVkIGRhaWx5IjoiTW9yZSBjb21wYW5pZXMgYWRkZWQgZGFpbHkifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiOiJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiLCJFdmVyeXRoaW5nIHlvdSBuZWVkIHRvIGtub3cgYWJvdXQgYSBjb21wYW55LCBhbGwgaW4gb25lIHBsYWNlIjoiRXZlcnl0aGluZyB5b3UgbmVlZCB0byBrbm93IGFib3V0IGEgY29tcGFueSwgYWxsIGluIG9uZSBwbGFjZSIsIk1vcmUgY29tcGFuaWVzIGFkZGVkIGRhaWx5IjoiTW9yZSBjb21wYW5pZXMgYWRkZWQgZGFpbHkifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiOiJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiLCJFdmVyeXRoaW5nIHlvdSBuZWVkIHRvIGtub3cgYWJvdXQgYSBjb21wYW55LCBhbGwgaW4gb25lIHBsYWNlIjoiRXZlcnl0aGluZyB5b3UgbmVlZCB0byBrbm93IGFib3V0IGEgY29tcGFueSwgYWxsIGluIG9uZSBwbGFjZSIsIk1vcmUgY29tcGFuaWVzIGFkZGVkIGRhaWx5IjoiTW9yZSBjb21wYW5pZXMgYWRkZWQgZGFpbHkifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiOiLguITguYnguJnguKvguLLguJrguKPguLTguKnguLHguJfguJfguLXguYjguYPguIrguYjguKrguLPguKvguKPguLHguJrguITguLjguJMiLCJFdmVyeXRoaW5nIHlvdSBuZWVkIHRvIGtub3cgYWJvdXQgYSBjb21wYW55LCBhbGwgaW4gb25lIHBsYWNlIjoi4LiX4Li44LiB4Liq4Li04LmI4LiH4LiX4Li14LmI4LiE4Li44LiT4LiI4Liz4LmA4Lib4LmH4LiZ4LiV4LmJ4Lit4LiH4LiX4Lij4Liy4Lia4LmA4LiB4Li14LmI4Lii4Lin4LiB4Lix4Lia4Lia4Lij4Li04Lip4Lix4LiXIOC4o+C4p+C4oeC5hOC4p+C5ieC5g+C4meC4l+C4teC5iOC5gOC4lOC4teC4ouC4pyIsIk1vcmUgY29tcGFuaWVzIGFkZGVkIGRhaWx5IjoiTW9yZSBjb21wYW5pZXMgYWRkZWQgZGFpbHkifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiOiLguITguYnguJnguKvguLLguJrguKPguLTguKnguLHguJfguJfguLXguYjguYPguIrguYjguKrguLPguKvguKPguLHguJrguITguLjguJMiLCJFdmVyeXRoaW5nIHlvdSBuZWVkIHRvIGtub3cgYWJvdXQgYSBjb21wYW55LCBhbGwgaW4gb25lIHBsYWNlIjoi4LiX4Li44LiB4Liq4Li04LmI4LiH4LiX4Li14LmI4LiE4Li44LiT4LiI4Liz4LmA4Lib4LmH4LiZ4LiV4LmJ4Lit4LiH4LiX4Lij4Liy4Lia4LmA4LiB4Li14LmI4Lii4Lin4LiB4Lix4Lia4Lia4Lij4Li04Lip4Lix4LiXIOC4o+C4p+C4oeC5hOC4p+C5ieC5g+C4meC4l+C4teC5iOC5gOC4lOC4teC4ouC4pyIsIk1vcmUgY29tcGFuaWVzIGFkZGVkIGRhaWx5IjoiTW9yZSBjb21wYW5pZXMgYWRkZWQgZGFpbHkifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiOiJUZW11a2FuIHBlcnVzYWhhYW4geWFuZyB0ZXBhdCB1bnR1ayBBbmRhIiwiRXZlcnl0aGluZyB5b3UgbmVlZCB0byBrbm93IGFib3V0IGEgY29tcGFueSwgYWxsIGluIG9uZSBwbGFjZSI6IlNlbXVhIHlhbmcgcGVybHUgZGlrZXRhaHVpIHRlbnRhbmcgcGVydXNhaGFhbiwgZGkgc2F0dSB0ZW1wYXQiLCJNb3JlIGNvbXBhbmllcyBhZGRlZCBkYWlseSI6Ik1vcmUgY29tcGFuaWVzIGFkZGVkIGRhaWx5In0=!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiOiJUZW11a2FuIHBlcnVzYWhhYW4geWFuZyB0ZXBhdCB1bnR1ayBBbmRhIiwiRXZlcnl0aGluZyB5b3UgbmVlZCB0byBrbm93IGFib3V0IGEgY29tcGFueSwgYWxsIGluIG9uZSBwbGFjZSI6IlNlbXVhIHlhbmcgcGVybHUgZGlrZXRhaHVpIHRlbnRhbmcgcGVydXNhaGFhbiwgZGkgc2F0dSB0ZW1wYXQiLCJNb3JlIGNvbXBhbmllcyBhZGRlZCBkYWlseSI6Ik1vcmUgY29tcGFuaWVzIGFkZGVkIGRhaWx5In0=!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiOiJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiLCJFdmVyeXRoaW5nIHlvdSBuZWVkIHRvIGtub3cgYWJvdXQgYSBjb21wYW55LCBhbGwgaW4gb25lIHBsYWNlIjoiRXZlcnl0aGluZyB5b3UgbmVlZCB0byBrbm93IGFib3V0IGEgY29tcGFueSwgYWxsIGluIG9uZSBwbGFjZSIsIk1vcmUgY29tcGFuaWVzIGFkZGVkIGRhaWx5IjoiTW9yZSBjb21wYW5pZXMgYWRkZWQgZGFpbHkifQ==!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiOiJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiLCJFdmVyeXRoaW5nIHlvdSBuZWVkIHRvIGtub3cgYWJvdXQgYSBjb21wYW55LCBhbGwgaW4gb25lIHBsYWNlIjoiRXZlcnl0aGluZyB5b3UgbmVlZCB0byBrbm93IGFib3V0IGEgY29tcGFueSwgYWxsIGluIG9uZSBwbGFjZSIsIk1vcmUgY29tcGFuaWVzIGFkZGVkIGRhaWx5IjoiTW9yZSBjb21wYW5pZXMgYWRkZWQgZGFpbHkifQ==!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiOiJbxpHDrMOsw6zguIHguLXguYnGjCDhua/huKnhur3hur3hur0gxZnDrMOsw6zEo+G4qeG5ryDDp8O2w7bDtm3MgsalxIPEg8SD4LiB4Li14LmJw73DvcO9IMaSw7bDtsO2xZkgw73DvcO9w7bDtsO2x5rHmseaXSIsIkV2ZXJ5dGhpbmcgeW91IG5lZWQgdG8ga25vdyBhYm91dCBhIGNvbXBhbnksIGFsbCBpbiBvbmUgcGxhY2UiOiJbw4vhub3hur3hur3hur3FmcO9w73DveG5r+G4qcOsw6zDrOC4geC4teC5icSjIMO9w73DvcO2w7bDtseax5rHmiDguIHguLXguYnhur3hur3hur3hur3hur3hur3GjCDhua/DtsO2w7YgxLfguIHguLXguYnDtsO2w7bFtSDEg8SDxIPDn8O2w7bDtseax5rHmuG5ryDEg8SDxIMgw6fDtsO2w7ZtzILGpcSDxIPEg+C4geC4teC5icO9w73DvSwgxIPEg8SDxprGmiDDrMOsw6zguIHguLXguYkgw7bDtsO24LiB4Li14LmJ4bq94bq94bq9IMalxprEg8SDxIPDp+G6veG6veG6vV0iLCJNb3JlIGNvbXBhbmllcyBhZGRlZCBkYWlseSI6IlvhuYLDtsO2xZnhur3hur0gw6fDtsO2bcyCxqXEg8SD4LiB4Li14LmJw6zDrOG6veG6vcWhIMSDxIPGjMaM4bq94bq9xowgxozEg8SDw6zDrMaaw73DvV0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIHRoZSByaWdodCBjb21wYW55IGZvciB5b3UiOiJbxpHDrMOsw6zguIHguLXguYnGjCDhua/huKnhur3hur3hur0gxZnDrMOsw6zEo+G4qeG5ryDDp8O2w7bDtm3MgsalxIPEg8SD4LiB4Li14LmJw73DvcO9IMaSw7bDtsO2xZkgw73DvcO9w7bDtsO2x5rHmseaXSIsIkV2ZXJ5dGhpbmcgeW91IG5lZWQgdG8ga25vdyBhYm91dCBhIGNvbXBhbnksIGFsbCBpbiBvbmUgcGxhY2UiOiJbw4vhub3hur3hur3hur3FmcO9w73DveG5r+G4qcOsw6zDrOC4geC4teC5icSjIMO9w73DvcO2w7bDtseax5rHmiDguIHguLXguYnhur3hur3hur3hur3hur3hur3GjCDhua/DtsO2w7YgxLfguIHguLXguYnDtsO2w7bFtSDEg8SDxIPDn8O2w7bDtseax5rHmuG5ryDEg8SDxIMgw6fDtsO2w7ZtzILGpcSDxIPEg+C4geC4teC5icO9w73DvSwgxIPEg8SDxprGmiDDrMOsw6zguIHguLXguYkgw7bDtsO24LiB4Li14LmJ4bq94bq94bq9IMalxprEg8SDxIPDp+G6veG6veG6vV0iLCJNb3JlIGNvbXBhbmllcyBhZGRlZCBkYWlseSI6IlvhuYLDtsO2xZnhur3hur0gw6fDtsO2bcyCxqXEg8SD4LiB4Li14LmJw6zDrOG6veG6vcWhIMSDxIPGjMaM4bq94bq9xowgxozEg8SDw6zDrMaaw73DvV0ifQ==!"
        )
      )
      });
  
      export { translations as default };
    