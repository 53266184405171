import { Section } from '@seek/libs-shared';
import { Text, TextLink } from 'braid-design-system';
import { CompanyProfilesPage } from '../../paths';
import { useTranslations } from '@vocab/react';
import translations from './.vocab';

export const SeoCtaBlurb = ({
  companyName,
  getCompanyProfilesPageUrl,
  onActiveTabChanged,
}: {
  companyName: string;
  getCompanyProfilesPageUrl: (p: CompanyProfilesPage) => string;
  onActiveTabChanged: (item: string | undefined) => void;
}) => {
  const { t } = useTranslations(translations);
  return (
    <Section>
      <Text size="small">
        {t(
          'Find out more about working at companyProfileModelName. Read company reviews from real employees, explore life and culture and view all open jobs.',
          {
            companyProfileModelName: companyName,
            ReviewsPage: (children) => (
              <TextLink
                weight="weak"
                href={getCompanyProfilesPageUrl(CompanyProfilesPage.Reviews)}
                onClick={(e) => {
                  e.preventDefault();
                  onActiveTabChanged(CompanyProfilesPage.Reviews);
                }}
              >
                {children}
              </TextLink>
            ),
            CulturePage: (children) => (
              <TextLink
                weight="weak"
                href={getCompanyProfilesPageUrl(CompanyProfilesPage.Culture)}
                onClick={(e) => {
                  e.preventDefault();
                  onActiveTabChanged(CompanyProfilesPage.Culture);
                }}
              >
                {children}
              </TextLink>
            ),
            JobsPage: (children) => (
              <TextLink
                weight="weak"
                href={getCompanyProfilesPageUrl(CompanyProfilesPage.Jobs)}
                onClick={(e) => {
                  e.preventDefault();
                  onActiveTabChanged(CompanyProfilesPage.Jobs);
                }}
              >
                {children}
              </TextLink>
            ),
          },
        )}
      </Text>
    </Section>
  );
};
