
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIjoiQXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyIsIkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiI6IkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiIsIkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCI6IkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIjoiQXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyIsIkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiI6IkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiIsIkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCI6IkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIjoi4Lij4Liy4LiH4Lin4Lix4Lil4LmB4Lil4Liw4LiB4Liy4Lij4Lij4Lix4Lia4Lij4Lit4LiHIiwiRmVhdHVyZSBhd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIHlvdXIgY29tcGFueSBoYXMgcmVjZWl2ZWQuIjoi4Lij4Liy4LiH4Lin4Lix4Lil4LmB4Lil4Liw4LiB4Liy4Lij4Lij4Lix4Lia4Lij4Lit4LiH4LiX4Li14LmI4Lia4Lij4Li04Lip4Lix4LiX4LiC4Lit4LiH4LiE4Li44LiT4LmE4LiU4LmJ4Lij4Lix4LiaIiwiQXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB1cGRhdGVkIjoi4Lit4Lix4Lib4LmA4LiU4LiV4Lij4Liy4LiH4Lin4Lix4Lil4LmB4Lil4Liw4LiB4Liy4Lij4Lij4Lix4Lia4Lij4Lit4LiH4LmB4Lil4LmJ4LinIn0=!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIjoi4Lij4Liy4LiH4Lin4Lix4Lil4LmB4Lil4Liw4LiB4Liy4Lij4Lij4Lix4Lia4Lij4Lit4LiHIiwiRmVhdHVyZSBhd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIHlvdXIgY29tcGFueSBoYXMgcmVjZWl2ZWQuIjoi4Lij4Liy4LiH4Lin4Lix4Lil4LmB4Lil4Liw4LiB4Liy4Lij4Lij4Lix4Lia4Lij4Lit4LiH4LiX4Li14LmI4Lia4Lij4Li04Lip4Lix4LiX4LiC4Lit4LiH4LiE4Li44LiT4LmE4LiU4LmJ4Lij4Lix4LiaIiwiQXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB1cGRhdGVkIjoi4Lit4Lix4Lib4LmA4LiU4LiV4Lij4Liy4LiH4Lin4Lix4Lil4LmB4Lil4Liw4LiB4Liy4Lij4Lij4Lix4Lia4Lij4Lit4LiH4LmB4Lil4LmJ4LinIn0=!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIjoiUGVuZ2hhcmdhYW4gZGFuIGFrcmVkaXRhc2kiLCJGZWF0dXJlIGF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgeW91ciBjb21wYW55IGhhcyByZWNlaXZlZC4iOiJUYW1waWxrYW4gcGVuZ2hhcmdhYW4gZGFuIGFrcmVkaXRhc2kgeWFuZyBkaXRlcmltYSBwZXJ1c2FoYWFuIEFuZGEuIiwiQXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB1cGRhdGVkIjoiUGVuZ2hhcmdhYW4gZGFuIGFrcmVkaXRhc2kgZGlwZXJiYXJ1aSJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIjoiUGVuZ2hhcmdhYW4gZGFuIGFrcmVkaXRhc2kiLCJGZWF0dXJlIGF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgeW91ciBjb21wYW55IGhhcyByZWNlaXZlZC4iOiJUYW1waWxrYW4gcGVuZ2hhcmdhYW4gZGFuIGFrcmVkaXRhc2kgeWFuZyBkaXRlcmltYSBwZXJ1c2FoYWFuIEFuZGEuIiwiQXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB1cGRhdGVkIjoiUGVuZ2hhcmdhYW4gZGFuIGFrcmVkaXRhc2kgZGlwZXJiYXJ1aSJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIjoiQXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyIsIkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiI6IkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiIsIkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCI6IkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIjoiQXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyIsIkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiI6IkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiIsIkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCI6IkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIjoiQXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyIsIkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiI6IkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiIsIkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCI6IkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIjoiQXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyIsIkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiI6IkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiIsIkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCI6IkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIjoiQXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyIsIkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiI6IkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiIsIkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCI6IkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIjoiQXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyIsIkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiI6IkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiIsIkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCI6IkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIjoiQXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyIsIkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiI6IkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiIsIkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCI6IkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIjoiQXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyIsIkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiI6IkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiIsIkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCI6IkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIjoiQXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyIsIkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiI6IkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiIsIkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCI6IkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIjoiQXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyIsIkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiI6IkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiIsIkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCI6IkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIjoiQXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyIsIkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiI6IkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiIsIkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCI6IkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIjoiQXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyIsIkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiI6IkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiIsIkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCI6IkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIjoiQXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyIsIkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiI6IkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiIsIkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCI6IkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIjoiQXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyIsIkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiI6IkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiIsIkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCI6IkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIjoiQXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyIsIkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiI6IkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiIsIkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCI6IkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIjoiQXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyIsIkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiI6IkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiIsIkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCI6IkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIjoiQXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyIsIkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiI6IkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiIsIkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCI6IkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIjoiQXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyIsIkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiI6IkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiIsIkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCI6IkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIjoi4Lij4Liy4LiH4Lin4Lix4Lil4LmB4Lil4Liw4LiB4Liy4Lij4Lij4Lix4Lia4Lij4Lit4LiHIiwiRmVhdHVyZSBhd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIHlvdXIgY29tcGFueSBoYXMgcmVjZWl2ZWQuIjoi4Lij4Liy4LiH4Lin4Lix4Lil4LmB4Lil4Liw4LiB4Liy4Lij4Lij4Lix4Lia4Lij4Lit4LiH4LiX4Li14LmI4Lia4Lij4Li04Lip4Lix4LiX4LiC4Lit4LiH4LiE4Li44LiT4LmE4LiU4LmJ4Lij4Lix4LiaIiwiQXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB1cGRhdGVkIjoi4Lit4Lix4Lib4LmA4LiU4LiV4Lij4Liy4LiH4Lin4Lix4Lil4LmB4Lil4Liw4LiB4Liy4Lij4Lij4Lix4Lia4Lij4Lit4LiH4LmB4Lil4LmJ4LinIn0=!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIjoi4Lij4Liy4LiH4Lin4Lix4Lil4LmB4Lil4Liw4LiB4Liy4Lij4Lij4Lix4Lia4Lij4Lit4LiHIiwiRmVhdHVyZSBhd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIHlvdXIgY29tcGFueSBoYXMgcmVjZWl2ZWQuIjoi4Lij4Liy4LiH4Lin4Lix4Lil4LmB4Lil4Liw4LiB4Liy4Lij4Lij4Lix4Lia4Lij4Lit4LiH4LiX4Li14LmI4Lia4Lij4Li04Lip4Lix4LiX4LiC4Lit4LiH4LiE4Li44LiT4LmE4LiU4LmJ4Lij4Lix4LiaIiwiQXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB1cGRhdGVkIjoi4Lit4Lix4Lib4LmA4LiU4LiV4Lij4Liy4LiH4Lin4Lix4Lil4LmB4Lil4Liw4LiB4Liy4Lij4Lij4Lix4Lia4Lij4Lit4LiH4LmB4Lil4LmJ4LinIn0=!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIjoiUGVuZ2hhcmdhYW4gZGFuIGFrcmVkaXRhc2kiLCJGZWF0dXJlIGF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgeW91ciBjb21wYW55IGhhcyByZWNlaXZlZC4iOiJUYW1waWxrYW4gcGVuZ2hhcmdhYW4gZGFuIGFrcmVkaXRhc2kgeWFuZyBkaXRlcmltYSBwZXJ1c2FoYWFuIEFuZGEuIiwiQXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB1cGRhdGVkIjoiUGVuZ2hhcmdhYW4gZGFuIGFrcmVkaXRhc2kgZGlwZXJiYXJ1aSJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIjoiUGVuZ2hhcmdhYW4gZGFuIGFrcmVkaXRhc2kiLCJGZWF0dXJlIGF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgeW91ciBjb21wYW55IGhhcyByZWNlaXZlZC4iOiJUYW1waWxrYW4gcGVuZ2hhcmdhYW4gZGFuIGFrcmVkaXRhc2kgeWFuZyBkaXRlcmltYSBwZXJ1c2FoYWFuIEFuZGEuIiwiQXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB1cGRhdGVkIjoiUGVuZ2hhcmdhYW4gZGFuIGFrcmVkaXRhc2kgZGlwZXJiYXJ1aSJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIjoiQXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyIsIkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiI6IkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiIsIkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCI6IkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIjoiQXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyIsIkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiI6IkZlYXR1cmUgYXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB5b3VyIGNvbXBhbnkgaGFzIHJlY2VpdmVkLiIsIkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCI6IkF3YXJkcyBhbmQgYWNjcmVkaXRhdGlvbnMgdXBkYXRlZCJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIjoiW+G6rMW1xIPEg8SDxZnGjMWhIMSDxIPEg+C4geC4teC5icaMIMSDxIPEg8Onw6fFmeG6veG6veG6vcaMw6zDrMOs4bmvxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJxaFdIiwiRmVhdHVyZSBhd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIHlvdXIgY29tcGFueSBoYXMgcmVjZWl2ZWQuIjoiW8aR4bq94bq9xIPEg+G5r8eax5rFmeG6veG6vSDEg8SDxbXEg8SDxZnGjMWhIMSDxIPguIHguLXguYnGjCDEg8SDw6fDp8WZ4bq94bq9xozDrMOs4bmvxIPEg+G5r8Osw6zDtsO24LiB4Li14LmJxaEgw73DvcO2w7bHmseaxZkgw6fDtsO2bcyCxqXEg8SD4LiB4Li14LmJw73DvSDhuKnEg8SDxaEgxZnhur3hur3Dp+G6veG6vcOsw6zhub3hur3hur3GjC5dIiwiQXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB1cGRhdGVkIjoiW+G6rMW1xIPEg8SDxZnGjMWhIMSDxIPEg+C4geC4teC5icaMIMSDxIPEg8Onw6fFmeG6veG6veG6vcaMw6zDrMOs4bmvxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJxaEgx5rHmseaxqXGjMSDxIPEg+G5r+G6veG6veG6vcaMXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIjoiW+G6rMW1xIPEg8SDxZnGjMWhIMSDxIPEg+C4geC4teC5icaMIMSDxIPEg8Onw6fFmeG6veG6veG6vcaMw6zDrMOs4bmvxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJxaFdIiwiRmVhdHVyZSBhd2FyZHMgYW5kIGFjY3JlZGl0YXRpb25zIHlvdXIgY29tcGFueSBoYXMgcmVjZWl2ZWQuIjoiW8aR4bq94bq9xIPEg+G5r8eax5rFmeG6veG6vSDEg8SDxbXEg8SDxZnGjMWhIMSDxIPguIHguLXguYnGjCDEg8SDw6fDp8WZ4bq94bq9xozDrMOs4bmvxIPEg+G5r8Osw6zDtsO24LiB4Li14LmJxaEgw73DvcO2w7bHmseaxZkgw6fDtsO2bcyCxqXEg8SD4LiB4Li14LmJw73DvSDhuKnEg8SDxaEgxZnhur3hur3Dp+G6veG6vcOsw6zhub3hur3hur3GjC5dIiwiQXdhcmRzIGFuZCBhY2NyZWRpdGF0aW9ucyB1cGRhdGVkIjoiW+G6rMW1xIPEg8SDxZnGjMWhIMSDxIPEg+C4geC4teC5icaMIMSDxIPEg8Onw6fFmeG6veG6veG6vcaMw6zDrMOs4bmvxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJxaEgx5rHmseaxqXGjMSDxIPEg+G5r+G6veG6veG6vcaMXSJ9!"
        )
      )
      });
  
      export { translations as default };
    