import { useTranslations } from '@vocab/react';
import { getHostName } from '@seek/melways-sites';
import { useMelwaysLink } from '@seek/melways-react';
import {
  Bleed,
  Box,
  Column,
  Columns,
  Stack,
  Tab,
  TabPanel,
  TabPanels,
  Tabs,
  TabsProvider,
  Text,
  TextLinkButton,
} from 'braid-design-system';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useRouteLoaderData } from 'react-router';

import { CompanyProfilesPage, ROOT_PATH } from '../../paths';
import { MainLayout } from '../mainLayout/MainLayout';

import {
  CompanyBrandingSection,
  useConfig,
  type CompanyBrandingSectionModel,
  useAppContext,
} from '@seek/libs-shared';
import { AboutPageContainer } from '../../pages/about/AboutPageContainer';
import { CulturePageContainer } from '../../pages/culture/CulturePageContainer';
import { JobsPageContainer } from '../../pages/jobs/JobsPageContainer';
import { ReviewsPageContainer } from '../../pages/reviews/ReviewsPageContainer';
import { CompanySearchBar } from '../../components/companySearchBar/CompanySearchBar';
import { MetaTag } from '../../components/MetaTag/MetaTag';
import { getMetaData, metaBrand } from '../../components/MetaTag/meta';
import type { AppConfig } from '../../../shared/config';
import { trackLinkEvents } from '../../../shared/trackLinkEvents';
import { useSearchParams } from 'react-router-dom';

import translations from './.vocab';

type Props = React.PropsWithChildren<{
  page: CompanyProfilesPage;
}>;

export function CompanyProfileLayout({ page }: Props) {
  const DEFAULT_TAB = CompanyProfilesPage.About;
  const { t } = useTranslations(translations);

  const companyProfileModel = useRouteLoaderData(
    'company-profile-root',
  ) as CompanyBrandingSectionModel;

  const [activeTab, setActiveTab] = React.useState<string | undefined>(page);
  const [showReviewReplyNotice, setShowReviewReplyNotice] =
    React.useState<boolean>(false);
  const [isDraft, setIsDraft] = useState(false);
  const [shouldShouldBackToJobSearchLink, setShouldShouldBackToJobSearchLink] =
    useState<boolean>(false);

  const config = useConfig<AppConfig>();
  const {
    language,
    siteName: site,
    environment,
    brand,
    absoluteUrl,
    consolidated,
  } = config;

  const navigate = useNavigate();
  const { companySlug } = useParams();
  const context = useAppContext();
  const { setCompanyId, setCompanyName } = context;
  const localize = useMelwaysLink();

  const getCompanyProfilesPageUrl = useCallback(
    (p: CompanyProfilesPage) => {
      const searchParams = isDraft ? '?draft=true' : '';
      const getPath = () => {
        switch (p) {
          case CompanyProfilesPage.Culture:
          case CompanyProfilesPage.Jobs:
          case CompanyProfilesPage.Reviews:
            return `/${ROOT_PATH}/${companySlug}/${p}${searchParams}`;
          default:
            return `/${ROOT_PATH}/${companySlug}${searchParams}`;
        }
      };

      return localize({
        path: getPath(),
        language,
      });
    },
    [companySlug, isDraft, language, localize],
  );

  const getOrigin = (): 'home' | 'about' | 'culture' | 'jobs' | 'reviews' => {
    switch (page) {
      case CompanyProfilesPage.About:
        return 'about';
      case CompanyProfilesPage.Culture:
        return 'culture';
      case CompanyProfilesPage.Jobs:
        return 'jobs';
      case CompanyProfilesPage.Reviews:
        return 'reviews';
      case CompanyProfilesPage.Home:
      default:
        return 'home';
    }
  };

  const [params] = useSearchParams();

  const trackWriteAReviewClicked = () => {
    trackLinkEvents.trackWriteAReviewButtonClicked({
      config,
      context,
      actionOrigin: getOrigin(),
    });
  };

  const trackReviewsClicked = () => {
    trackLinkEvents.trackReviewsClicked({
      config,
      context,
      actionOrigin: 'about',
    });
  };

  const trackJobsPaginationClicked = trackLinkEvents.trackPaginationClicked({
    config,
    context,
    currentPageSection: 'jobs',
  });

  const trackReviewsPaginationClicked = trackLinkEvents.trackPaginationClicked({
    config,
    context,
    currentPageSection: 'reviews',
  });

  const trackPhotosPaginationClicked = trackLinkEvents.trackPaginationClicked({
    config,
    context,
    currentPageSection: 'culture',
  });

  const trackSortOrderChanged = trackLinkEvents.trackSortOrderChanged({
    config,
    context,
  });

  const trackSeeAllPhotosButtonClicked = () =>
    trackLinkEvents.trackSeeAllPhotosButtonClicked({
      config,
      context,
    });

  const trackWebsiteClicked = () =>
    trackLinkEvents.trackWebsiteClicked({
      config,
      context,
    });

  const trackSignInClicked = () => {
    trackLinkEvents.trackSignInClicked({
      config,
      context,
    });
  };

  const trackRegisterClicked = () => {
    trackLinkEvents.trackRegisterClicked({
      config,
      context,
    });
  };

  const trackRandomCompanyProfileLinkClicked = ({
    linkedCompanyId,
  }: {
    linkedCompanyId: string;
  }) => {
    console.log('trackRandomCompanyProfileLinkClicked');
    console.log({ config, context, linkedCompanyId });
    trackLinkEvents.trackRandomCompanyProfileLinkClicked({
      config,
      context,
      linkedCompanyId,
    });
  };

  const onActiveTabChanged = useCallback(
    (item: string | undefined) => {
      const newActiveTab = item ? (item as CompanyProfilesPage) : DEFAULT_TAB;

      setActiveTab(newActiveTab);
      navigate(getCompanyProfilesPageUrl(newActiveTab), {
        replace: true,
      });

      scrollTo({ top: 0, behavior: 'smooth' });
    },
    [DEFAULT_TAB, getCompanyProfilesPageUrl, navigate],
  );

  useEffect(() => {
    if (companyProfileModel && setCompanyId && setCompanyName) {
      setCompanyId(companyProfileModel.id);
      setCompanyName(companyProfileModel.name);
      setIsDraft(params.get('draft') === 'true');
    }
  }, [companyProfileModel, params]);

  useEffect(() => {
    if (params.get('ac')) {
      onActiveTabChanged(CompanyProfilesPage.Reviews);
      setShowReviewReplyNotice(true);
    }
  }, [params, onActiveTabChanged]);

  const brandUrl = getHostName({
    site,
    staging: environment === 'staging',
    ...(consolidated ? { alternative: 'subDomainRegion' } : {}),
  });

  useEffect(() => {
    const splitedUrls = document.referrer.split(brandUrl);
    setShouldShouldBackToJobSearchLink(
      splitedUrls.length === 2 && splitedUrls[1].substring(0, 4) === '/job',
    );
  }, [brandUrl]);

  return (
    <MainLayout pageWidth="medium" showHeaderDivider>
      <TabsProvider
        id="company-profile"
        selectedItem={activeTab}
        onChange={(_index, item) => onActiveTabChanged(item)}
      >
        <MetaTag
          {...getMetaData({
            tab: activeTab as CompanyProfilesPage,
            companyTitle: companyProfileModel.name,
            reviewCount: companyProfileModel?.rating?.count,
            companyProfileImage: companyProfileModel.branding?.logoImageUrl,
            language,
            brand: metaBrand[brand] || 'SEEK',
            brandUrl,
            absoluteUrl,
          })}
        />
        <Stack space="gutter">
          <Columns space="small" alignY="center" collapseBelow="tablet">
            <Column width="2/3">
              {shouldShouldBackToJobSearchLink && (
                <Text tone="secondary">
                  <TextLinkButton
                    weight="weak"
                    onClick={() => {
                      window.history.back();
                    }}
                  >
                    &lt; {t('Back to job search')}
                  </TextLinkButton>
                </Text>
              )}
            </Column>
            <Column width="1/3">
              <CompanySearchBar setActiveTab={setActiveTab} />
            </Column>
          </Columns>

          <CompanyBrandingSection
            trackWriteAReviewClicked={trackWriteAReviewClicked}
            navigateToReviewsTab={() => {
              trackLinkEvents.trackReviewsClicked({
                config,
                context,
                actionOrigin: getOrigin(),
              });
              onActiveTabChanged(CompanyProfilesPage.Reviews);
            }}
            navigateToAboutTab={() => {
              onActiveTabChanged(CompanyProfilesPage.About);
            }}
            model={{
              name: companyProfileModel.name,
              branding: companyProfileModel.branding,
              rating: companyProfileModel.rating,
            }}
            aboutPageLink={getCompanyProfilesPageUrl(CompanyProfilesPage.About)}
          />
          <Bleed top={['small', 'gutter']}>
            <Box
              paddingBottom="none"
              paddingX={['none', 'none', 'large']}
              background="surface"
            >
              <Tabs label="" divider="full">
                <Tab item={CompanyProfilesPage.About}>{t('About')}</Tab>
                <Tab item={CompanyProfilesPage.Culture}>
                  {t('Life and Culture')}
                </Tab>
                <Tab item={CompanyProfilesPage.Jobs}>{t('Jobs')}</Tab>
                <Tab item={CompanyProfilesPage.Reviews}>{t('Reviews')}</Tab>
              </Tabs>
            </Box>
          </Bleed>
          <Box>
            <TabPanels renderInactivePanels={false}>
              <TabPanel item={CompanyProfilesPage.About}>
                <AboutPageContainer
                  onActiveTabChanged={onActiveTabChanged}
                  getCompanyProfilesPageUrl={getCompanyProfilesPageUrl}
                  trackWriteAReviewClicked={trackWriteAReviewClicked}
                  trackReviewsClicked={trackReviewsClicked}
                  trackSeeAllPhotosButtonClicked={
                    trackSeeAllPhotosButtonClicked
                  }
                  trackWebsiteClicked={trackWebsiteClicked}
                  trackRandomCompanyProfileLinkClicked={
                    trackRandomCompanyProfileLinkClicked
                  }
                />
              </TabPanel>
              <TabPanel item={CompanyProfilesPage.Culture}>
                <CulturePageContainer
                  trackPhotosPaginationClicked={trackPhotosPaginationClicked}
                />
              </TabPanel>
              <TabPanel item={CompanyProfilesPage.Jobs}>
                <JobsPageContainer
                  trackJobsPaginationClicked={trackJobsPaginationClicked}
                />
              </TabPanel>
              <TabPanel item={CompanyProfilesPage.Reviews}>
                <ReviewsPageContainer
                  showReviewReplyNotice={showReviewReplyNotice}
                  trackWriteAReviewClicked={trackWriteAReviewClicked}
                  trackReviewsPaginationClicked={trackReviewsPaginationClicked}
                  trackSortOrderChanged={trackSortOrderChanged}
                  trackSignInClicked={trackSignInClicked}
                  trackRegisterClicked={trackRegisterClicked}
                />
              </TabPanel>
            </TabPanels>
          </Box>
        </Stack>
      </TabsProvider>
    </MainLayout>
  );
}
