import type {
  CompanyProfile as CompanyProfileFromGraph,
  GroupedCompanies,
  Reviews as ReviewsFromGraph,
} from '@seek/ca-graphql-schema/types';

import type {
  CompanyProfile,
  Jobs,
  Reviews,
  CompanyProfileRatingBreakdown,
} from '@seek/libs-shared';
import type {
  LegacyCompanyReviewsSummaryResult,
  LegacyCompanyProfile,
  LegacySearchCompaniesResult,
} from '../legacyCompanyProfileService/types';

import type { CompaniesListModel } from '../../../companyProfiles/models/companiesListModel';

import type { SearchCompaniesResult } from './types';

/**
 * This function returns no reviews data
 */
export const mapCompanyProfileModelFromDraft = (
  legacyCompanyProfile: LegacyCompanyProfile,
  legacyCompanyReviewsSummary?: LegacyCompanyReviewsSummaryResult,
): CompanyProfile => ({
  id: legacyCompanyProfile.company_id,
  name: legacyCompanyProfile.company_name,
  overview: {
    website: legacyCompanyProfile.website_url
      ? {
          url: legacyCompanyProfile.website_url,
          displayName: legacyCompanyProfile.company_name,
        }
      : undefined,
    industry: legacyCompanyProfile.industry || undefined,
    companySize: legacyCompanyProfile.number_of_employees_range,
    primaryLocation: legacyCompanyProfile.address || undefined,
    specialities: legacyCompanyProfile.specialities || undefined,
    description: legacyCompanyProfile.overview || undefined,
  },
  foreignCompanyId: legacyCompanyProfile.foreign_company_id,
  branding: {
    logoImageUrl:
      legacyCompanyProfile.assets?.logo?.url ||
      'https://image-service-cdn.seek.com.au/98ea82fb0e440087ccc2da65b549d18bdcb4a450/f3c5292cec0e05e4272d9bf9146f390d366481d0',
    coverImageUrl:
      legacyCompanyProfile.assets?.cover?.strategies.jdp_cover || undefined,
  },
  rating: legacyCompanyReviewsSummary?.data?.company_review_summary?.average_company_rating
    ? {
        average:
          legacyCompanyReviewsSummary?.data?.company_review_summary
            .average_company_rating,
        count:
          legacyCompanyReviewsSummary?.data?.company_review_summary
            .approved_reviews_count,
      }
    : undefined,
  overallRating: legacyCompanyReviewsSummary?.data?.company_review_summary?.average_company_rating
    ? {
        average:
          legacyCompanyReviewsSummary?.data?.company_review_summary
            .average_company_rating,
        count:
          legacyCompanyReviewsSummary?.data?.company_review_summary
            .approved_reviews_count,
      }
    : undefined,
  awards: legacyCompanyProfile.awards?.map((award) => ({
    imageUrl: award.link,
    name: award.name,
    year: award.year,
  })),
  featured: legacyCompanyProfile.featured?.text
    ? {
        description: legacyCompanyProfile.featured?.text,
        imageUrl: !legacyCompanyProfile.featured?.link_is_video
          ? legacyCompanyProfile.featured?.link
          : undefined,
        title: legacyCompanyProfile.featured?.sub_heading,
        videoUrl: legacyCompanyProfile.featured?.link_is_video
          ? legacyCompanyProfile.featured?.link
          : undefined,
      }
    : undefined,
  missionStatement: legacyCompanyProfile.mission_statement
    ? {
        description: legacyCompanyProfile.mission_statement,
      }
    : undefined,
  customStatement: legacyCompanyProfile.custom_statement
    ? {
        title: legacyCompanyProfile.custom_statement?.title || '',
        description: legacyCompanyProfile.custom_statement?.text || [],
      }
    : undefined,
  gallery: {
    name: legacyCompanyProfile.company_name,
    photos: legacyCompanyProfile?.gallery?.photo_video_gallery?.photos ?? [],
  },
  cultureAndValues: legacyCompanyProfile.culture_values
    ? {
        title: legacyCompanyProfile.culture_values?.heading,
        description: legacyCompanyProfile.culture_values?.text,
        imageUrl: legacyCompanyProfile.culture_values?.photo,
        values: legacyCompanyProfile.culture_values?.values?.map((value) => ({
          title: value.title,
          description: value.text,
        })),
      }
    : undefined,
  perksAndBenefits: legacyCompanyProfile.perks_benefits?.map((value) => ({
    title: value.title,
    description: value.text,
  })),
  salaryRating:
    (legacyCompanyReviewsSummary?.data?.company_review_summary?.salary_generous_percentage ?? 0) +
    (legacyCompanyReviewsSummary?.data?.company_review_summary?.salary_fair_percentage ?? 0),
  employeeRecommendationRating:
    legacyCompanyReviewsSummary?.data?.company_review_summary?.company_recommended_percentage ?? 0,
  companyReviewSummaryBreakdown: [
    {
      rating:
        legacyCompanyReviewsSummary?.data?.company_review_summary?.average_benefits_and_perks ?? 0,
      description: 'Benefits & perks',
    },
    {
      rating:
        legacyCompanyReviewsSummary?.data?.company_review_summary?.average_career_opportunity ?? 0,
      description: 'Career development',
    },
    {
      rating:
        legacyCompanyReviewsSummary?.data?.company_review_summary?.average_work_life_balance ?? 0,
      description: 'Work/Life balance',
    },
    {
      rating:
        legacyCompanyReviewsSummary?.data?.company_review_summary?.average_work_environment ?? 0,
      description: 'Working environment',
    },
    {
      rating:
        legacyCompanyReviewsSummary?.data?.company_review_summary?.average_executive_management ?? 0,
      description: 'Management',
    },
    {
      rating:
        legacyCompanyReviewsSummary?.data?.company_review_summary?.average_rating_diversity ?? 0,
      description: 'Diversity & equal opportunity',
    },
  ],
  ...(legacyCompanyReviewsSummary?.data?.company_review_summary
    ? {
        ratingBreakdown: mapLegacyRatingSummaryBreakdown(
          legacyCompanyReviewsSummary,
        ),
      }
    : {}),
});

export const mapCompanyProfileModel = ({
  companyProfileFromGraph,
  legacyCompanyReviewsSummary,
  legacyCompanyProfile,
}: {
  companyProfileFromGraph: CompanyProfileFromGraph;
  legacyCompanyReviewsSummary?: LegacyCompanyReviewsSummaryResult;
  legacyCompanyProfile: LegacyCompanyProfile;
}): CompanyProfile => ({
  id: companyProfileFromGraph.id,
  name: companyProfileFromGraph.name,
  overview: {
    website: companyProfileFromGraph.overview?.website?.url
      ? {
          url: companyProfileFromGraph.overview?.website?.url,
          displayName:
            companyProfileFromGraph.overview?.website?.displayName ??
            companyProfileFromGraph.name,
        }
      : undefined,
    industry: companyProfileFromGraph.overview?.industry || undefined,
    companySize: companyProfileFromGraph.overview?.size?.description,
    primaryLocation:
      companyProfileFromGraph.overview?.primaryLocation || undefined,
    specialities: companyProfileFromGraph.overview?.specialities?.split(','),
    description:
      companyProfileFromGraph.overview?.description?.paragraphs || undefined,
  },
  foreignCompanyId: legacyCompanyProfile.foreign_company_id,
  branding: {
    logoImageUrl:
      companyProfileFromGraph.branding?.logo ||
      'https://image-service-cdn.seek.com.au/98ea82fb0e440087ccc2da65b549d18bdcb4a450/f3c5292cec0e05e4272d9bf9146f390d366481d0',
    coverImageUrl: companyProfileFromGraph.branding?.cover?.url || undefined,
  },
  rating: companyProfileFromGraph.reviewsSummary?.overallRating
    ? {
        average: companyProfileFromGraph.reviewsSummary.overallRating.value,
        count:
          companyProfileFromGraph.reviewsSummary.overallRating.numberOfReviews
            .value,
      }
    : undefined,
  overallRating: companyProfileFromGraph.reviewsSummary?.overallRating
    ? {
        average: companyProfileFromGraph.reviewsSummary.overallRating.value,
        count:
          companyProfileFromGraph.reviewsSummary.overallRating.numberOfReviews
            .value,
      }
    : undefined,
  awards: companyProfileFromGraph.awards,
  featured: companyProfileFromGraph.featured
    ? {
        description: companyProfileFromGraph.featured.description?.paragraphs,
        imageUrl: companyProfileFromGraph.featured.imageUrl || undefined,
        title: companyProfileFromGraph.featured.title,
        videoUrl: companyProfileFromGraph.featured.videoUrl || undefined,
      }
    : undefined,
  missionStatement: legacyCompanyProfile.mission_statement
    ? {
        description: legacyCompanyProfile.mission_statement,
      }
    : undefined,
  customStatement: legacyCompanyProfile.custom_statement
    ? {
        title: legacyCompanyProfile.custom_statement?.title || '',
        description: legacyCompanyProfile.custom_statement?.text || [],
      }
    : undefined,
  gallery: {
    name: companyProfileFromGraph.name,
    photos: legacyCompanyProfile?.gallery?.photo_video_gallery?.photos ?? [],
  },
  cultureAndValues: companyProfileFromGraph.cultureAndValues || undefined,
  perksAndBenefits: companyProfileFromGraph.perksAndBenefits || undefined,
  salaryRating: companyProfileFromGraph.reviewsSummary?.salaryRating.value,
  employeeRecommendationRating:
    companyProfileFromGraph.reviewsSummary?.employeeRecommendation.value,
  companyReviewSummaryBreakdown:
    companyProfileFromGraph.reviewsSummary?.ratings,
  ...(legacyCompanyReviewsSummary?.data?.company_review_summary
    ? {
        ratingBreakdown: mapLegacyRatingSummaryBreakdown(
          legacyCompanyReviewsSummary,
        ),
      }
    : {}),
});

export const mapReviewsModel = (
  companyProfile: CompanyProfileFromGraph,
  companyReviews: ReviewsFromGraph,
): Reviews => ({
  name: companyProfile.name,
  companyId: companyProfile.id,
  rating: companyProfile.reviewsSummary?.overallRating
    ? {
        average: companyProfile.reviewsSummary.overallRating.value,
        count:
          companyProfile.reviewsSummary.overallRating.numberOfReviews.value,
      }
    : undefined,
  reviews: companyReviews?.data?.map((r) => ({
    title: r.title || undefined,
    createdAt: r.createdAt.shortAbsoluteLabel,
    pros: r.pros,
    cons: r.cons,
    overallRating: r.overallRating,
    id: r.id,
    companyId: r.companyId,
    jobTitle: r.jobTitle || undefined,
    employmentStatus: r.employmentStatus || undefined,
    workLocation: r.workLocation || undefined,
    salarySummary: r.salarySummary,
    upvotes: r.upvotes || undefined,
    recommended: r.recommended,
    ratings: r.ratings,
    reply: r.reply
      ? {
          companyLogoUrl: companyProfile.branding?.logo || undefined,
          createdAt: r.reply.createdAt.dateTime,
          from: r.reply.from,
          text: r.reply.text,
          user: {
            userId: r.reply.userId,
          },
        }
      : undefined,
  })),
  paging: {
    page: companyReviews?.paging?.page,
    perPage: companyReviews?.paging?.perPage,
    total: companyReviews?.paging?.total,
    totalPages: companyReviews?.paging?.totalPages,
  },
});

const mapLegacyRatingSummaryBreakdown = (
  model: LegacyCompanyReviewsSummaryResult,
): CompanyProfileRatingBreakdown => {
  const companyReviewSummary = model?.data?.company_review_summary;
  return {
    companyRating1StarCount: companyReviewSummary?.company_rating_1_star_count ?? 0,
    companyRating1StarPercentage:
      companyReviewSummary?.company_rating_1_star_percentage ?? 0,
    companyRating2StarCount: companyReviewSummary?.company_rating_2_star_count ?? 0,
    companyRating2StarPercentage:
      companyReviewSummary?.company_rating_2_star_percentage ?? 0,
    companyRating3StarCount: companyReviewSummary?.company_rating_3_star_count ?? 0,
    companyRating3StarPercentage:
      companyReviewSummary?.company_rating_3_star_percentage ?? 0,
    companyRating4StarCount: companyReviewSummary?.company_rating_4_star_count ?? 0,
    companyRating4StarPercentage:
      companyReviewSummary?.company_rating_4_star_percentage ?? 0,
    companyRating5StarCount: companyReviewSummary?.company_rating_5_star_count ?? 0,
    companyRating5StarPercentage:
      companyReviewSummary?.company_rating_5_star_percentage ?? 0,
  };
};

// Legacy Company Profile Service does the mapping for now
export const mapJobsModel = (jobs: Jobs): Jobs => jobs;

export const mapSearchCompanyModel = (
  companySearchResults: LegacySearchCompaniesResult,
): SearchCompaniesResult => ({
  companies: companySearchResults?.data?.map((company) => ({
    logoUrl: company.logo_url,
    name: company.company_name,
    slug: company.company_name_slug,
    overallRating: {
      average: company.average_company_rating,
      count: company.reviews_count,
    },
  })),
  totalCompanies: companySearchResults.paging.total,
});

export const mapCompanyGroupsToFlatCompanies = (
  groupedCompanies: GroupedCompanies[],
): CompaniesListModel => groupedCompanies.flatMap((group) => group.companies.map((company) => ({
      name: company.name,
      slug: company.slug,
      id: company.id,
    })));
