
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0IjoiV2XigJlyZSB3b3JraW5nIG9uIGl0IiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbuKAmXQgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iLCJHbyB0byBob21lcGFnZSI6IkdvIHRvIGhvbWVwYWdlIiwiSWYgdGhpcyBzdGlsbCBkb2Vzbid0IHdvcmssIHJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIGZvciBhc3Npc3RhbmNlLiI6IklmIHRoaXMgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW08L1RleHRMaW5rPiBmb3IgYXNzaXN0YW5jZS4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0IjoiV2XigJlyZSB3b3JraW5nIG9uIGl0IiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbuKAmXQgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iLCJHbyB0byBob21lcGFnZSI6IkdvIHRvIGhvbWVwYWdlIiwiSWYgdGhpcyBzdGlsbCBkb2Vzbid0IHdvcmssIHJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIGZvciBhc3Npc3RhbmNlLiI6IklmIHRoaXMgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW08L1RleHRMaW5rPiBmb3IgYXNzaXN0YW5jZS4ifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0Ijoi4LmA4Lij4Liy4LiB4Liz4Lil4Lix4LiH4LmB4LiB4LmJ4LmE4LiC4LiC4LmJ4Lit4Lic4Li04LiU4Lie4Lil4Liy4LiUIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IuC5gOC4o+C4suC5guC4q+C4peC4lOC4quC4tOC5iOC4h+C4meC4teC5ieC5hOC4oeC5iOC5hOC4lOC5ieC5g+C4meC4guC4k+C4sOC4meC4teC5ieC5geC4leC5iOC5gOC4o+C4suC4geC4s+C4peC4seC4h+C4nuC4ouC4suC4ouC4suC4oeC5geC4geC5ieC5hOC4guC4reC4ouC5iOC4suC4h+C4quC4uOC4lOC4hOC4p+C4suC4oeC4quC4suC4oeC4suC4o+C4liIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoi4Lil4Lit4LiH4Lij4Li14LmA4Lif4Lij4LiK4Lir4LiZ4LmJ4Liy4LmA4Lie4LiI4Lir4Lij4Li34Lit4LiB4Lil4Lix4Lia4Lih4Liy4LiV4Lij4Lin4LiI4Liq4Lit4Lia4Lit4Li14LiB4LiE4Lij4Lix4LmJ4LiH4LmD4LiZ4Lig4Liy4Lii4Lir4Lil4Lix4LiHIiwiR28gdG8gaG9tZXBhZ2UiOiLguYTguJvguJfguLXguYjguKvguJnguYnguLLguKvguKXguLHguIEiLCJJZiB0aGlzIHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBTZXJ2aWNlIHRlYW0gZm9yIGFzc2lzdGFuY2UuIjoi4Lir4Liy4LiB4Lii4Lix4LiH4LiE4LiH4LmD4LiK4LmJ4LiB4Liy4Lij4LmE4Lih4LmI4LmE4LiU4LmJIOC5guC4m+C4o+C4lOC4leC4tOC4lOC4leC5iOC4rTxUZXh0TGluaz7guJfguLXguKHguJrguKPguLTguIHguLLguKPguKXguLnguIHguITguYnguLI8L1RleHRMaW5rPuC4guC4reC4h+C5gOC4o+C4suC4quC4s+C4q+C4o+C4seC4muC4hOC4p+C4suC4oeC4iuC5iOC4p+C4ouC5gOC4q+C4peC4t+C4rSJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0Ijoi4LmA4Lij4Liy4LiB4Liz4Lil4Lix4LiH4LmB4LiB4LmJ4LmE4LiC4LiC4LmJ4Lit4Lic4Li04LiU4Lie4Lil4Liy4LiUIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IuC5gOC4o+C4suC5guC4q+C4peC4lOC4quC4tOC5iOC4h+C4meC4teC5ieC5hOC4oeC5iOC5hOC4lOC5ieC5g+C4meC4guC4k+C4sOC4meC4teC5ieC5geC4leC5iOC5gOC4o+C4suC4geC4s+C4peC4seC4h+C4nuC4ouC4suC4ouC4suC4oeC5geC4geC5ieC5hOC4guC4reC4ouC5iOC4suC4h+C4quC4uOC4lOC4hOC4p+C4suC4oeC4quC4suC4oeC4suC4o+C4liIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoi4Lil4Lit4LiH4Lij4Li14LmA4Lif4Lij4LiK4Lir4LiZ4LmJ4Liy4LmA4Lie4LiI4Lir4Lij4Li34Lit4LiB4Lil4Lix4Lia4Lih4Liy4LiV4Lij4Lin4LiI4Liq4Lit4Lia4Lit4Li14LiB4LiE4Lij4Lix4LmJ4LiH4LmD4LiZ4Lig4Liy4Lii4Lir4Lil4Lix4LiHIiwiR28gdG8gaG9tZXBhZ2UiOiLguYTguJvguJfguLXguYjguKvguJnguYnguLLguKvguKXguLHguIEiLCJJZiB0aGlzIHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBTZXJ2aWNlIHRlYW0gZm9yIGFzc2lzdGFuY2UuIjoi4Lir4Liy4LiB4Lii4Lix4LiH4LiE4LiH4LmD4LiK4LmJ4LiB4Liy4Lij4LmE4Lih4LmI4LmE4LiU4LmJIOC5guC4m+C4o+C4lOC4leC4tOC4lOC4leC5iOC4rTxUZXh0TGluaz7guJfguLXguKHguJrguKPguLTguIHguLLguKPguKXguLnguIHguITguYnguLI8L1RleHRMaW5rPuC4guC4reC4h+C5gOC4o+C4suC4quC4s+C4q+C4o+C4seC4muC4hOC4p+C4suC4oeC4iuC5iOC4p+C4ouC5gOC4q+C4peC4t+C4rSJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0IjoiS2FtaSBzZWRhbmcgYmVydXNhaGEgbWVuZ2F0YXNpbnlhIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IlNhYXQgaW5pIGthbWkgdGlkYWsgZGFwYXQgbWVtdWF0bnlhIGRhbiBzZWRhbmcgYmVydXNhaGEgbWVtcGVyYmFpa2lueWEuIiwiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiJDb2JhIG11YXQgdWxhbmcgaGFsYW1hbiBhdGF1IHBlcmlrc2EgbGFnaSBuYW50aS4iLCJHbyB0byBob21lcGFnZSI6IktlbWJhbGkga2UgYmVyYW5kYSIsIklmIHRoaXMgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIFNlcnZpY2UgdGVhbSBmb3IgYXNzaXN0YW5jZS4iOiJKaWthIG1hc2loIHRpZGFrIGJlcmhhc2lsLCBodWJ1bmdpIDxUZXh0TGluaz50aW0gTGF5YW5hbiBQZWxhbmdnYW48L1RleHRMaW5rPiBrYW1pIHVudHVrIG1lbmRhcGF0a2FuIGJhbnR1YW4uIn0=!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0IjoiS2FtaSBzZWRhbmcgYmVydXNhaGEgbWVuZ2F0YXNpbnlhIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IlNhYXQgaW5pIGthbWkgdGlkYWsgZGFwYXQgbWVtdWF0bnlhIGRhbiBzZWRhbmcgYmVydXNhaGEgbWVtcGVyYmFpa2lueWEuIiwiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiJDb2JhIG11YXQgdWxhbmcgaGFsYW1hbiBhdGF1IHBlcmlrc2EgbGFnaSBuYW50aS4iLCJHbyB0byBob21lcGFnZSI6IktlbWJhbGkga2UgYmVyYW5kYSIsIklmIHRoaXMgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIFNlcnZpY2UgdGVhbSBmb3IgYXNzaXN0YW5jZS4iOiJKaWthIG1hc2loIHRpZGFrIGJlcmhhc2lsLCBodWJ1bmdpIDxUZXh0TGluaz50aW0gTGF5YW5hbiBQZWxhbmdnYW48L1RleHRMaW5rPiBrYW1pIHVudHVrIG1lbmRhcGF0a2FuIGJhbnR1YW4uIn0=!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0IjoiV2XigJlyZSB3b3JraW5nIG9uIGl0IiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbuKAmXQgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iLCJHbyB0byBob21lcGFnZSI6IkdvIHRvIGhvbWVwYWdlIiwiSWYgdGhpcyBzdGlsbCBkb2Vzbid0IHdvcmssIHJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIGZvciBhc3Npc3RhbmNlLiI6IklmIHRoaXMgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW08L1RleHRMaW5rPiBmb3IgYXNzaXN0YW5jZS4ifQ==!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0IjoiV2XigJlyZSB3b3JraW5nIG9uIGl0IiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbuKAmXQgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iLCJHbyB0byBob21lcGFnZSI6IkdvIHRvIGhvbWVwYWdlIiwiSWYgdGhpcyBzdGlsbCBkb2Vzbid0IHdvcmssIHJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIGZvciBhc3Npc3RhbmNlLiI6IklmIHRoaXMgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW08L1RleHRMaW5rPiBmb3IgYXNzaXN0YW5jZS4ifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0IjoiV2XigJlyZSB3b3JraW5nIG9uIGl0IiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbuKAmXQgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iLCJHbyB0byBob21lcGFnZSI6IkdvIHRvIGhvbWVwYWdlIiwiSWYgdGhpcyBzdGlsbCBkb2Vzbid0IHdvcmssIHJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIGZvciBhc3Npc3RhbmNlLiI6IklmIHRoaXMgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW08L1RleHRMaW5rPiBmb3IgYXNzaXN0YW5jZS4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0IjoiV2XigJlyZSB3b3JraW5nIG9uIGl0IiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbuKAmXQgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iLCJHbyB0byBob21lcGFnZSI6IkdvIHRvIGhvbWVwYWdlIiwiSWYgdGhpcyBzdGlsbCBkb2Vzbid0IHdvcmssIHJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIGZvciBhc3Npc3RhbmNlLiI6IklmIHRoaXMgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW08L1RleHRMaW5rPiBmb3IgYXNzaXN0YW5jZS4ifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0IjoiV2XigJlyZSB3b3JraW5nIG9uIGl0IiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbuKAmXQgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iLCJHbyB0byBob21lcGFnZSI6IkdvIHRvIGhvbWVwYWdlIiwiSWYgdGhpcyBzdGlsbCBkb2Vzbid0IHdvcmssIHJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIGZvciBhc3Npc3RhbmNlLiI6IklmIHRoaXMgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW08L1RleHRMaW5rPiBmb3IgYXNzaXN0YW5jZS4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0IjoiV2XigJlyZSB3b3JraW5nIG9uIGl0IiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbuKAmXQgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iLCJHbyB0byBob21lcGFnZSI6IkdvIHRvIGhvbWVwYWdlIiwiSWYgdGhpcyBzdGlsbCBkb2Vzbid0IHdvcmssIHJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIGZvciBhc3Npc3RhbmNlLiI6IklmIHRoaXMgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW08L1RleHRMaW5rPiBmb3IgYXNzaXN0YW5jZS4ifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0IjoiV2XigJlyZSB3b3JraW5nIG9uIGl0IiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbuKAmXQgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iLCJHbyB0byBob21lcGFnZSI6IkdvIHRvIGhvbWVwYWdlIiwiSWYgdGhpcyBzdGlsbCBkb2Vzbid0IHdvcmssIHJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIGZvciBhc3Npc3RhbmNlLiI6IklmIHRoaXMgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW08L1RleHRMaW5rPiBmb3IgYXNzaXN0YW5jZS4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0IjoiV2XigJlyZSB3b3JraW5nIG9uIGl0IiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbuKAmXQgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iLCJHbyB0byBob21lcGFnZSI6IkdvIHRvIGhvbWVwYWdlIiwiSWYgdGhpcyBzdGlsbCBkb2Vzbid0IHdvcmssIHJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIGZvciBhc3Npc3RhbmNlLiI6IklmIHRoaXMgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW08L1RleHRMaW5rPiBmb3IgYXNzaXN0YW5jZS4ifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0IjoiV2XigJlyZSB3b3JraW5nIG9uIGl0IiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbuKAmXQgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iLCJHbyB0byBob21lcGFnZSI6IkdvIHRvIGhvbWVwYWdlIiwiSWYgdGhpcyBzdGlsbCBkb2Vzbid0IHdvcmssIHJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIGZvciBhc3Npc3RhbmNlLiI6IklmIHRoaXMgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW08L1RleHRMaW5rPiBmb3IgYXNzaXN0YW5jZS4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0IjoiV2XigJlyZSB3b3JraW5nIG9uIGl0IiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbuKAmXQgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iLCJHbyB0byBob21lcGFnZSI6IkdvIHRvIGhvbWVwYWdlIiwiSWYgdGhpcyBzdGlsbCBkb2Vzbid0IHdvcmssIHJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIGZvciBhc3Npc3RhbmNlLiI6IklmIHRoaXMgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW08L1RleHRMaW5rPiBmb3IgYXNzaXN0YW5jZS4ifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0IjoiV2XigJlyZSB3b3JraW5nIG9uIGl0IiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbuKAmXQgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iLCJHbyB0byBob21lcGFnZSI6IkdvIHRvIGhvbWVwYWdlIiwiSWYgdGhpcyBzdGlsbCBkb2Vzbid0IHdvcmssIHJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIGZvciBhc3Npc3RhbmNlLiI6IklmIHRoaXMgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW08L1RleHRMaW5rPiBmb3IgYXNzaXN0YW5jZS4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0IjoiV2XigJlyZSB3b3JraW5nIG9uIGl0IiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbuKAmXQgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iLCJHbyB0byBob21lcGFnZSI6IkdvIHRvIGhvbWVwYWdlIiwiSWYgdGhpcyBzdGlsbCBkb2Vzbid0IHdvcmssIHJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIGZvciBhc3Npc3RhbmNlLiI6IklmIHRoaXMgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW08L1RleHRMaW5rPiBmb3IgYXNzaXN0YW5jZS4ifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0IjoiV2XigJlyZSB3b3JraW5nIG9uIGl0IiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbuKAmXQgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iLCJHbyB0byBob21lcGFnZSI6IkdvIHRvIGhvbWVwYWdlIiwiSWYgdGhpcyBzdGlsbCBkb2Vzbid0IHdvcmssIHJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIGZvciBhc3Npc3RhbmNlLiI6IklmIHRoaXMgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW08L1RleHRMaW5rPiBmb3IgYXNzaXN0YW5jZS4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0IjoiV2XigJlyZSB3b3JraW5nIG9uIGl0IiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbuKAmXQgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iLCJHbyB0byBob21lcGFnZSI6IkdvIHRvIGhvbWVwYWdlIiwiSWYgdGhpcyBzdGlsbCBkb2Vzbid0IHdvcmssIHJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIGZvciBhc3Npc3RhbmNlLiI6IklmIHRoaXMgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW08L1RleHRMaW5rPiBmb3IgYXNzaXN0YW5jZS4ifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0IjoiV2XigJlyZSB3b3JraW5nIG9uIGl0IiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbuKAmXQgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iLCJHbyB0byBob21lcGFnZSI6IkdvIHRvIGhvbWVwYWdlIiwiSWYgdGhpcyBzdGlsbCBkb2Vzbid0IHdvcmssIHJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIGZvciBhc3Npc3RhbmNlLiI6IklmIHRoaXMgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW08L1RleHRMaW5rPiBmb3IgYXNzaXN0YW5jZS4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0IjoiV2XigJlyZSB3b3JraW5nIG9uIGl0IiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbuKAmXQgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iLCJHbyB0byBob21lcGFnZSI6IkdvIHRvIGhvbWVwYWdlIiwiSWYgdGhpcyBzdGlsbCBkb2Vzbid0IHdvcmssIHJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIGZvciBhc3Npc3RhbmNlLiI6IklmIHRoaXMgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW08L1RleHRMaW5rPiBmb3IgYXNzaXN0YW5jZS4ifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0IjoiV2XigJlyZSB3b3JraW5nIG9uIGl0IiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbuKAmXQgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iLCJHbyB0byBob21lcGFnZSI6IkdvIHRvIGhvbWVwYWdlIiwiSWYgdGhpcyBzdGlsbCBkb2Vzbid0IHdvcmssIHJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIGZvciBhc3Npc3RhbmNlLiI6IklmIHRoaXMgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW08L1RleHRMaW5rPiBmb3IgYXNzaXN0YW5jZS4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0IjoiV2XigJlyZSB3b3JraW5nIG9uIGl0IiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbuKAmXQgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iLCJHbyB0byBob21lcGFnZSI6IkdvIHRvIGhvbWVwYWdlIiwiSWYgdGhpcyBzdGlsbCBkb2Vzbid0IHdvcmssIHJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIGZvciBhc3Npc3RhbmNlLiI6IklmIHRoaXMgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW08L1RleHRMaW5rPiBmb3IgYXNzaXN0YW5jZS4ifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0Ijoi4LmA4Lij4Liy4LiB4Liz4Lil4Lix4LiH4LmB4LiB4LmJ4LmE4LiC4LiC4LmJ4Lit4Lic4Li04LiU4Lie4Lil4Liy4LiUIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IuC5gOC4o+C4suC5guC4q+C4peC4lOC4quC4tOC5iOC4h+C4meC4teC5ieC5hOC4oeC5iOC5hOC4lOC5ieC5g+C4meC4guC4k+C4sOC4meC4teC5ieC5geC4leC5iOC5gOC4o+C4suC4geC4s+C4peC4seC4h+C4nuC4ouC4suC4ouC4suC4oeC5geC4geC5ieC5hOC4guC4reC4ouC5iOC4suC4h+C4quC4uOC4lOC4hOC4p+C4suC4oeC4quC4suC4oeC4suC4o+C4liIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoi4Lil4Lit4LiH4Lij4Li14LmA4Lif4Lij4LiK4Lir4LiZ4LmJ4Liy4LmA4Lie4LiI4Lir4Lij4Li34Lit4LiB4Lil4Lix4Lia4Lih4Liy4LiV4Lij4Lin4LiI4Liq4Lit4Lia4Lit4Li14LiB4LiE4Lij4Lix4LmJ4LiH4LmD4LiZ4Lig4Liy4Lii4Lir4Lil4Lix4LiHIiwiR28gdG8gaG9tZXBhZ2UiOiLguYTguJvguJfguLXguYjguKvguJnguYnguLLguKvguKXguLHguIEiLCJJZiB0aGlzIHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBTZXJ2aWNlIHRlYW0gZm9yIGFzc2lzdGFuY2UuIjoi4Lir4Liy4LiB4Lii4Lix4LiH4LiE4LiH4LmD4LiK4LmJ4LiB4Liy4Lij4LmE4Lih4LmI4LmE4LiU4LmJIOC5guC4m+C4o+C4lOC4leC4tOC4lOC4leC5iOC4rTxUZXh0TGluaz7guJfguLXguKHguJrguKPguLTguIHguLLguKPguKXguLnguIHguITguYnguLI8L1RleHRMaW5rPuC4guC4reC4h+C5gOC4o+C4suC4quC4s+C4q+C4o+C4seC4muC4hOC4p+C4suC4oeC4iuC5iOC4p+C4ouC5gOC4q+C4peC4t+C4rSJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0Ijoi4LmA4Lij4Liy4LiB4Liz4Lil4Lix4LiH4LmB4LiB4LmJ4LmE4LiC4LiC4LmJ4Lit4Lic4Li04LiU4Lie4Lil4Liy4LiUIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IuC5gOC4o+C4suC5guC4q+C4peC4lOC4quC4tOC5iOC4h+C4meC4teC5ieC5hOC4oeC5iOC5hOC4lOC5ieC5g+C4meC4guC4k+C4sOC4meC4teC5ieC5geC4leC5iOC5gOC4o+C4suC4geC4s+C4peC4seC4h+C4nuC4ouC4suC4ouC4suC4oeC5geC4geC5ieC5hOC4guC4reC4ouC5iOC4suC4h+C4quC4uOC4lOC4hOC4p+C4suC4oeC4quC4suC4oeC4suC4o+C4liIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoi4Lil4Lit4LiH4Lij4Li14LmA4Lif4Lij4LiK4Lir4LiZ4LmJ4Liy4LmA4Lie4LiI4Lir4Lij4Li34Lit4LiB4Lil4Lix4Lia4Lih4Liy4LiV4Lij4Lin4LiI4Liq4Lit4Lia4Lit4Li14LiB4LiE4Lij4Lix4LmJ4LiH4LmD4LiZ4Lig4Liy4Lii4Lir4Lil4Lix4LiHIiwiR28gdG8gaG9tZXBhZ2UiOiLguYTguJvguJfguLXguYjguKvguJnguYnguLLguKvguKXguLHguIEiLCJJZiB0aGlzIHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBTZXJ2aWNlIHRlYW0gZm9yIGFzc2lzdGFuY2UuIjoi4Lir4Liy4LiB4Lii4Lix4LiH4LiE4LiH4LmD4LiK4LmJ4LiB4Liy4Lij4LmE4Lih4LmI4LmE4LiU4LmJIOC5guC4m+C4o+C4lOC4leC4tOC4lOC4leC5iOC4rTxUZXh0TGluaz7guJfguLXguKHguJrguKPguLTguIHguLLguKPguKXguLnguIHguITguYnguLI8L1RleHRMaW5rPuC4guC4reC4h+C5gOC4o+C4suC4quC4s+C4q+C4o+C4seC4muC4hOC4p+C4suC4oeC4iuC5iOC4p+C4ouC5gOC4q+C4peC4t+C4rSJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0IjoiS2FtaSBzZWRhbmcgYmVydXNhaGEgbWVuZ2F0YXNpbnlhIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IlNhYXQgaW5pIGthbWkgdGlkYWsgZGFwYXQgbWVtdWF0bnlhIGRhbiBzZWRhbmcgYmVydXNhaGEgbWVtcGVyYmFpa2lueWEuIiwiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiJDb2JhIG11YXQgdWxhbmcgaGFsYW1hbiBhdGF1IHBlcmlrc2EgbGFnaSBuYW50aS4iLCJHbyB0byBob21lcGFnZSI6IktlbWJhbGkga2UgYmVyYW5kYSIsIklmIHRoaXMgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIFNlcnZpY2UgdGVhbSBmb3IgYXNzaXN0YW5jZS4iOiJKaWthIG1hc2loIHRpZGFrIGJlcmhhc2lsLCBodWJ1bmdpIDxUZXh0TGluaz50aW0gTGF5YW5hbiBQZWxhbmdnYW48L1RleHRMaW5rPiBrYW1pIHVudHVrIG1lbmRhcGF0a2FuIGJhbnR1YW4uIn0=!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0IjoiS2FtaSBzZWRhbmcgYmVydXNhaGEgbWVuZ2F0YXNpbnlhIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IlNhYXQgaW5pIGthbWkgdGlkYWsgZGFwYXQgbWVtdWF0bnlhIGRhbiBzZWRhbmcgYmVydXNhaGEgbWVtcGVyYmFpa2lueWEuIiwiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiJDb2JhIG11YXQgdWxhbmcgaGFsYW1hbiBhdGF1IHBlcmlrc2EgbGFnaSBuYW50aS4iLCJHbyB0byBob21lcGFnZSI6IktlbWJhbGkga2UgYmVyYW5kYSIsIklmIHRoaXMgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIFNlcnZpY2UgdGVhbSBmb3IgYXNzaXN0YW5jZS4iOiJKaWthIG1hc2loIHRpZGFrIGJlcmhhc2lsLCBodWJ1bmdpIDxUZXh0TGluaz50aW0gTGF5YW5hbiBQZWxhbmdnYW48L1RleHRMaW5rPiBrYW1pIHVudHVrIG1lbmRhcGF0a2FuIGJhbnR1YW4uIn0=!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0IjoiV2XigJlyZSB3b3JraW5nIG9uIGl0IiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbuKAmXQgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iLCJHbyB0byBob21lcGFnZSI6IkdvIHRvIGhvbWVwYWdlIiwiSWYgdGhpcyBzdGlsbCBkb2Vzbid0IHdvcmssIHJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIGZvciBhc3Npc3RhbmNlLiI6IklmIHRoaXMgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW08L1RleHRMaW5rPiBmb3IgYXNzaXN0YW5jZS4ifQ==!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0IjoiV2XigJlyZSB3b3JraW5nIG9uIGl0IiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbuKAmXQgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iLCJHbyB0byBob21lcGFnZSI6IkdvIHRvIGhvbWVwYWdlIiwiSWYgdGhpcyBzdGlsbCBkb2Vzbid0IHdvcmssIHJlYWNoIG91dCB0byBvdXIgQ3VzdG9tZXIgU2VydmljZSB0ZWFtIGZvciBhc3Npc3RhbmNlLiI6IklmIHRoaXMgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW08L1RleHRMaW5rPiBmb3IgYXNzaXN0YW5jZS4ifQ==!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0IjoiW8W04bq94bq94bq94oCZxZnhur3hur3hur0gxbXDtsO2w7bFmcS3w6zDrMOs4LiB4Li14LmJxKMgw7bDtsO24LiB4Li14LmJIMOsw6zDrOG5r10iLCJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93IGJ1dCB3ZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggaXQuIjoiW8W04bq94bq94bq9IMOnxIPEg8SD4LiB4Li14LmJ4oCZ4bmvIMaaw7bDtsO2xIPEg8SDxowg4bmv4bipw6zDrMOsxaEgxZnDrMOsw6zEo+G4qeG5ryDguIHguLXguYnDtsO2w7bFtSDDn8eax5rHmuG5ryDFteG6veG6veG6vSfFmeG6veG6veG6vSDGjMO2w7bDtsOsw6zDrOC4geC4teC5icSjIMO2w7bDtseax5rHmsWZIMOf4bq94bq94bq9xaHhua8g4bmvw7bDtsO2IMaSw6zDrMOs6q2VIMOsw6zDrOG5ry5dIiwiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiJb4bmuxZnDvcO9w70gxZnhur3hur3hur3GksWZ4bq94bq94bq9xaHhuKnDrMOsw6zguIHguLXguYnEoyDhua/huKnhur3hur3hur0gxqXEg8SDxIPEo+G6veG6veG6vSDDtsO2w7bFmSDDp+G4qeG6veG6veG6vcOnxLcgw5/Eg8SDxIPDp8S3IMaaxIPEg8SD4bmv4bq94bq94bq9xZkuXSIsIkdvIHRvIGhvbWVwYWdlIjoiW8emw7bDtiDhua/DtsO2IOG4qcO2w7ZtzILhur3hur3GpcSDxIPEo+G6veG6vV0iLCJJZiB0aGlzIHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBTZXJ2aWNlIHRlYW0gZm9yIGFzc2lzdGFuY2UuIjoiW8OPxpIg4bmv4bipw6zDrMOsxaEgxaHhua/DrMOsw6zGmsaaIMaMw7bDtsO24bq94bq94bq9xaHguIHguLXguYkn4bmvIMW1w7bDtsO2xZnEtywgxZnhur3hur3hur3Eg8SDxIPDp+G4qSDDtsO2w7bHmseax5rhua8g4bmvw7bDtsO2IMO2w7bDtseax5rHmsWZIDxUZXh0TGluaz7Dh8eax5rHmsWh4bmvw7bDtsO2bcyC4bq94bq94bq9xZkgxaDhur3hur3hur3FmeG5vcOsw6zDrMOn4bq94bq94bq9IOG5r+G6veG6veG6vcSDxIPEg23MgjwvVGV4dExpbms+IMaSw7bDtsO2xZkgxIPEg8SDxaHFocOsw6zDrMWh4bmvxIPEg8SD4LiB4Li14LmJw6fhur3hur3hur0uXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0IjoiW8W04bq94bq94bq94oCZxZnhur3hur3hur0gxbXDtsO2w7bFmcS3w6zDrMOs4LiB4Li14LmJxKMgw7bDtsO24LiB4Li14LmJIMOsw6zDrOG5r10iLCJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93IGJ1dCB3ZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggaXQuIjoiW8W04bq94bq94bq9IMOnxIPEg8SD4LiB4Li14LmJ4oCZ4bmvIMaaw7bDtsO2xIPEg8SDxowg4bmv4bipw6zDrMOsxaEgxZnDrMOsw6zEo+G4qeG5ryDguIHguLXguYnDtsO2w7bFtSDDn8eax5rHmuG5ryDFteG6veG6veG6vSfFmeG6veG6veG6vSDGjMO2w7bDtsOsw6zDrOC4geC4teC5icSjIMO2w7bDtseax5rHmsWZIMOf4bq94bq94bq9xaHhua8g4bmvw7bDtsO2IMaSw6zDrMOs6q2VIMOsw6zDrOG5ry5dIiwiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiJb4bmuxZnDvcO9w70gxZnhur3hur3hur3GksWZ4bq94bq94bq9xaHhuKnDrMOsw6zguIHguLXguYnEoyDhua/huKnhur3hur3hur0gxqXEg8SDxIPEo+G6veG6veG6vSDDtsO2w7bFmSDDp+G4qeG6veG6veG6vcOnxLcgw5/Eg8SDxIPDp8S3IMaaxIPEg8SD4bmv4bq94bq94bq9xZkuXSIsIkdvIHRvIGhvbWVwYWdlIjoiW8emw7bDtiDhua/DtsO2IOG4qcO2w7ZtzILhur3hur3GpcSDxIPEo+G6veG6vV0iLCJJZiB0aGlzIHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBTZXJ2aWNlIHRlYW0gZm9yIGFzc2lzdGFuY2UuIjoiW8OPxpIg4bmv4bipw6zDrMOsxaEgxaHhua/DrMOsw6zGmsaaIMaMw7bDtsO24bq94bq94bq9xaHguIHguLXguYkn4bmvIMW1w7bDtsO2xZnEtywgxZnhur3hur3hur3Eg8SDxIPDp+G4qSDDtsO2w7bHmseax5rhua8g4bmvw7bDtsO2IMO2w7bDtseax5rHmsWZIDxUZXh0TGluaz7Dh8eax5rHmsWh4bmvw7bDtsO2bcyC4bq94bq94bq9xZkgxaDhur3hur3hur3FmeG5vcOsw6zDrMOn4bq94bq94bq9IOG5r+G6veG6veG6vcSDxIPEg23MgjwvVGV4dExpbms+IMaSw7bDtsO2xZkgxIPEg8SDxaHFocOsw6zDrMWh4bmvxIPEg8SD4LiB4Li14LmJw6fhur3hur3hur0uXSJ9!"
        )
      )
      });
  
      export { translations as default };
    