
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc+e3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc+e3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiLguKrguLTguYjguIfguJfguLXguYjguJvguKPguLDguJfguLHguJrguYPguIgiLCJUaGUgY2hhbGxlbmdlcyI6IuC4hOC4p+C4suC4oeC4l+C5ieC4suC4l+C4suC4oiIsIkhlbHBmdWw/Ijoi4Lih4Li14Lib4Lij4Liw4LmC4Lii4LiK4LiZ4LmMPyIsInBlb3BsZSI6IuC4hOC4mSIsIlNhdmUiOiLguJrguLHguJnguJfguLbguIEiLCJSZXBseSI6IuC4leC4reC4muC4geC4peC4seC4miIsIjEgcGVyc29uIjoiMSDguITguJkiLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiLguKHguK3guIfguKfguYjguLLguKHguLXguJvguKPguLDguYLguKLguIrguJnguYwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiLguKXguLTguIfguIHguYzguJXguK3guJrguIHguKXguLHguJrguKvguKHguJTguK3guLLguKLguLgiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiLguKXguLTguIfguIHguYzguJXguK3guJrguIHguKXguLHguJrguJnguLXguYnguKvguKHguJTguK3guLLguKLguLgiLCJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluayI6IuC5gOC4nuC4t+C5iOC4reC4o+C4seC4muC4quC4tOC4l+C4mOC4tOC4geC4suC4o+C5gOC4guC5ieC4suC4luC4tuC4h+C4peC4tOC4h+C4geC5jOC4leC4reC4muC4geC4peC4seC4muC4l+C4teC5iOC4l+C4s+C4h+C4suC4meC4reC4ouC4ueC5iCDguYLguJvguKPguJQ8VGV4dExpbms+4LiV4Li04LiU4LiV4LmI4Lit4Lid4LmI4Liy4Lii4Lia4Lij4Li04LiB4Liy4Lij4Lil4Li54LiB4LiE4LmJ4LiyPC9UZXh0TGluaz4g4Lir4Lij4Li34Lit4LmC4LiX4Lij4LmE4Lib4LiX4Li14LmI4Lir4Lih4Liy4Lii4LmA4Lil4LiCIDxTdHJvbmc+e3Bob25lTnVtYmVyfTwvU3Ryb25nPiIsIlJlcG9ydCB0aGlzIHJldmlldyI6IlJlcG9ydCB0aGlzIHJldmlldyJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiLguKrguLTguYjguIfguJfguLXguYjguJvguKPguLDguJfguLHguJrguYPguIgiLCJUaGUgY2hhbGxlbmdlcyI6IuC4hOC4p+C4suC4oeC4l+C5ieC4suC4l+C4suC4oiIsIkhlbHBmdWw/Ijoi4Lih4Li14Lib4Lij4Liw4LmC4Lii4LiK4LiZ4LmMPyIsInBlb3BsZSI6IuC4hOC4mSIsIlNhdmUiOiLguJrguLHguJnguJfguLbguIEiLCJSZXBseSI6IuC4leC4reC4muC4geC4peC4seC4miIsIjEgcGVyc29uIjoiMSDguITguJkiLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiLguKHguK3guIfguKfguYjguLLguKHguLXguJvguKPguLDguYLguKLguIrguJnguYwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiLguKXguLTguIfguIHguYzguJXguK3guJrguIHguKXguLHguJrguKvguKHguJTguK3guLLguKLguLgiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiLguKXguLTguIfguIHguYzguJXguK3guJrguIHguKXguLHguJrguJnguLXguYnguKvguKHguJTguK3guLLguKLguLgiLCJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluayI6IuC5gOC4nuC4t+C5iOC4reC4o+C4seC4muC4quC4tOC4l+C4mOC4tOC4geC4suC4o+C5gOC4guC5ieC4suC4luC4tuC4h+C4peC4tOC4h+C4geC5jOC4leC4reC4muC4geC4peC4seC4muC4l+C4teC5iOC4l+C4s+C4h+C4suC4meC4reC4ouC4ueC5iCDguYLguJvguKPguJQ8VGV4dExpbms+4LiV4Li04LiU4LiV4LmI4Lit4Lid4LmI4Liy4Lii4Lia4Lij4Li04LiB4Liy4Lij4Lil4Li54LiB4LiE4LmJ4LiyPC9UZXh0TGluaz4g4Lir4Lij4Li34Lit4LmC4LiX4Lij4LmE4Lib4LiX4Li14LmI4Lir4Lih4Liy4Lii4LmA4Lil4LiCIDxTdHJvbmc+e3Bob25lTnVtYmVyfTwvU3Ryb25nPiIsIlJlcG9ydCB0aGlzIHJldmlldyI6IlJlcG9ydCB0aGlzIHJldmlldyJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJIYWwtaGFsIGJhaWsiLCJUaGUgY2hhbGxlbmdlcyI6IlRhbnRhbmdhbiIsIkhlbHBmdWw/IjoiTWVtYmFudHU/IiwicGVvcGxlIjoib3JhbmciLCJTYXZlIjoiU2ltcGFuIiwiUmVwbHkiOiJKYXdhYiIsIjEgcGVyc29uIjoiMSBvcmFuZyIsImZvdW5kIHRoaXMgaGVscGZ1bCI6Im1lbmdhbmdnYXAgaW5pIG1lbWJhbnR1IiwiRXhwaXJlZCBSZXBseSBMaW5rIjoiVGF1dGFuIEJhbGFzYW4gS2VkYWx1d2Fyc2EiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUYXV0YW4gYmFsYXNhbiBpbmkgdGVsYWgga2VkYWx1d2Fyc2EuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJBZ2FyIGRhcGF0IG1lbmdha3NlcyB0YXV0YW4gYmFsYXNhbiB5YW5nIGJlcmZ1bmdzaSwgPFRleHRMaW5rPmh1YnVuZ2kgTGF5YW5hbiBQZWxhbmdnYW48L1RleHRMaW5rPiBhdGF1IHRlbGVwb24gPFN0cm9uZz57cGhvbmVOdW1iZXJ9PC9TdHJvbmc+LiIsIlJlcG9ydCB0aGlzIHJldmlldyI6IlJlcG9ydCB0aGlzIHJldmlldyJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJIYWwtaGFsIGJhaWsiLCJUaGUgY2hhbGxlbmdlcyI6IlRhbnRhbmdhbiIsIkhlbHBmdWw/IjoiTWVtYmFudHU/IiwicGVvcGxlIjoib3JhbmciLCJTYXZlIjoiU2ltcGFuIiwiUmVwbHkiOiJKYXdhYiIsIjEgcGVyc29uIjoiMSBvcmFuZyIsImZvdW5kIHRoaXMgaGVscGZ1bCI6Im1lbmdhbmdnYXAgaW5pIG1lbWJhbnR1IiwiRXhwaXJlZCBSZXBseSBMaW5rIjoiVGF1dGFuIEJhbGFzYW4gS2VkYWx1d2Fyc2EiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUYXV0YW4gYmFsYXNhbiBpbmkgdGVsYWgga2VkYWx1d2Fyc2EuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJBZ2FyIGRhcGF0IG1lbmdha3NlcyB0YXV0YW4gYmFsYXNhbiB5YW5nIGJlcmZ1bmdzaSwgPFRleHRMaW5rPmh1YnVuZ2kgTGF5YW5hbiBQZWxhbmdnYW48L1RleHRMaW5rPiBhdGF1IHRlbGVwb24gPFN0cm9uZz57cGhvbmVOdW1iZXJ9PC9TdHJvbmc+LiIsIlJlcG9ydCB0aGlzIHJldmlldyI6IlJlcG9ydCB0aGlzIHJldmlldyJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc+e3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ==!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc+e3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc+e3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc+e3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc+e3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc+e3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc+e3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc+e3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc+e3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc+e3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc+e3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc+e3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc+e3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc+e3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc+e3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc+e3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc+e3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc+e3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiLguKrguLTguYjguIfguJfguLXguYjguJvguKPguLDguJfguLHguJrguYPguIgiLCJUaGUgY2hhbGxlbmdlcyI6IuC4hOC4p+C4suC4oeC4l+C5ieC4suC4l+C4suC4oiIsIkhlbHBmdWw/Ijoi4Lih4Li14Lib4Lij4Liw4LmC4Lii4LiK4LiZ4LmMPyIsInBlb3BsZSI6IuC4hOC4mSIsIlNhdmUiOiLguJrguLHguJnguJfguLbguIEiLCJSZXBseSI6IuC4leC4reC4muC4geC4peC4seC4miIsIjEgcGVyc29uIjoiMSDguITguJkiLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiLguKHguK3guIfguKfguYjguLLguKHguLXguJvguKPguLDguYLguKLguIrguJnguYwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiLguKXguLTguIfguIHguYzguJXguK3guJrguIHguKXguLHguJrguKvguKHguJTguK3guLLguKLguLgiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiLguKXguLTguIfguIHguYzguJXguK3guJrguIHguKXguLHguJrguJnguLXguYnguKvguKHguJTguK3guLLguKLguLgiLCJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluayI6IuC5gOC4nuC4t+C5iOC4reC4o+C4seC4muC4quC4tOC4l+C4mOC4tOC4geC4suC4o+C5gOC4guC5ieC4suC4luC4tuC4h+C4peC4tOC4h+C4geC5jOC4leC4reC4muC4geC4peC4seC4muC4l+C4teC5iOC4l+C4s+C4h+C4suC4meC4reC4ouC4ueC5iCDguYLguJvguKPguJQ8VGV4dExpbms+4LiV4Li04LiU4LiV4LmI4Lit4Lid4LmI4Liy4Lii4Lia4Lij4Li04LiB4Liy4Lij4Lil4Li54LiB4LiE4LmJ4LiyPC9UZXh0TGluaz4g4Lir4Lij4Li34Lit4LmC4LiX4Lij4LmE4Lib4LiX4Li14LmI4Lir4Lih4Liy4Lii4LmA4Lil4LiCIDxTdHJvbmc+e3Bob25lTnVtYmVyfTwvU3Ryb25nPiIsIlJlcG9ydCB0aGlzIHJldmlldyI6IlJlcG9ydCB0aGlzIHJldmlldyJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiLguKrguLTguYjguIfguJfguLXguYjguJvguKPguLDguJfguLHguJrguYPguIgiLCJUaGUgY2hhbGxlbmdlcyI6IuC4hOC4p+C4suC4oeC4l+C5ieC4suC4l+C4suC4oiIsIkhlbHBmdWw/Ijoi4Lih4Li14Lib4Lij4Liw4LmC4Lii4LiK4LiZ4LmMPyIsInBlb3BsZSI6IuC4hOC4mSIsIlNhdmUiOiLguJrguLHguJnguJfguLbguIEiLCJSZXBseSI6IuC4leC4reC4muC4geC4peC4seC4miIsIjEgcGVyc29uIjoiMSDguITguJkiLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiLguKHguK3guIfguKfguYjguLLguKHguLXguJvguKPguLDguYLguKLguIrguJnguYwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiLguKXguLTguIfguIHguYzguJXguK3guJrguIHguKXguLHguJrguKvguKHguJTguK3guLLguKLguLgiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiLguKXguLTguIfguIHguYzguJXguK3guJrguIHguKXguLHguJrguJnguLXguYnguKvguKHguJTguK3guLLguKLguLgiLCJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluayI6IuC5gOC4nuC4t+C5iOC4reC4o+C4seC4muC4quC4tOC4l+C4mOC4tOC4geC4suC4o+C5gOC4guC5ieC4suC4luC4tuC4h+C4peC4tOC4h+C4geC5jOC4leC4reC4muC4geC4peC4seC4muC4l+C4teC5iOC4l+C4s+C4h+C4suC4meC4reC4ouC4ueC5iCDguYLguJvguKPguJQ8VGV4dExpbms+4LiV4Li04LiU4LiV4LmI4Lit4Lid4LmI4Liy4Lii4Lia4Lij4Li04LiB4Liy4Lij4Lil4Li54LiB4LiE4LmJ4LiyPC9UZXh0TGluaz4g4Lir4Lij4Li34Lit4LmC4LiX4Lij4LmE4Lib4LiX4Li14LmI4Lir4Lih4Liy4Lii4LmA4Lil4LiCIDxTdHJvbmc+e3Bob25lTnVtYmVyfTwvU3Ryb25nPiIsIlJlcG9ydCB0aGlzIHJldmlldyI6IlJlcG9ydCB0aGlzIHJldmlldyJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJIYWwtaGFsIGJhaWsiLCJUaGUgY2hhbGxlbmdlcyI6IlRhbnRhbmdhbiIsIkhlbHBmdWw/IjoiTWVtYmFudHU/IiwicGVvcGxlIjoib3JhbmciLCJTYXZlIjoiU2ltcGFuIiwiUmVwbHkiOiJKYXdhYiIsIjEgcGVyc29uIjoiMSBvcmFuZyIsImZvdW5kIHRoaXMgaGVscGZ1bCI6Im1lbmdhbmdnYXAgaW5pIG1lbWJhbnR1IiwiRXhwaXJlZCBSZXBseSBMaW5rIjoiVGF1dGFuIEJhbGFzYW4gS2VkYWx1d2Fyc2EiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUYXV0YW4gYmFsYXNhbiBpbmkgdGVsYWgga2VkYWx1d2Fyc2EuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJBZ2FyIGRhcGF0IG1lbmdha3NlcyB0YXV0YW4gYmFsYXNhbiB5YW5nIGJlcmZ1bmdzaSwgPFRleHRMaW5rPmh1YnVuZ2kgTGF5YW5hbiBQZWxhbmdnYW48L1RleHRMaW5rPiBhdGF1IHRlbGVwb24gPFN0cm9uZz57cGhvbmVOdW1iZXJ9PC9TdHJvbmc+LiIsIlJlcG9ydCB0aGlzIHJldmlldyI6IlJlcG9ydCB0aGlzIHJldmlldyJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJIYWwtaGFsIGJhaWsiLCJUaGUgY2hhbGxlbmdlcyI6IlRhbnRhbmdhbiIsIkhlbHBmdWw/IjoiTWVtYmFudHU/IiwicGVvcGxlIjoib3JhbmciLCJTYXZlIjoiU2ltcGFuIiwiUmVwbHkiOiJKYXdhYiIsIjEgcGVyc29uIjoiMSBvcmFuZyIsImZvdW5kIHRoaXMgaGVscGZ1bCI6Im1lbmdhbmdnYXAgaW5pIG1lbWJhbnR1IiwiRXhwaXJlZCBSZXBseSBMaW5rIjoiVGF1dGFuIEJhbGFzYW4gS2VkYWx1d2Fyc2EiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUYXV0YW4gYmFsYXNhbiBpbmkgdGVsYWgga2VkYWx1d2Fyc2EuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJBZ2FyIGRhcGF0IG1lbmdha3NlcyB0YXV0YW4gYmFsYXNhbiB5YW5nIGJlcmZ1bmdzaSwgPFRleHRMaW5rPmh1YnVuZ2kgTGF5YW5hbiBQZWxhbmdnYW48L1RleHRMaW5rPiBhdGF1IHRlbGVwb24gPFN0cm9uZz57cGhvbmVOdW1iZXJ9PC9TdHJvbmc+LiIsIlJlcG9ydCB0aGlzIHJldmlldyI6IlJlcG9ydCB0aGlzIHJldmlldyJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc+e3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ==!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJUaGUgZ29vZCB0aGluZ3MiLCJUaGUgY2hhbGxlbmdlcyI6IlRoZSBjaGFsbGVuZ2VzIiwiSGVscGZ1bD8iOiJIZWxwZnVsPyIsInBlb3BsZSI6InBlb3BsZSIsIlNhdmUiOiJTYXZlIiwiUmVwbHkiOiJSZXBseSIsIjEgcGVyc29uIjoiMSBwZXJzb24iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJmb3VuZCB0aGlzIGhlbHBmdWwiLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJFeHBpcmVkIFJlcGx5IExpbmsiLCJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIHJlcGx5IGxpbmsgaGFzIGV4cGlyZWQuIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJUbyBnYWluIGFjY2VzcyB0byBhIHdvcmtpbmcgcmVwbHkgbGluaywgcGxlYXNlIDxUZXh0TGluaz5jb250YWN0IEN1c3RvbWVyIFNlcnZpY2U8L1RleHRMaW5rPiBvciBjYWxsIDxTdHJvbmc+e3Bob25lTnVtYmVyfTwvU3Ryb25nPi4iLCJSZXBvcnQgdGhpcyByZXZpZXciOiJSZXBvcnQgdGhpcyByZXZpZXcifQ==!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJb4bmu4bip4bq94bq94bq9IMSjw7bDtsO2w7bDtsO2xowg4bmv4bipw6zDrMOs4LiB4Li14LmJxKPFoV0iLCJUaGUgY2hhbGxlbmdlcyI6Ilvhua7huKnhur3hur3hur0gw6fhuKnEg8SDxIPGmsaa4bq94bq94bq94LiB4Li14LmJxKPhur3hur3hur3FoV0iLCJIZWxwZnVsPyI6IlvhuKjhur3hur3hur3GmsalxpLHmseax5rGmj9dIiwicGVvcGxlIjoiW8al4bq94bq9w7bDtsalxprhur3hur1dIiwiU2F2ZSI6IlvFoMSDxIPhub3hur3hur1dIiwiUmVwbHkiOiJbxZjhur3hur3Gpcaaw73DvV0iLCIxIHBlcnNvbiI6IlsxIMal4bq94bq94bq9xZnFocO2w7bDtuC4geC4teC5iV0iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJbxpLDtsO2w7bHmseax5rguIHguLXguYnGjCDhua/huKnDrMOsw6zFoSDhuKnhur3hur3hur3GmsalxpLHmseax5rGml0iLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJbw4vqrZXGpcOsw6zDrMWZ4bq94bq94bq9xowgxZjhur3hur3hur3Gpcaaw73DvcO9IMWBw6zDrMOs4LiB4Li14LmJxLddIiwiVGhpcyByZXBseSBsaW5rIGhhcyBleHBpcmVkIjoiW+G5ruG4qcOsw6zDrMWhIMWZ4bq94bq94bq9xqXGmsO9w73DvSDGmsOsw6zDrOC4geC4teC5icS3IOG4qcSDxIPEg8WhIOG6veG6veG6veqtlcalw6zDrMOsxZnhur3hur3hur3GjC5dIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJb4bmuw7bDtsO2IMSjxIPEg8SDw6zDrMOs4LiB4Li14LmJIMSDxIPEg8Onw6fhur3hur3hur3FocWhIOG5r8O2w7bDtiDEg8SDxIMgxbXDtsO2w7bFmcS3w6zDrMOs4LiB4Li14LmJxKMgxZnhur3hur3hur3Gpcaaw73DvcO9IMaaw6zDrMOs4LiB4Li14LmJxLcsIMalxprhur3hur3hur3Eg8SDxIPFoeG6veG6veG6vSA8VGV4dExpbms+w6fDtsO2w7bguIHguLXguYnhua/Eg8SDxIPDp+G5ryDDh8eax5rHmsWh4bmvw7bDtsO2bcyC4bq94bq94bq9xZkgxaDhur3hur3hur3FmeG5vcOsw6zDrMOn4bq94bq94bq9PC9UZXh0TGluaz4gw7bDtsO2xZkgw6fEg8SDxIPGmsaaIDxTdHJvbmc+e3Bob25lTnVtYmVyfTwvU3Ryb25nPi5dIiwiUmVwb3J0IHRoaXMgcmV2aWV3IjoiW8WY4bq94bq94bq9xqXDtsO2w7bFmeG5ryDhua/huKnDrMOsw6zFoSDFmeG6veG6veG6veG5vcOsw6zDrOG6veG6veG6vcW1XSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgZ29vZCB0aGluZ3MiOiJb4bmu4bip4bq94bq94bq9IMSjw7bDtsO2w7bDtsO2xowg4bmv4bipw6zDrMOs4LiB4Li14LmJxKPFoV0iLCJUaGUgY2hhbGxlbmdlcyI6Ilvhua7huKnhur3hur3hur0gw6fhuKnEg8SDxIPGmsaa4bq94bq94bq94LiB4Li14LmJxKPhur3hur3hur3FoV0iLCJIZWxwZnVsPyI6IlvhuKjhur3hur3hur3GmsalxpLHmseax5rGmj9dIiwicGVvcGxlIjoiW8al4bq94bq9w7bDtsalxprhur3hur1dIiwiU2F2ZSI6IlvFoMSDxIPhub3hur3hur1dIiwiUmVwbHkiOiJbxZjhur3hur3Gpcaaw73DvV0iLCIxIHBlcnNvbiI6IlsxIMal4bq94bq94bq9xZnFocO2w7bDtuC4geC4teC5iV0iLCJmb3VuZCB0aGlzIGhlbHBmdWwiOiJbxpLDtsO2w7bHmseax5rguIHguLXguYnGjCDhua/huKnDrMOsw6zFoSDhuKnhur3hur3hur3GmsalxpLHmseax5rGml0iLCJFeHBpcmVkIFJlcGx5IExpbmsiOiJbw4vqrZXGpcOsw6zDrMWZ4bq94bq94bq9xowgxZjhur3hur3hur3Gpcaaw73DvcO9IMWBw6zDrMOs4LiB4Li14LmJxLddIiwiVGhpcyByZXBseSBsaW5rIGhhcyBleHBpcmVkIjoiW+G5ruG4qcOsw6zDrMWhIMWZ4bq94bq94bq9xqXGmsO9w73DvSDGmsOsw6zDrOC4geC4teC5icS3IOG4qcSDxIPEg8WhIOG6veG6veG6veqtlcalw6zDrMOsxZnhur3hur3hur3GjC5dIiwiVG8gZ2FpbiBhY2Nlc3MgdG8gYSB3b3JraW5nIHJlcGx5IGxpbmsiOiJb4bmuw7bDtsO2IMSjxIPEg8SDw6zDrMOs4LiB4Li14LmJIMSDxIPEg8Onw6fhur3hur3hur3FocWhIOG5r8O2w7bDtiDEg8SDxIMgxbXDtsO2w7bFmcS3w6zDrMOs4LiB4Li14LmJxKMgxZnhur3hur3hur3Gpcaaw73DvcO9IMaaw6zDrMOs4LiB4Li14LmJxLcsIMalxprhur3hur3hur3Eg8SDxIPFoeG6veG6veG6vSA8VGV4dExpbms+w6fDtsO2w7bguIHguLXguYnhua/Eg8SDxIPDp+G5ryDDh8eax5rHmsWh4bmvw7bDtsO2bcyC4bq94bq94bq9xZkgxaDhur3hur3hur3FmeG5vcOsw6zDrMOn4bq94bq94bq9PC9UZXh0TGluaz4gw7bDtsO2xZkgw6fEg8SDxIPGmsaaIDxTdHJvbmc+e3Bob25lTnVtYmVyfTwvU3Ryb25nPi5dIiwiUmVwb3J0IHRoaXMgcmV2aWV3IjoiW8WY4bq94bq94bq9xqXDtsO2w7bFmeG5ryDhua/huKnDrMOsw6zFoSDFmeG6veG6veG6veG5vcOsw6zDrOG6veG6veG6vcW1XSJ9!"
        )
      )
      });
  
      export { translations as default };
    