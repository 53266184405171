import { useTranslations } from '@vocab/react';
import translations from './.vocab';
import {
  Stack,
  Inline,
  TextLink,
  Box,
  Text,
  TextLinkButton,
  IconChevron,
  Tiles,
  Hidden,
} from 'braid-design-system';
import { useState } from 'react';
import { useConfig } from '@seek/libs-shared';
import { useMelwaysLink } from '@seek/melways-react';

import * as styles from './CompaniesList.css';
import { ROOT_PATH } from '../../paths';

const CompanyLink = ({
  name,
  slug,
  id,
  trackCompanyProfileLinkClicked,
}: {
  name: string;
  slug: string;
  id: string;
  trackCompanyProfileLinkClicked: ({
    linkedCompanyId,
  }: {
    linkedCompanyId: string;
  }) => void;
}) => {
  const { language } = useConfig();
  const localize = useMelwaysLink();

  return (
    <Text tone="secondary" size="small" key={slug}>
      <TextLink
        href={`${localize({
          path: `/${ROOT_PATH}/${slug}`,
          language,
        })}`}
        weight="weak"
        onClick={() => trackCompanyProfileLinkClicked({ linkedCompanyId: id })}
      >
        {name}
      </TextLink>
    </Text>
  );
};

export const CompaniesList = ({
  title,
  allCompanies,
  trackCompanyProfileLinkClicked,
}: {
  title: string;
  allCompanies: { name: string; slug: string; id: string }[];
  trackCompanyProfileLinkClicked: ({
    linkedCompanyId,
  }: {
    linkedCompanyId: string;
  }) => void;
}) => {
  const [hideTopCompanies, setHideTopCompanies] = useState(true);
  const { t } = useTranslations(translations);

  return (
    <Hidden below="tablet">
      <Stack space="large">
        <Text weight="strong">{title}</Text>
        <Inline space={'small'}>
          {allCompanies.slice(0, 5).map((company) => (
            <CompanyLink
              name={company.name}
              slug={company.slug}
              key={company.slug}
              id={company.id}
              trackCompanyProfileLinkClicked={trackCompanyProfileLinkClicked}
            />
          ))}
          {allCompanies.length > 5 && (
            <Box
              onClick={() => {
                setHideTopCompanies(!hideTopCompanies);
              }}
              cursor="pointer"
            >
              <Inline space={'xxsmall'}>
                <TextLinkButton>
                  <Text tone="secondary" size="small">
                    {t('View all')}
                  </Text>
                </TextLinkButton>
                <Box
                  className={`${hideTopCompanies ? '' : styles.rotated}`}
                  style={{ transition: 'transform 0.2s ease-in-out' }}
                >
                  <Text size="small">
                    <IconChevron alignY="lowercase" />
                  </Text>
                </Box>
              </Inline>
            </Box>
          )}
        </Inline>
        {allCompanies.length > 5 && (
          <Box
            style={{
              display: hideTopCompanies ? 'none' : 'unset',
              transition: 'transform 0.2s ease-in-out opacity 0.2s ease-in-out',
            }}
          >
            <Tiles columns={4} space={'medium'}>
              {allCompanies.slice(5).map((company) => (
                <CompanyLink
                  name={company.name}
                  slug={company.slug}
                  key={company.slug}
                  trackCompanyProfileLinkClicked={
                    trackCompanyProfileLinkClicked
                  }
                  id={company.id}
                />
              ))}
            </Tiles>
          </Box>
        )}
      </Stack>
    </Hidden>
  );
};
