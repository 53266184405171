
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSB3aWxsIGxvb2sgaW50byB0aGlzIHJldmlldyI6IldlIHdpbGwgbG9vayBpbnRvIHRoaXMgcmV2aWV3IiwiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIjoiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIiwiQ2xvc2UiOiJDbG9zZSJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSB3aWxsIGxvb2sgaW50byB0aGlzIHJldmlldyI6IldlIHdpbGwgbG9vayBpbnRvIHRoaXMgcmV2aWV3IiwiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIjoiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIiwiQ2xvc2UiOiJDbG9zZSJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSB3aWxsIGxvb2sgaW50byB0aGlzIHJldmlldyI6IuC5gOC4o+C4suC4iOC4sOC4leC4o+C4p+C4iOC4quC4reC4muC4o+C4teC4p+C4tOC4p+C4meC4teC5iSIsIlRoYW5rcyBmb3IgeW91ciBmZWVkYmFjayI6IuC4guC4reC4guC4reC4muC4hOC4uOC4k+C4quC4s+C4q+C4o+C4seC4muC4hOC4p+C4suC4oeC4hOC4tOC4lOC5gOC4q+C5h+C4meC4guC4reC4h+C4hOC4uOC4kyIsIkNsb3NlIjoi4Lib4Li04LiUIn0=!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSB3aWxsIGxvb2sgaW50byB0aGlzIHJldmlldyI6IuC5gOC4o+C4suC4iOC4sOC4leC4o+C4p+C4iOC4quC4reC4muC4o+C4teC4p+C4tOC4p+C4meC4teC5iSIsIlRoYW5rcyBmb3IgeW91ciBmZWVkYmFjayI6IuC4guC4reC4guC4reC4muC4hOC4uOC4k+C4quC4s+C4q+C4o+C4seC4muC4hOC4p+C4suC4oeC4hOC4tOC4lOC5gOC4q+C5h+C4meC4guC4reC4h+C4hOC4uOC4kyIsIkNsb3NlIjoi4Lib4Li04LiUIn0=!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSB3aWxsIGxvb2sgaW50byB0aGlzIHJldmlldyI6IkthbWkgYWthbiBtZW1lcmlrc2EgdWxhc2FuIGluaSIsIlRoYW5rcyBmb3IgeW91ciBmZWVkYmFjayI6IlRlcmltYSBrYXNpaCBhdGFzIG1hc3VrYW4gQW5kYSIsIkNsb3NlIjoiVHV0dXAifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSB3aWxsIGxvb2sgaW50byB0aGlzIHJldmlldyI6IkthbWkgYWthbiBtZW1lcmlrc2EgdWxhc2FuIGluaSIsIlRoYW5rcyBmb3IgeW91ciBmZWVkYmFjayI6IlRlcmltYSBrYXNpaCBhdGFzIG1hc3VrYW4gQW5kYSIsIkNsb3NlIjoiVHV0dXAifQ==!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSB3aWxsIGxvb2sgaW50byB0aGlzIHJldmlldyI6IldlIHdpbGwgbG9vayBpbnRvIHRoaXMgcmV2aWV3IiwiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIjoiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIiwiQ2xvc2UiOiJDbG9zZSJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSB3aWxsIGxvb2sgaW50byB0aGlzIHJldmlldyI6IldlIHdpbGwgbG9vayBpbnRvIHRoaXMgcmV2aWV3IiwiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIjoiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIiwiQ2xvc2UiOiJDbG9zZSJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSB3aWxsIGxvb2sgaW50byB0aGlzIHJldmlldyI6IldlIHdpbGwgbG9vayBpbnRvIHRoaXMgcmV2aWV3IiwiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIjoiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIiwiQ2xvc2UiOiJDbG9zZSJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSB3aWxsIGxvb2sgaW50byB0aGlzIHJldmlldyI6IldlIHdpbGwgbG9vayBpbnRvIHRoaXMgcmV2aWV3IiwiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIjoiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIiwiQ2xvc2UiOiJDbG9zZSJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSB3aWxsIGxvb2sgaW50byB0aGlzIHJldmlldyI6IldlIHdpbGwgbG9vayBpbnRvIHRoaXMgcmV2aWV3IiwiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIjoiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIiwiQ2xvc2UiOiJDbG9zZSJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSB3aWxsIGxvb2sgaW50byB0aGlzIHJldmlldyI6IldlIHdpbGwgbG9vayBpbnRvIHRoaXMgcmV2aWV3IiwiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIjoiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIiwiQ2xvc2UiOiJDbG9zZSJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSB3aWxsIGxvb2sgaW50byB0aGlzIHJldmlldyI6IldlIHdpbGwgbG9vayBpbnRvIHRoaXMgcmV2aWV3IiwiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIjoiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIiwiQ2xvc2UiOiJDbG9zZSJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSB3aWxsIGxvb2sgaW50byB0aGlzIHJldmlldyI6IldlIHdpbGwgbG9vayBpbnRvIHRoaXMgcmV2aWV3IiwiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIjoiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIiwiQ2xvc2UiOiJDbG9zZSJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSB3aWxsIGxvb2sgaW50byB0aGlzIHJldmlldyI6IldlIHdpbGwgbG9vayBpbnRvIHRoaXMgcmV2aWV3IiwiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIjoiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIiwiQ2xvc2UiOiJDbG9zZSJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSB3aWxsIGxvb2sgaW50byB0aGlzIHJldmlldyI6IldlIHdpbGwgbG9vayBpbnRvIHRoaXMgcmV2aWV3IiwiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIjoiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIiwiQ2xvc2UiOiJDbG9zZSJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSB3aWxsIGxvb2sgaW50byB0aGlzIHJldmlldyI6IldlIHdpbGwgbG9vayBpbnRvIHRoaXMgcmV2aWV3IiwiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIjoiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIiwiQ2xvc2UiOiJDbG9zZSJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSB3aWxsIGxvb2sgaW50byB0aGlzIHJldmlldyI6IldlIHdpbGwgbG9vayBpbnRvIHRoaXMgcmV2aWV3IiwiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIjoiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIiwiQ2xvc2UiOiJDbG9zZSJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSB3aWxsIGxvb2sgaW50byB0aGlzIHJldmlldyI6IldlIHdpbGwgbG9vayBpbnRvIHRoaXMgcmV2aWV3IiwiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIjoiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIiwiQ2xvc2UiOiJDbG9zZSJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSB3aWxsIGxvb2sgaW50byB0aGlzIHJldmlldyI6IldlIHdpbGwgbG9vayBpbnRvIHRoaXMgcmV2aWV3IiwiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIjoiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIiwiQ2xvc2UiOiJDbG9zZSJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSB3aWxsIGxvb2sgaW50byB0aGlzIHJldmlldyI6IldlIHdpbGwgbG9vayBpbnRvIHRoaXMgcmV2aWV3IiwiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIjoiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIiwiQ2xvc2UiOiJDbG9zZSJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSB3aWxsIGxvb2sgaW50byB0aGlzIHJldmlldyI6IldlIHdpbGwgbG9vayBpbnRvIHRoaXMgcmV2aWV3IiwiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIjoiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIiwiQ2xvc2UiOiJDbG9zZSJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSB3aWxsIGxvb2sgaW50byB0aGlzIHJldmlldyI6IldlIHdpbGwgbG9vayBpbnRvIHRoaXMgcmV2aWV3IiwiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIjoiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIiwiQ2xvc2UiOiJDbG9zZSJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSB3aWxsIGxvb2sgaW50byB0aGlzIHJldmlldyI6IldlIHdpbGwgbG9vayBpbnRvIHRoaXMgcmV2aWV3IiwiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIjoiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIiwiQ2xvc2UiOiJDbG9zZSJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSB3aWxsIGxvb2sgaW50byB0aGlzIHJldmlldyI6IuC5gOC4o+C4suC4iOC4sOC4leC4o+C4p+C4iOC4quC4reC4muC4o+C4teC4p+C4tOC4p+C4meC4teC5iSIsIlRoYW5rcyBmb3IgeW91ciBmZWVkYmFjayI6IuC4guC4reC4guC4reC4muC4hOC4uOC4k+C4quC4s+C4q+C4o+C4seC4muC4hOC4p+C4suC4oeC4hOC4tOC4lOC5gOC4q+C5h+C4meC4guC4reC4h+C4hOC4uOC4kyIsIkNsb3NlIjoi4Lib4Li04LiUIn0=!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSB3aWxsIGxvb2sgaW50byB0aGlzIHJldmlldyI6IuC5gOC4o+C4suC4iOC4sOC4leC4o+C4p+C4iOC4quC4reC4muC4o+C4teC4p+C4tOC4p+C4meC4teC5iSIsIlRoYW5rcyBmb3IgeW91ciBmZWVkYmFjayI6IuC4guC4reC4guC4reC4muC4hOC4uOC4k+C4quC4s+C4q+C4o+C4seC4muC4hOC4p+C4suC4oeC4hOC4tOC4lOC5gOC4q+C5h+C4meC4guC4reC4h+C4hOC4uOC4kyIsIkNsb3NlIjoi4Lib4Li04LiUIn0=!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSB3aWxsIGxvb2sgaW50byB0aGlzIHJldmlldyI6IkthbWkgYWthbiBtZW1lcmlrc2EgdWxhc2FuIGluaSIsIlRoYW5rcyBmb3IgeW91ciBmZWVkYmFjayI6IlRlcmltYSBrYXNpaCBhdGFzIG1hc3VrYW4gQW5kYSIsIkNsb3NlIjoiVHV0dXAifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSB3aWxsIGxvb2sgaW50byB0aGlzIHJldmlldyI6IkthbWkgYWthbiBtZW1lcmlrc2EgdWxhc2FuIGluaSIsIlRoYW5rcyBmb3IgeW91ciBmZWVkYmFjayI6IlRlcmltYSBrYXNpaCBhdGFzIG1hc3VrYW4gQW5kYSIsIkNsb3NlIjoiVHV0dXAifQ==!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSB3aWxsIGxvb2sgaW50byB0aGlzIHJldmlldyI6IldlIHdpbGwgbG9vayBpbnRvIHRoaXMgcmV2aWV3IiwiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIjoiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIiwiQ2xvc2UiOiJDbG9zZSJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSB3aWxsIGxvb2sgaW50byB0aGlzIHJldmlldyI6IldlIHdpbGwgbG9vayBpbnRvIHRoaXMgcmV2aWV3IiwiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIjoiVGhhbmtzIGZvciB5b3VyIGZlZWRiYWNrIiwiQ2xvc2UiOiJDbG9zZSJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSB3aWxsIGxvb2sgaW50byB0aGlzIHJldmlldyI6IlvFtOG6veG6veG6vSDFtcOsw6zDrMaaxpogxprDtsO2w7bDtsO2w7bEtyDDrMOsw6zguIHguLXguYnhua/DtsO2w7Yg4bmv4bipw6zDrMOsxaEgxZnhur3hur3hur3hub3DrMOsw6zhur3hur3hur3FtV0iLCJUaGFua3MgZm9yIHlvdXIgZmVlZGJhY2siOiJb4bmu4bipxIPEg8SD4LiB4Li14LmJxLfFoSDGksO2w7bDtsWZIMO9w73DvcO2w7bDtseax5rHmsWZIMaS4bq94bq94bq94bq94bq94bq9xozDn8SDxIPEg8OnxLddIiwiQ2xvc2UiOiJbw4fGmsO2w7bFoeG6veG6vV0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSB3aWxsIGxvb2sgaW50byB0aGlzIHJldmlldyI6IlvFtOG6veG6veG6vSDFtcOsw6zDrMaaxpogxprDtsO2w7bDtsO2w7bEtyDDrMOsw6zguIHguLXguYnhua/DtsO2w7Yg4bmv4bipw6zDrMOsxaEgxZnhur3hur3hur3hub3DrMOsw6zhur3hur3hur3FtV0iLCJUaGFua3MgZm9yIHlvdXIgZmVlZGJhY2siOiJb4bmu4bipxIPEg8SD4LiB4Li14LmJxLfFoSDGksO2w7bDtsWZIMO9w73DvcO2w7bDtseax5rHmsWZIMaS4bq94bq94bq94bq94bq94bq9xozDn8SDxIPEg8OnxLddIiwiQ2xvc2UiOiJbw4fGmsO2w7bFoeG6veG6vV0ifQ==!"
        )
      )
      });
  
      export { translations as default };
    