
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5Ijoi4Lil4LiH4LiK4Li34LmI4Lit4LmA4LiC4LmJ4Liy4LmD4LiK4LmJ4Lia4Lix4LiN4LiK4Li14Lic4Li54LmJ4Lil4LiH4LmC4LiG4Lip4LiT4Liy4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiC4Lit4LiH4LiE4Li44LiT4LmA4Lie4Li34LmI4Lit4LiV4Lit4Lia4LiB4Lil4Lix4LiaIiwiVG8gcmVwbHkgdG8gYSByZXZpZXcsIHNpZ24gaW4gdG8geW91ciBhZHZlcnRpc2VyIGFjY291bnQgd2l0aCBhZG1pbiBhY2Nlc3MuIjoi4Liq4Liz4Lir4Lij4Lix4Lia4LiB4Liy4Lij4LiV4Lit4Lia4LiB4Lil4Lix4Lia4Lij4Li14Lin4Li04LinIOC4peC4h+C4iuC4t+C5iOC4reC5gOC4guC5ieC4suC5g+C4iuC5ieC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC4peC4h+C5guC4huC4qeC4k+C4suC4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4meC4guC4reC4h+C4hOC4uOC4k+C4lOC5ieC4p+C4ouC4geC4suC4o+C5gOC4guC5ieC4suC4luC4tuC4h+C4o+C4sOC4lOC4seC4muC4nOC4ueC5ieC4lOC4ueC5geC4peC4o+C4sOC4muC4miIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoi4Lir4Liy4LiB4Lii4Lix4LiH4LiE4LiH4LmD4LiK4LmJ4LiB4Liy4Lij4LmE4Lih4LmI4LmE4LiU4LmJIOC5guC4m+C4o+C4lOC4leC4tOC4lOC4leC5iOC4rTxUZXh0TGluaz7guJfguLXguKHguJrguKPguLTguIHguLLguKPguKXguLnguIHguITguYnguLI8L1RleHRMaW5rPuC4guC4reC4h+C5gOC4o+C4siJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5Ijoi4Lil4LiH4LiK4Li34LmI4Lit4LmA4LiC4LmJ4Liy4LmD4LiK4LmJ4Lia4Lix4LiN4LiK4Li14Lic4Li54LmJ4Lil4LiH4LmC4LiG4Lip4LiT4Liy4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiC4Lit4LiH4LiE4Li44LiT4LmA4Lie4Li34LmI4Lit4LiV4Lit4Lia4LiB4Lil4Lix4LiaIiwiVG8gcmVwbHkgdG8gYSByZXZpZXcsIHNpZ24gaW4gdG8geW91ciBhZHZlcnRpc2VyIGFjY291bnQgd2l0aCBhZG1pbiBhY2Nlc3MuIjoi4Liq4Liz4Lir4Lij4Lix4Lia4LiB4Liy4Lij4LiV4Lit4Lia4LiB4Lil4Lix4Lia4Lij4Li14Lin4Li04LinIOC4peC4h+C4iuC4t+C5iOC4reC5gOC4guC5ieC4suC5g+C4iuC5ieC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC4peC4h+C5guC4huC4qeC4k+C4suC4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4meC4guC4reC4h+C4hOC4uOC4k+C4lOC5ieC4p+C4ouC4geC4suC4o+C5gOC4guC5ieC4suC4luC4tuC4h+C4o+C4sOC4lOC4seC4muC4nOC4ueC5ieC4lOC4ueC5geC4peC4o+C4sOC4muC4miIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoi4Lir4Liy4LiB4Lii4Lix4LiH4LiE4LiH4LmD4LiK4LmJ4LiB4Liy4Lij4LmE4Lih4LmI4LmE4LiU4LmJIOC5guC4m+C4o+C4lOC4leC4tOC4lOC4leC5iOC4rTxUZXh0TGluaz7guJfguLXguKHguJrguKPguLTguIHguLLguKPguKXguLnguIHguITguYnguLI8L1RleHRMaW5rPuC4guC4reC4h+C5gOC4o+C4siJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiTWFzdWsga2UgYWt1biBwZW5naWtsYW4gQW5kYSB1bnR1ayBtZW1iYWxhcyIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlVudHVrIG1lbWJhbGFzIHVsYXNhbiwgbWFzdWsga2UgYWt1biBwZW5naWtsYW4gQW5kYSBkZW5nYW4gYWtzZXMgYWRtaW4uIiwiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIHNlcnZpY2UgdGVhbS4iOiJKaWthIG1hc2loIHRpZGFrIGJlcmhhc2lsLCBodWJ1bmdpIHRpbSA8VGV4dExpbms+VGltIGxheWFuYW4gcGVsYW5nZ2FuLjwvVGV4dExpbms+In0=!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiTWFzdWsga2UgYWt1biBwZW5naWtsYW4gQW5kYSB1bnR1ayBtZW1iYWxhcyIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlVudHVrIG1lbWJhbGFzIHVsYXNhbiwgbWFzdWsga2UgYWt1biBwZW5naWtsYW4gQW5kYSBkZW5nYW4gYWtzZXMgYWRtaW4uIiwiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIHNlcnZpY2UgdGVhbS4iOiJKaWthIG1hc2loIHRpZGFrIGJlcmhhc2lsLCBodWJ1bmdpIHRpbSA8VGV4dExpbms+VGltIGxheWFuYW4gcGVsYW5nZ2FuLjwvVGV4dExpbms+In0=!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ==!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5Ijoi4Lil4LiH4LiK4Li34LmI4Lit4LmA4LiC4LmJ4Liy4LmD4LiK4LmJ4Lia4Lix4LiN4LiK4Li14Lic4Li54LmJ4Lil4LiH4LmC4LiG4Lip4LiT4Liy4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiC4Lit4LiH4LiE4Li44LiT4LmA4Lie4Li34LmI4Lit4LiV4Lit4Lia4LiB4Lil4Lix4LiaIiwiVG8gcmVwbHkgdG8gYSByZXZpZXcsIHNpZ24gaW4gdG8geW91ciBhZHZlcnRpc2VyIGFjY291bnQgd2l0aCBhZG1pbiBhY2Nlc3MuIjoi4Liq4Liz4Lir4Lij4Lix4Lia4LiB4Liy4Lij4LiV4Lit4Lia4LiB4Lil4Lix4Lia4Lij4Li14Lin4Li04LinIOC4peC4h+C4iuC4t+C5iOC4reC5gOC4guC5ieC4suC5g+C4iuC5ieC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC4peC4h+C5guC4huC4qeC4k+C4suC4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4meC4guC4reC4h+C4hOC4uOC4k+C4lOC5ieC4p+C4ouC4geC4suC4o+C5gOC4guC5ieC4suC4luC4tuC4h+C4o+C4sOC4lOC4seC4muC4nOC4ueC5ieC4lOC4ueC5geC4peC4o+C4sOC4muC4miIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoi4Lir4Liy4LiB4Lii4Lix4LiH4LiE4LiH4LmD4LiK4LmJ4LiB4Liy4Lij4LmE4Lih4LmI4LmE4LiU4LmJIOC5guC4m+C4o+C4lOC4leC4tOC4lOC4leC5iOC4rTxUZXh0TGluaz7guJfguLXguKHguJrguKPguLTguIHguLLguKPguKXguLnguIHguITguYnguLI8L1RleHRMaW5rPuC4guC4reC4h+C5gOC4o+C4siJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5Ijoi4Lil4LiH4LiK4Li34LmI4Lit4LmA4LiC4LmJ4Liy4LmD4LiK4LmJ4Lia4Lix4LiN4LiK4Li14Lic4Li54LmJ4Lil4LiH4LmC4LiG4Lip4LiT4Liy4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiC4Lit4LiH4LiE4Li44LiT4LmA4Lie4Li34LmI4Lit4LiV4Lit4Lia4LiB4Lil4Lix4LiaIiwiVG8gcmVwbHkgdG8gYSByZXZpZXcsIHNpZ24gaW4gdG8geW91ciBhZHZlcnRpc2VyIGFjY291bnQgd2l0aCBhZG1pbiBhY2Nlc3MuIjoi4Liq4Liz4Lir4Lij4Lix4Lia4LiB4Liy4Lij4LiV4Lit4Lia4LiB4Lil4Lix4Lia4Lij4Li14Lin4Li04LinIOC4peC4h+C4iuC4t+C5iOC4reC5gOC4guC5ieC4suC5g+C4iuC5ieC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC4peC4h+C5guC4huC4qeC4k+C4suC4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4meC4guC4reC4h+C4hOC4uOC4k+C4lOC5ieC4p+C4ouC4geC4suC4o+C5gOC4guC5ieC4suC4luC4tuC4h+C4o+C4sOC4lOC4seC4muC4nOC4ueC5ieC4lOC4ueC5geC4peC4o+C4sOC4muC4miIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoi4Lir4Liy4LiB4Lii4Lix4LiH4LiE4LiH4LmD4LiK4LmJ4LiB4Liy4Lij4LmE4Lih4LmI4LmE4LiU4LmJIOC5guC4m+C4o+C4lOC4leC4tOC4lOC4leC5iOC4rTxUZXh0TGluaz7guJfguLXguKHguJrguKPguLTguIHguLLguKPguKXguLnguIHguITguYnguLI8L1RleHRMaW5rPuC4guC4reC4h+C5gOC4o+C4siJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiTWFzdWsga2UgYWt1biBwZW5naWtsYW4gQW5kYSB1bnR1ayBtZW1iYWxhcyIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlVudHVrIG1lbWJhbGFzIHVsYXNhbiwgbWFzdWsga2UgYWt1biBwZW5naWtsYW4gQW5kYSBkZW5nYW4gYWtzZXMgYWRtaW4uIiwiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIHNlcnZpY2UgdGVhbS4iOiJKaWthIG1hc2loIHRpZGFrIGJlcmhhc2lsLCBodWJ1bmdpIHRpbSA8VGV4dExpbms+VGltIGxheWFuYW4gcGVsYW5nZ2FuLjwvVGV4dExpbms+In0=!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiTWFzdWsga2UgYWt1biBwZW5naWtsYW4gQW5kYSB1bnR1ayBtZW1iYWxhcyIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlVudHVrIG1lbWJhbGFzIHVsYXNhbiwgbWFzdWsga2UgYWt1biBwZW5naWtsYW4gQW5kYSBkZW5nYW4gYWtzZXMgYWRtaW4uIiwiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIHNlcnZpY2UgdGVhbS4iOiJKaWthIG1hc2loIHRpZGFrIGJlcmhhc2lsLCBodWJ1bmdpIHRpbSA8VGV4dExpbms+VGltIGxheWFuYW4gcGVsYW5nZ2FuLjwvVGV4dExpbms+In0=!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ==!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiU2lnbiBpbiB0byB5b3VyIGFkdmVydGlzZXIgYWNjb3VudCB0byByZXBseSIsIlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiI6IlRvIHJlcGx5IHRvIGEgcmV2aWV3LCBzaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHdpdGggYWRtaW4gYWNjZXNzLiIsIklmIGl0IHN0aWxsIGRvZXNuJ3Qgd29yaywgcmVhY2ggb3V0IHRvIG91ciBDdXN0b21lciBzZXJ2aWNlIHRlYW0uIjoiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBzZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz4ifQ==!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiW8Wgw6zDrMOsxKPguIHguLXguYkgw6zDrMOs4LiB4Li14LmJIOG5r8O2w7bDtiDDvcO9w73DtsO2w7bHmseax5rFmSDEg8SDxIPGjOG5veG6veG6veG6vcWZ4bmvw6zDrMOsxaHhur3hur3hur3FmSDEg8SDxIPDp8Onw7bDtsO2x5rHmsea4LiB4Li14LmJ4bmvIOG5r8O2w7bDtiDFmeG6veG6veG6vcalxprDvcO9w71dIiwiVG8gcmVwbHkgdG8gYSByZXZpZXcsIHNpZ24gaW4gdG8geW91ciBhZHZlcnRpc2VyIGFjY291bnQgd2l0aCBhZG1pbiBhY2Nlc3MuIjoiW+G5rsO2w7bDtiDFmeG6veG6veG6vcalxprDvcO9w70g4bmvw7bDtsO2IMSDxIPEgyDFmeG6veG6veG6veG5vcOsw6zDrOG6veG6veG6vcW1LCDFocOsw6zDrMSj4LiB4Li14LmJIMOsw6zDrOC4geC4teC5iSDhua/DtsO2w7Ygw73DvcO9w7bDtsO2x5rHmseaxZkgxIPEg8SDxozhub3hur3hur3hur3FmeG5r8Osw6zDrMWh4bq94bq94bq9xZkgxIPEg8SDw6fDp8O2w7bDtseax5rHmuC4geC4teC5ieG5ryDFtcOsw6zDrOG5r+G4qSDEg8SDxIPGjG3MgsOsw6zDrOC4geC4teC5iSDEg8SDxIPDp8On4bq94bq94bq9xaHFoS5dIiwiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIHNlcnZpY2UgdGVhbS4iOiJbw4/GkiDDrMOsw6zhua8gxaHhua/DrMOsw6zGmsaaIMaMw7bDtsO24bq94bq94bq9xaHguIHguLXguYkn4bmvIMW1w7bDtsO2xZnEtywgxZnhur3hur3hur3Eg8SDxIPDp+G4qSDDtsO2w7bHmseax5rhua8g4bmvw7bDtsO2IMO2w7bDtseax5rHmsWZIDxUZXh0TGluaz7Dh8eax5rHmsWh4bmvw7bDtsO2bcyC4bq94bq94bq9xZkgxaHhur3hur3hur3FmeG5vcOsw6zDrMOn4bq94bq94bq9IOG5r+G6veG6veG6vcSDxIPEg23Mgi48L1RleHRMaW5rPl0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHlvdXIgYWR2ZXJ0aXNlciBhY2NvdW50IHRvIHJlcGx5IjoiW8Wgw6zDrMOsxKPguIHguLXguYkgw6zDrMOs4LiB4Li14LmJIOG5r8O2w7bDtiDDvcO9w73DtsO2w7bHmseax5rFmSDEg8SDxIPGjOG5veG6veG6veG6vcWZ4bmvw6zDrMOsxaHhur3hur3hur3FmSDEg8SDxIPDp8Onw7bDtsO2x5rHmsea4LiB4Li14LmJ4bmvIOG5r8O2w7bDtiDFmeG6veG6veG6vcalxprDvcO9w71dIiwiVG8gcmVwbHkgdG8gYSByZXZpZXcsIHNpZ24gaW4gdG8geW91ciBhZHZlcnRpc2VyIGFjY291bnQgd2l0aCBhZG1pbiBhY2Nlc3MuIjoiW+G5rsO2w7bDtiDFmeG6veG6veG6vcalxprDvcO9w70g4bmvw7bDtsO2IMSDxIPEgyDFmeG6veG6veG6veG5vcOsw6zDrOG6veG6veG6vcW1LCDFocOsw6zDrMSj4LiB4Li14LmJIMOsw6zDrOC4geC4teC5iSDhua/DtsO2w7Ygw73DvcO9w7bDtsO2x5rHmseaxZkgxIPEg8SDxozhub3hur3hur3hur3FmeG5r8Osw6zDrMWh4bq94bq94bq9xZkgxIPEg8SDw6fDp8O2w7bDtseax5rHmuC4geC4teC5ieG5ryDFtcOsw6zDrOG5r+G4qSDEg8SDxIPGjG3MgsOsw6zDrOC4geC4teC5iSDEg8SDxIPDp8On4bq94bq94bq9xaHFoS5dIiwiSWYgaXQgc3RpbGwgZG9lc24ndCB3b3JrLCByZWFjaCBvdXQgdG8gb3VyIEN1c3RvbWVyIHNlcnZpY2UgdGVhbS4iOiJbw4/GkiDDrMOsw6zhua8gxaHhua/DrMOsw6zGmsaaIMaMw7bDtsO24bq94bq94bq9xaHguIHguLXguYkn4bmvIMW1w7bDtsO2xZnEtywgxZnhur3hur3hur3Eg8SDxIPDp+G4qSDDtsO2w7bHmseax5rhua8g4bmvw7bDtsO2IMO2w7bDtseax5rHmsWZIDxUZXh0TGluaz7Dh8eax5rHmsWh4bmvw7bDtsO2bcyC4bq94bq94bq9xZkgxaHhur3hur3hur3FmeG5vcOsw6zDrMOn4bq94bq94bq9IOG5r+G6veG6veG6vcSDxIPEg23Mgi48L1RleHRMaW5rPl0ifQ==!"
        )
      )
      });
  
      export { translations as default };
    