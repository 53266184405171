
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBYm91dCI6IkFib3V0IiwiTGlmZSBhbmQgQ3VsdHVyZSI6IkxpZmUgYW5kIEN1bHR1cmUiLCJKb2JzIjoiSm9icyIsIlJldmlld3MiOiJSZXZpZXdzIiwiQmFjayB0byBqb2Igc2VhcmNoIjoiQmFjayB0byBqb2Igc2VhcmNoIn0=!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBYm91dCI6IkFib3V0IiwiTGlmZSBhbmQgQ3VsdHVyZSI6IkxpZmUgYW5kIEN1bHR1cmUiLCJKb2JzIjoiSm9icyIsIlJldmlld3MiOiJSZXZpZXdzIiwiQmFjayB0byBqb2Igc2VhcmNoIjoiQmFjayB0byBqb2Igc2VhcmNoIn0=!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBYm91dCI6IuC5gOC4geC4teC5iOC4ouC4p+C4geC4seC4miIsIkxpZmUgYW5kIEN1bHR1cmUiOiLguIrguLXguKfguLTguJXguYHguKXguLDguKfguLHguJLguJnguJjguKPguKPguKEiLCJKb2JzIjoi4LiH4Liy4LiZIiwiUmV2aWV3cyI6IuC4o+C4teC4p+C4tOC4pyIsIkJhY2sgdG8gam9iIHNlYXJjaCI6IuC4geC4peC4seC4muC5hOC4m+C4l+C4teC5iOC4geC4suC4o+C4hOC5ieC4meC4q+C4suC4h+C4suC4mSJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBYm91dCI6IuC5gOC4geC4teC5iOC4ouC4p+C4geC4seC4miIsIkxpZmUgYW5kIEN1bHR1cmUiOiLguIrguLXguKfguLTguJXguYHguKXguLDguKfguLHguJLguJnguJjguKPguKPguKEiLCJKb2JzIjoi4LiH4Liy4LiZIiwiUmV2aWV3cyI6IuC4o+C4teC4p+C4tOC4pyIsIkJhY2sgdG8gam9iIHNlYXJjaCI6IuC4geC4peC4seC4muC5hOC4m+C4l+C4teC5iOC4geC4suC4o+C4hOC5ieC4meC4q+C4suC4h+C4suC4mSJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBYm91dCI6IlRlbnRhbmciLCJMaWZlIGFuZCBDdWx0dXJlIjoiS2VoaWR1cGFuIGRhbiBCdWRheWEiLCJKb2JzIjoiUGVrZXJqYWFuIiwiUmV2aWV3cyI6IlVsYXNhbiIsIkJhY2sgdG8gam9iIHNlYXJjaCI6IktlbWJhbGkga2UgcGVuY2FyaWFuIGxvd29uZ2FuIGtlcmphIn0=!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBYm91dCI6IlRlbnRhbmciLCJMaWZlIGFuZCBDdWx0dXJlIjoiS2VoaWR1cGFuIGRhbiBCdWRheWEiLCJKb2JzIjoiUGVrZXJqYWFuIiwiUmV2aWV3cyI6IlVsYXNhbiIsIkJhY2sgdG8gam9iIHNlYXJjaCI6IktlbWJhbGkga2UgcGVuY2FyaWFuIGxvd29uZ2FuIGtlcmphIn0=!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBYm91dCI6IkFib3V0IiwiTGlmZSBhbmQgQ3VsdHVyZSI6IkxpZmUgYW5kIEN1bHR1cmUiLCJKb2JzIjoiSm9icyIsIlJldmlld3MiOiJSZXZpZXdzIiwiQmFjayB0byBqb2Igc2VhcmNoIjoiQmFjayB0byBqb2Igc2VhcmNoIn0=!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBYm91dCI6IkFib3V0IiwiTGlmZSBhbmQgQ3VsdHVyZSI6IkxpZmUgYW5kIEN1bHR1cmUiLCJKb2JzIjoiSm9icyIsIlJldmlld3MiOiJSZXZpZXdzIiwiQmFjayB0byBqb2Igc2VhcmNoIjoiQmFjayB0byBqb2Igc2VhcmNoIn0=!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBYm91dCI6IkFib3V0IiwiTGlmZSBhbmQgQ3VsdHVyZSI6IkxpZmUgYW5kIEN1bHR1cmUiLCJKb2JzIjoiSm9icyIsIlJldmlld3MiOiJSZXZpZXdzIiwiQmFjayB0byBqb2Igc2VhcmNoIjoiQmFjayB0byBqb2Igc2VhcmNoIn0=!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBYm91dCI6IkFib3V0IiwiTGlmZSBhbmQgQ3VsdHVyZSI6IkxpZmUgYW5kIEN1bHR1cmUiLCJKb2JzIjoiSm9icyIsIlJldmlld3MiOiJSZXZpZXdzIiwiQmFjayB0byBqb2Igc2VhcmNoIjoiQmFjayB0byBqb2Igc2VhcmNoIn0=!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBYm91dCI6IkFib3V0IiwiTGlmZSBhbmQgQ3VsdHVyZSI6IkxpZmUgYW5kIEN1bHR1cmUiLCJKb2JzIjoiSm9icyIsIlJldmlld3MiOiJSZXZpZXdzIiwiQmFjayB0byBqb2Igc2VhcmNoIjoiQmFjayB0byBqb2Igc2VhcmNoIn0=!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBYm91dCI6IkFib3V0IiwiTGlmZSBhbmQgQ3VsdHVyZSI6IkxpZmUgYW5kIEN1bHR1cmUiLCJKb2JzIjoiSm9icyIsIlJldmlld3MiOiJSZXZpZXdzIiwiQmFjayB0byBqb2Igc2VhcmNoIjoiQmFjayB0byBqb2Igc2VhcmNoIn0=!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBYm91dCI6IkFib3V0IiwiTGlmZSBhbmQgQ3VsdHVyZSI6IkxpZmUgYW5kIEN1bHR1cmUiLCJKb2JzIjoiSm9icyIsIlJldmlld3MiOiJSZXZpZXdzIiwiQmFjayB0byBqb2Igc2VhcmNoIjoiQmFjayB0byBqb2Igc2VhcmNoIn0=!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBYm91dCI6IkFib3V0IiwiTGlmZSBhbmQgQ3VsdHVyZSI6IkxpZmUgYW5kIEN1bHR1cmUiLCJKb2JzIjoiSm9icyIsIlJldmlld3MiOiJSZXZpZXdzIiwiQmFjayB0byBqb2Igc2VhcmNoIjoiQmFjayB0byBqb2Igc2VhcmNoIn0=!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBYm91dCI6IkFib3V0IiwiTGlmZSBhbmQgQ3VsdHVyZSI6IkxpZmUgYW5kIEN1bHR1cmUiLCJKb2JzIjoiSm9icyIsIlJldmlld3MiOiJSZXZpZXdzIiwiQmFjayB0byBqb2Igc2VhcmNoIjoiQmFjayB0byBqb2Igc2VhcmNoIn0=!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBYm91dCI6IkFib3V0IiwiTGlmZSBhbmQgQ3VsdHVyZSI6IkxpZmUgYW5kIEN1bHR1cmUiLCJKb2JzIjoiSm9icyIsIlJldmlld3MiOiJSZXZpZXdzIiwiQmFjayB0byBqb2Igc2VhcmNoIjoiQmFjayB0byBqb2Igc2VhcmNoIn0=!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBYm91dCI6IkFib3V0IiwiTGlmZSBhbmQgQ3VsdHVyZSI6IkxpZmUgYW5kIEN1bHR1cmUiLCJKb2JzIjoiSm9icyIsIlJldmlld3MiOiJSZXZpZXdzIiwiQmFjayB0byBqb2Igc2VhcmNoIjoiQmFjayB0byBqb2Igc2VhcmNoIn0=!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBYm91dCI6IkFib3V0IiwiTGlmZSBhbmQgQ3VsdHVyZSI6IkxpZmUgYW5kIEN1bHR1cmUiLCJKb2JzIjoiSm9icyIsIlJldmlld3MiOiJSZXZpZXdzIiwiQmFjayB0byBqb2Igc2VhcmNoIjoiQmFjayB0byBqb2Igc2VhcmNoIn0=!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBYm91dCI6IkFib3V0IiwiTGlmZSBhbmQgQ3VsdHVyZSI6IkxpZmUgYW5kIEN1bHR1cmUiLCJKb2JzIjoiSm9icyIsIlJldmlld3MiOiJSZXZpZXdzIiwiQmFjayB0byBqb2Igc2VhcmNoIjoiQmFjayB0byBqb2Igc2VhcmNoIn0=!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBYm91dCI6IkFib3V0IiwiTGlmZSBhbmQgQ3VsdHVyZSI6IkxpZmUgYW5kIEN1bHR1cmUiLCJKb2JzIjoiSm9icyIsIlJldmlld3MiOiJSZXZpZXdzIiwiQmFjayB0byBqb2Igc2VhcmNoIjoiQmFjayB0byBqb2Igc2VhcmNoIn0=!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBYm91dCI6IkFib3V0IiwiTGlmZSBhbmQgQ3VsdHVyZSI6IkxpZmUgYW5kIEN1bHR1cmUiLCJKb2JzIjoiSm9icyIsIlJldmlld3MiOiJSZXZpZXdzIiwiQmFjayB0byBqb2Igc2VhcmNoIjoiQmFjayB0byBqb2Igc2VhcmNoIn0=!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBYm91dCI6IkFib3V0IiwiTGlmZSBhbmQgQ3VsdHVyZSI6IkxpZmUgYW5kIEN1bHR1cmUiLCJKb2JzIjoiSm9icyIsIlJldmlld3MiOiJSZXZpZXdzIiwiQmFjayB0byBqb2Igc2VhcmNoIjoiQmFjayB0byBqb2Igc2VhcmNoIn0=!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBYm91dCI6IkFib3V0IiwiTGlmZSBhbmQgQ3VsdHVyZSI6IkxpZmUgYW5kIEN1bHR1cmUiLCJKb2JzIjoiSm9icyIsIlJldmlld3MiOiJSZXZpZXdzIiwiQmFjayB0byBqb2Igc2VhcmNoIjoiQmFjayB0byBqb2Igc2VhcmNoIn0=!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBYm91dCI6IkFib3V0IiwiTGlmZSBhbmQgQ3VsdHVyZSI6IkxpZmUgYW5kIEN1bHR1cmUiLCJKb2JzIjoiSm9icyIsIlJldmlld3MiOiJSZXZpZXdzIiwiQmFjayB0byBqb2Igc2VhcmNoIjoiQmFjayB0byBqb2Igc2VhcmNoIn0=!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBYm91dCI6IuC5gOC4geC4teC5iOC4ouC4p+C4geC4seC4miIsIkxpZmUgYW5kIEN1bHR1cmUiOiLguIrguLXguKfguLTguJXguYHguKXguLDguKfguLHguJLguJnguJjguKPguKPguKEiLCJKb2JzIjoi4LiH4Liy4LiZIiwiUmV2aWV3cyI6IuC4o+C4teC4p+C4tOC4pyIsIkJhY2sgdG8gam9iIHNlYXJjaCI6IuC4geC4peC4seC4muC5hOC4m+C4l+C4teC5iOC4geC4suC4o+C4hOC5ieC4meC4q+C4suC4h+C4suC4mSJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBYm91dCI6IuC5gOC4geC4teC5iOC4ouC4p+C4geC4seC4miIsIkxpZmUgYW5kIEN1bHR1cmUiOiLguIrguLXguKfguLTguJXguYHguKXguLDguKfguLHguJLguJnguJjguKPguKPguKEiLCJKb2JzIjoi4LiH4Liy4LiZIiwiUmV2aWV3cyI6IuC4o+C4teC4p+C4tOC4pyIsIkJhY2sgdG8gam9iIHNlYXJjaCI6IuC4geC4peC4seC4muC5hOC4m+C4l+C4teC5iOC4geC4suC4o+C4hOC5ieC4meC4q+C4suC4h+C4suC4mSJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBYm91dCI6IlRlbnRhbmciLCJMaWZlIGFuZCBDdWx0dXJlIjoiS2VoaWR1cGFuIGRhbiBCdWRheWEiLCJKb2JzIjoiUGVrZXJqYWFuIiwiUmV2aWV3cyI6IlVsYXNhbiIsIkJhY2sgdG8gam9iIHNlYXJjaCI6IktlbWJhbGkga2UgcGVuY2FyaWFuIGxvd29uZ2FuIGtlcmphIn0=!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBYm91dCI6IlRlbnRhbmciLCJMaWZlIGFuZCBDdWx0dXJlIjoiS2VoaWR1cGFuIGRhbiBCdWRheWEiLCJKb2JzIjoiUGVrZXJqYWFuIiwiUmV2aWV3cyI6IlVsYXNhbiIsIkJhY2sgdG8gam9iIHNlYXJjaCI6IktlbWJhbGkga2UgcGVuY2FyaWFuIGxvd29uZ2FuIGtlcmphIn0=!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBYm91dCI6IkFib3V0IiwiTGlmZSBhbmQgQ3VsdHVyZSI6IkxpZmUgYW5kIEN1bHR1cmUiLCJKb2JzIjoiSm9icyIsIlJldmlld3MiOiJSZXZpZXdzIiwiQmFjayB0byBqb2Igc2VhcmNoIjoiQmFjayB0byBqb2Igc2VhcmNoIn0=!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBYm91dCI6IkFib3V0IiwiTGlmZSBhbmQgQ3VsdHVyZSI6IkxpZmUgYW5kIEN1bHR1cmUiLCJKb2JzIjoiSm9icyIsIlJldmlld3MiOiJSZXZpZXdzIiwiQmFjayB0byBqb2Igc2VhcmNoIjoiQmFjayB0byBqb2Igc2VhcmNoIn0=!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBYm91dCI6IlvhuqzDn8O2w7bHmsea4bmvXSIsIkxpZmUgYW5kIEN1bHR1cmUiOiJbxYHDrMOsw6zGkuG6veG6veG6vSDEg8SDxIPguIHguLXguYnGjCDDh8eax5rHmsaa4bmvx5rHmseaxZnhur3hur3hur1dIiwiSm9icyI6IlvEtMO2w7bDtsOfxaFdIiwiUmV2aWV3cyI6IlvFmOG6veG6veG5vcOsw6zhur3hur3FtcWhXSIsIkJhY2sgdG8gam9iIHNlYXJjaCI6Ilvhup7Eg8SDxIPDp8S3IOG5r8O2w7bDtiDEtcO2w7bDtsOfIMWh4bq94bq94bq9xIPEg8SDxZnDp+G4qV0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBYm91dCI6IlvhuqzDn8O2w7bHmsea4bmvXSIsIkxpZmUgYW5kIEN1bHR1cmUiOiJbxYHDrMOsw6zGkuG6veG6veG6vSDEg8SDxIPguIHguLXguYnGjCDDh8eax5rHmsaa4bmvx5rHmseaxZnhur3hur3hur1dIiwiSm9icyI6IlvEtMO2w7bDtsOfxaFdIiwiUmV2aWV3cyI6IlvFmOG6veG6veG5vcOsw6zhur3hur3FtcWhXSIsIkJhY2sgdG8gam9iIHNlYXJjaCI6Ilvhup7Eg8SDxIPDp8S3IOG5r8O2w7bDtiDEtcO2w7bDtsOfIMWh4bq94bq94bq9xIPEg8SDxZnDp+G4qV0ifQ==!"
        )
      )
      });
  
      export { translations as default };
    