import { useTranslations } from '@vocab/react';
import { useMelwaysLink } from '@seek/melways-react';

import translations from './.vocab';
import { useConfig } from '@seek/libs-shared';
import { Box, Stack, Heading, Text, TextLink } from 'braid-design-system';
import { SpeechBubbleIcon } from '../../../companyReviews/reviewsEmptyState/SpeechBubbleIcon';

export const ReviewReplyNotice = () => {
  const { t } = useTranslations(translations);
  const { country, product, language } = useConfig();
  const localize = useMelwaysLink();

  const contactUsUrl = localize({
    country,
    path: product === 'candidate' ? '/contact-us' : '/contactus',
    language,
    product,
  });

  return (
    <Box paddingY="xxlarge" paddingX={['medium', 'none']}>
      <Stack space="xlarge" align="center">
        <SpeechBubbleIcon />
        <Stack space="medium">
          <Heading level="3" align="center">
            {t('Sign in to your advertiser account to reply')}
          </Heading>
          <Stack space="xlarge">
            <Text tone="secondary" align="center">
              {t(
                'To reply to a review, sign in to your advertiser account with admin access.',
              )}
            </Text>
            <Text tone="secondary" align="center">
              {t(
                "If it still doesn't work, reach out to our Customer service team.",
                {
                  TextLink: (children) => (
                    <TextLink href={contactUsUrl}>{children}</TextLink>
                  ),
                },
              )}
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
