import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import type {
  CompanyProfile as CompanyProfileFromGraph,
  CompanyNameSlug as CompanyNameSlugFromGraph,
  PostCompanyProfileReviewUpvotesPayload as ReviewUpvotesPayloadFromGraph,
  PostCompanyProfileReviewFlagPayload as ReviewFlagPayloadFromGraph,
  Reviews as ReviewsFromGraph,
  FeatureFlag,
  FeatureFlagInput,
  GroupedCompanies,
} from '@seek/ca-graphql-schema/types';

import type {
  CompanyProfile,
  Jobs,
  Logger,
  Reviews,
  ReviewUpvotes as ReviewUpvotesFromGraph,
  SortOrder,
} from '@seek/libs-shared';
import { createLegacyCompanyProfileService } from '../legacyCompanyProfileService/legacyCompanyProfileService';
import { createSearchService } from '../searchService/searchService';
import {
  mapCompanyProfileModel,
  mapReviewsModel,
  mapJobsModel,
  mapSearchCompanyModel,
  mapCompanyProfileModelFromDraft,
  mapCompanyGroupsToFlatCompanies,
} from './mapper';
import type { AppConfig } from '../../config';
import type { SearchCompaniesResult } from './types';
import {
  GET_COMPANY_NAME_SLUG_QUERY,
  GET_COMPANY_PROFILE_QUERY,
  GET_COMPANY_REVIEW_UPVOTES_QUERY,
  GET_FEATURE_FLAGS_QUERY,
  GET_REVIEWS_QUERY,
  GET_GROUPED_COMPANIES_QUERY,
  POST_COMPANY_REVIEW_FLAG_MUTATE,
  POST_COMPANY_REVIEW_UPVOTE_MUTATE,
} from './graphqlQueries';
import { convertToRecord } from '../../utils/convertToRecord';
import type { CompaniesListModel } from '../../../companyProfiles/models/companiesListModel';

export interface CompanyProfileService {
  searchCompanies: ({
    searchTerm,
  }: {
    searchTerm: string;
  }) => Promise<SearchCompaniesResult>;
  getCompanyProfile: (
    companyId: string,
    isDraft?: boolean,
  ) => Promise<CompanyProfile>;
  getCompanyProfileForeignId: (companyId: string) => Promise<string>;
  getJobs: (companyId: string, page?: number) => Promise<Jobs>;
  getReviews: ({
    companyId,
    page,
    sortOrder,
    perPage,
  }: {
    companyId: string;
    page?: number;
    sortOrder?: SortOrder;
    perPage?: number;
  }) => Promise<Reviews>;
  getCompanyNameSlug: (
    companySlug: string,
    isLegacyUrl: boolean,
  ) => Promise<CompanyNameSlugFromGraph>;
  getUpvoteIds: ({
    companyId,
  }: {
    companyId: string;
  }) => Promise<ReviewUpvotesFromGraph>;
  postUpvoteReview: ({
    companyId,
    reviewId,
  }: {
    companyId: string;
    reviewId: string;
  }) => Promise<ReviewUpvotesPayloadFromGraph | undefined>;
  postFlagReview: ({
    companyId,
    reviewId,
    reason,
    details,
  }: {
    companyId: string;
    reviewId: string;
    reason: string;
    details: string;
  }) => Promise<ReviewFlagPayloadFromGraph | undefined>;
  getFeatureFlags: ({
    companyId,
    advertiserId,
  }: {
    companyId?: string;
    advertiserId?: string;
  }) => Promise<Record<string, unknown>>;
  getGroupedCompanies: ({
    groupBy,
    perPage,
    sortBy,
    excludedCompanyIds,
    randomSeed,
  }: {
    groupBy: { group: 'INDUSTRY'; value: string[] };
    perPage?: number;
    sortBy?: 'REVIEWS_COUNT';
    excludedCompanyIds?: string[];
    randomSeed?: string;
  }) => Promise<CompaniesListModel>;
}

export const createCompanyProfileService = ({
  config,
  client,
  logger,
}: {
  client: ApolloClient<NormalizedCacheObject>;
  config: AppConfig;
  logger: Logger;
}): CompanyProfileService => {
  const legacyCompanyProfileService = createLegacyCompanyProfileService({
    companyProfilesApiBaseUrl: config.companyProfilesApiBaseUrl,
    config,
    logger,
  });

  const searchService = createSearchService({
    config,
    logger,
  });

  const searchCompanies = async ({
    searchTerm,
  }: {
    searchTerm: string;
  }): Promise<SearchCompaniesResult> => {
    try {
      const res = await legacyCompanyProfileService.searchCompanies({
        searchTerm,
      });

      if (!res.data) {
        return { companies: [], totalCompanies: 0 };
      }

      return mapSearchCompanyModel(res);
    } catch (error) {
      throw new Error('Error from searchCompanies', { cause: error });
    }
  };

  const getCompanyProfileForeignId = async (
    companyId: string,
  ): Promise<string> =>
    await legacyCompanyProfileService.getCompanyProfileForeignId(companyId);

  const getJobs = async (companyId: string, page = 1): Promise<Jobs> => {
    const organisationId = await getCompanyProfileForeignId(companyId);

    if (!organisationId) return { jobs: [], totalJobs: 0 };

    const jobs = await searchService.getJobsList({
      organisationId,
      page,
      country: config.country,
    });

    return mapJobsModel(jobs);
  };

  const getCompanyProfile = async (
    companyId: string,
    isDraft = false,
  ): Promise<CompanyProfile> => {
    if (isDraft) {
      const draft =
        await legacyCompanyProfileService.getLegacyCompanyProfilePreview(
          companyId,
        );

      if (!draft) {
        throw new Error(`Company not found: ${companyId}`);
      }

      const legacyCompanyReviewsSummary =
        await legacyCompanyProfileService.getCompanyReviewsSummary(companyId);

      return mapCompanyProfileModelFromDraft(
        draft,
        legacyCompanyReviewsSummary,
      );
    }

    // only foreignCompanyId and gallery are used from legacyCompanyProfile
    const legacyCompanyProfile =
      await legacyCompanyProfileService.getLegacyCompanyProfile(companyId);

    if (!legacyCompanyProfile)
      throw new Error(`Company not found: ${companyId}`);

    const legacyCompanyReviewsSummary =
      await legacyCompanyProfileService.getCompanyReviewsSummary(companyId);

    const response = await client.query<{
      companyProfile: CompanyProfileFromGraph;
    }>({
      query: GET_COMPANY_PROFILE_QUERY,
      variables: {
        companyProfileId: companyId,
        zone: config.zone,
        locale: config.locale,
      },
    });

    return mapCompanyProfileModel({
      companyProfileFromGraph: response.data?.companyProfile,
      legacyCompanyReviewsSummary,
      legacyCompanyProfile,
    });
  };

  const getReviews = async ({
    companyId,
    page = 1,
    sortOrder = 'most recent',
    perPage = 10,
  }: {
    companyId: string;
    page?: number;
    sortOrder?: SortOrder;
    perPage?: number;
  }): Promise<Reviews> => {
    const response = await client.query<{
      companyProfile: CompanyProfileFromGraph;
      companyReviews: ReviewsFromGraph;
    }>({
      query: GET_REVIEWS_QUERY,
      variables: {
        companyProfileId: companyId,
        zone: config.zone,
        locale: config.locale,
        page,
        perPage,
        sort: sortOrder === 'most helpful' ? '-upvote_count' : '',
      },
    });

    return mapReviewsModel(
      response.data.companyProfile,
      response.data.companyReviews,
    );
  };

  const getCompanyNameSlug = async (
    companySlug: string,
    isLegacyUrl: boolean,
  ): Promise<CompanyNameSlugFromGraph> => {
    const response = await client.query<{
      companyNameSlug: CompanyNameSlugFromGraph;
    }>({
      query: GET_COMPANY_NAME_SLUG_QUERY,
      variables: {
        companySlug,
        zone: config.zone,
        isLegacyUrl,
      },
    });

    return response?.data?.companyNameSlug;
  };

  const getUpvoteIds = async ({
    companyId,
  }: {
    companyId: string;
  }): Promise<ReviewUpvotesFromGraph> => {
    const response = await client.query<{
      companyProfileReviewUpvotes: ReviewUpvotesFromGraph;
    }>({
      query: GET_COMPANY_REVIEW_UPVOTES_QUERY,
      variables: {
        companyProfileReviewUpvotesId: companyId,
        zone: config.zone,
      },
    });

    return response?.data?.companyProfileReviewUpvotes;
  };

  const postUpvoteReview = async ({
    companyId,
    reviewId,
  }: {
    companyId: string;
    reviewId: string;
  }): Promise<ReviewUpvotesPayloadFromGraph | undefined> => {
    const response = await client.mutate<{
      postCompanyProfileReviewUpvotes: ReviewUpvotesPayloadFromGraph;
    }>({
      mutation: POST_COMPANY_REVIEW_UPVOTE_MUTATE,
      variables: {
        input: {
          companyId,
          reviewId,
        },
      },
    });

    return response?.data?.postCompanyProfileReviewUpvotes;
  };

  const postFlagReview = async ({
    companyId,
    reviewId,
    reason,
    details,
  }: {
    companyId: string;
    reviewId: string;
    reason: string;
    details: string;
  }): Promise<ReviewFlagPayloadFromGraph | undefined> => {
    const response = await client.mutate<{
      postCompanyProfileReviewFlag: ReviewFlagPayloadFromGraph;
    }>({
      mutation: POST_COMPANY_REVIEW_FLAG_MUTATE,
      variables: {
        input: {
          companyId,
          reviewId,
          reason,
          details,
        },
      },
    });

    return response?.data?.postCompanyProfileReviewFlag;
  };

  const getFeatureFlags = async ({
    companyId,
    advertiserId,
  }: {
    companyId?: string;
    advertiserId?: string;
  }): Promise<Record<string, unknown>> => {
    const response = await client.query<{
      companyProfilesFeatureFlags: FeatureFlag[];
    }>({
      query: GET_FEATURE_FLAGS_QUERY,
      variables: {
        input: {
          companyId: companyId ?? null,
          advertiserId: advertiserId ?? null,
        } as FeatureFlagInput,
      },
    });

    const featureFlags: Record<string, unknown> = convertToRecord(
      response?.data?.companyProfilesFeatureFlags ?? [],
    );

    return featureFlags;
  };

  const getGroupedCompanies = async ({
    groupBy,
    perPage = 10,
    sortBy,
    excludedCompanyIds = [],
    randomSeed,
  }: {
    groupBy: {
      group: 'INDUSTRY';
      value: string[];
    };
    perPage?: number;
    sortBy?: 'REVIEWS_COUNT';
    excludedCompanyIds?: string[];
    randomSeed?: string;
  }): Promise<CompaniesListModel> => {
    const response = await client.query<{
      groupedCompanies: GroupedCompanies[];
    }>({
      query: GET_GROUPED_COMPANIES_QUERY,
      variables: {
        groupBy,
        perPage,
        sortBy,
        zone: config.zone,
        excludedCompanyIds,
        randomSeed,
      },
    });
    return mapCompanyGroupsToFlatCompanies(response.data.groupedCompanies);
  };

  return {
    getCompanyNameSlug,
    getCompanyProfile,
    getCompanyProfileForeignId,
    getJobs,
    getReviews,
    getUpvoteIds,
    postUpvoteReview,
    postFlagReview,
    searchCompanies,
    getFeatureFlags,
    getGroupedCompanies,
  };
};
