
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGZWF0dXJlZCI6IkZlYXR1cmVkIiwiRmVhdHVyZWQgYmFubmVyIGltYWdlIjoiRmVhdHVyZWQgYmFubmVyIGltYWdlIiwiU2hvd2Nhc2Ugc29tZXRoaW5nIGFib3V0IHlvdXIgb3JnYW5pc2F0aW9uIHRoYXQgeW91IHdhbnQgam9iIHNlZWtlcnMgdG8ga25vdyBhYm91dC4iOiJTaG93Y2FzZSBzb21ldGhpbmcgYWJvdXQgeW91ciBvcmdhbmlzYXRpb24gdGhhdCB5b3Ugd2FudCBqb2Igc2Vla2VycyB0byBrbm93IGFib3V0LiIsIkZlYXR1cmVkIFVwZGF0ZWQiOiJGZWF0dXJlZCBVcGRhdGVkIn0=!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGZWF0dXJlZCI6IkZlYXR1cmVkIiwiRmVhdHVyZWQgYmFubmVyIGltYWdlIjoiRmVhdHVyZWQgYmFubmVyIGltYWdlIiwiU2hvd2Nhc2Ugc29tZXRoaW5nIGFib3V0IHlvdXIgb3JnYW5pc2F0aW9uIHRoYXQgeW91IHdhbnQgam9iIHNlZWtlcnMgdG8ga25vdyBhYm91dC4iOiJTaG93Y2FzZSBzb21ldGhpbmcgYWJvdXQgeW91ciBvcmdhbmlzYXRpb24gdGhhdCB5b3Ugd2FudCBqb2Igc2Vla2VycyB0byBrbm93IGFib3V0LiIsIkZlYXR1cmVkIFVwZGF0ZWQiOiJGZWF0dXJlZCBVcGRhdGVkIn0=!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGZWF0dXJlZCI6IuC4iOC4uOC4lOC5gOC4lOC5iOC4mSIsIkZlYXR1cmVkIGJhbm5lciBpbWFnZSI6IuC4o+C4ueC4m+C4oOC4suC4nuC5geC4muC4meC5gOC4meC4reC4o+C5jOC4l+C4teC5iOC5geC4quC4lOC4hyIsIlNob3djYXNlIHNvbWV0aGluZyBhYm91dCB5b3VyIG9yZ2FuaXNhdGlvbiB0aGF0IHlvdSB3YW50IGpvYiBzZWVrZXJzIHRvIGtub3cgYWJvdXQuIjoi4LmB4Liq4LiU4LiH4LiE4LmI4Liy4LiZ4Li04Lii4Lih4LmB4Lil4Liw4Lin4Lix4LiS4LiZ4LiY4Lij4Lij4Lih4Lit4LiH4LiE4LmM4LiB4Lij4LmA4Lie4Li34LmI4Lit4LiU4Li24LiH4LiU4Li54LiU4LmD4LiI4Lic4Li54LmJ4Lir4Liy4LiH4Liy4LiZIiwiRmVhdHVyZWQgVXBkYXRlZCI6IuC4reC4seC4m+C5gOC4lOC4leC4n+C4teC5gOC4iOC4reC4o+C5jOC5geC4peC5ieC4pyJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGZWF0dXJlZCI6IuC4iOC4uOC4lOC5gOC4lOC5iOC4mSIsIkZlYXR1cmVkIGJhbm5lciBpbWFnZSI6IuC4o+C4ueC4m+C4oOC4suC4nuC5geC4muC4meC5gOC4meC4reC4o+C5jOC4l+C4teC5iOC5geC4quC4lOC4hyIsIlNob3djYXNlIHNvbWV0aGluZyBhYm91dCB5b3VyIG9yZ2FuaXNhdGlvbiB0aGF0IHlvdSB3YW50IGpvYiBzZWVrZXJzIHRvIGtub3cgYWJvdXQuIjoi4LmB4Liq4LiU4LiH4LiE4LmI4Liy4LiZ4Li04Lii4Lih4LmB4Lil4Liw4Lin4Lix4LiS4LiZ4LiY4Lij4Lij4Lih4Lit4LiH4LiE4LmM4LiB4Lij4LmA4Lie4Li34LmI4Lit4LiU4Li24LiH4LiU4Li54LiU4LmD4LiI4Lic4Li54LmJ4Lir4Liy4LiH4Liy4LiZIiwiRmVhdHVyZWQgVXBkYXRlZCI6IuC4reC4seC4m+C5gOC4lOC4leC4n+C4teC5gOC4iOC4reC4o+C5jOC5geC4peC5ieC4pyJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGZWF0dXJlZCI6IlNvcm90YW4iLCJGZWF0dXJlZCBiYW5uZXIgaW1hZ2UiOiJHYW1iYXIgc3BhbmR1ayB1bmdndWxhbiIsIlNob3djYXNlIHNvbWV0aGluZyBhYm91dCB5b3VyIG9yZ2FuaXNhdGlvbiB0aGF0IHlvdSB3YW50IGpvYiBzZWVrZXJzIHRvIGtub3cgYWJvdXQuIjoiVGFtcGlsa2FuIHN1YXR1IGhhbCB0ZW50YW5nIG9yZ2FuaXNhc2kgQW5kYSB5YW5nIHBlcmx1IGRpa2V0YWh1aSBvbGVoIHBlbmNhcmkga2VyamEuIiwiRmVhdHVyZWQgVXBkYXRlZCI6IkZpdHVyIERpcGVyYmFydWkifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGZWF0dXJlZCI6IlNvcm90YW4iLCJGZWF0dXJlZCBiYW5uZXIgaW1hZ2UiOiJHYW1iYXIgc3BhbmR1ayB1bmdndWxhbiIsIlNob3djYXNlIHNvbWV0aGluZyBhYm91dCB5b3VyIG9yZ2FuaXNhdGlvbiB0aGF0IHlvdSB3YW50IGpvYiBzZWVrZXJzIHRvIGtub3cgYWJvdXQuIjoiVGFtcGlsa2FuIHN1YXR1IGhhbCB0ZW50YW5nIG9yZ2FuaXNhc2kgQW5kYSB5YW5nIHBlcmx1IGRpa2V0YWh1aSBvbGVoIHBlbmNhcmkga2VyamEuIiwiRmVhdHVyZWQgVXBkYXRlZCI6IkZpdHVyIERpcGVyYmFydWkifQ==!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGZWF0dXJlZCI6IkZlYXR1cmVkIiwiRmVhdHVyZWQgYmFubmVyIGltYWdlIjoiRmVhdHVyZWQgYmFubmVyIGltYWdlIiwiU2hvd2Nhc2Ugc29tZXRoaW5nIGFib3V0IHlvdXIgb3JnYW5pc2F0aW9uIHRoYXQgeW91IHdhbnQgam9iIHNlZWtlcnMgdG8ga25vdyBhYm91dC4iOiJTaG93Y2FzZSBzb21ldGhpbmcgYWJvdXQgeW91ciBvcmdhbmlzYXRpb24gdGhhdCB5b3Ugd2FudCBqb2Igc2Vla2VycyB0byBrbm93IGFib3V0LiIsIkZlYXR1cmVkIFVwZGF0ZWQiOiJGZWF0dXJlZCBVcGRhdGVkIn0=!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGZWF0dXJlZCI6IkZlYXR1cmVkIiwiRmVhdHVyZWQgYmFubmVyIGltYWdlIjoiRmVhdHVyZWQgYmFubmVyIGltYWdlIiwiU2hvd2Nhc2Ugc29tZXRoaW5nIGFib3V0IHlvdXIgb3JnYW5pc2F0aW9uIHRoYXQgeW91IHdhbnQgam9iIHNlZWtlcnMgdG8ga25vdyBhYm91dC4iOiJTaG93Y2FzZSBzb21ldGhpbmcgYWJvdXQgeW91ciBvcmdhbmlzYXRpb24gdGhhdCB5b3Ugd2FudCBqb2Igc2Vla2VycyB0byBrbm93IGFib3V0LiIsIkZlYXR1cmVkIFVwZGF0ZWQiOiJGZWF0dXJlZCBVcGRhdGVkIn0=!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGZWF0dXJlZCI6IkZlYXR1cmVkIiwiRmVhdHVyZWQgYmFubmVyIGltYWdlIjoiRmVhdHVyZWQgYmFubmVyIGltYWdlIiwiU2hvd2Nhc2Ugc29tZXRoaW5nIGFib3V0IHlvdXIgb3JnYW5pc2F0aW9uIHRoYXQgeW91IHdhbnQgam9iIHNlZWtlcnMgdG8ga25vdyBhYm91dC4iOiJTaG93Y2FzZSBzb21ldGhpbmcgYWJvdXQgeW91ciBvcmdhbmlzYXRpb24gdGhhdCB5b3Ugd2FudCBqb2Igc2Vla2VycyB0byBrbm93IGFib3V0LiIsIkZlYXR1cmVkIFVwZGF0ZWQiOiJGZWF0dXJlZCBVcGRhdGVkIn0=!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGZWF0dXJlZCI6IkZlYXR1cmVkIiwiRmVhdHVyZWQgYmFubmVyIGltYWdlIjoiRmVhdHVyZWQgYmFubmVyIGltYWdlIiwiU2hvd2Nhc2Ugc29tZXRoaW5nIGFib3V0IHlvdXIgb3JnYW5pc2F0aW9uIHRoYXQgeW91IHdhbnQgam9iIHNlZWtlcnMgdG8ga25vdyBhYm91dC4iOiJTaG93Y2FzZSBzb21ldGhpbmcgYWJvdXQgeW91ciBvcmdhbmlzYXRpb24gdGhhdCB5b3Ugd2FudCBqb2Igc2Vla2VycyB0byBrbm93IGFib3V0LiIsIkZlYXR1cmVkIFVwZGF0ZWQiOiJGZWF0dXJlZCBVcGRhdGVkIn0=!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGZWF0dXJlZCI6IkZlYXR1cmVkIiwiRmVhdHVyZWQgYmFubmVyIGltYWdlIjoiRmVhdHVyZWQgYmFubmVyIGltYWdlIiwiU2hvd2Nhc2Ugc29tZXRoaW5nIGFib3V0IHlvdXIgb3JnYW5pc2F0aW9uIHRoYXQgeW91IHdhbnQgam9iIHNlZWtlcnMgdG8ga25vdyBhYm91dC4iOiJTaG93Y2FzZSBzb21ldGhpbmcgYWJvdXQgeW91ciBvcmdhbmlzYXRpb24gdGhhdCB5b3Ugd2FudCBqb2Igc2Vla2VycyB0byBrbm93IGFib3V0LiIsIkZlYXR1cmVkIFVwZGF0ZWQiOiJGZWF0dXJlZCBVcGRhdGVkIn0=!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGZWF0dXJlZCI6IkZlYXR1cmVkIiwiRmVhdHVyZWQgYmFubmVyIGltYWdlIjoiRmVhdHVyZWQgYmFubmVyIGltYWdlIiwiU2hvd2Nhc2Ugc29tZXRoaW5nIGFib3V0IHlvdXIgb3JnYW5pc2F0aW9uIHRoYXQgeW91IHdhbnQgam9iIHNlZWtlcnMgdG8ga25vdyBhYm91dC4iOiJTaG93Y2FzZSBzb21ldGhpbmcgYWJvdXQgeW91ciBvcmdhbmlzYXRpb24gdGhhdCB5b3Ugd2FudCBqb2Igc2Vla2VycyB0byBrbm93IGFib3V0LiIsIkZlYXR1cmVkIFVwZGF0ZWQiOiJGZWF0dXJlZCBVcGRhdGVkIn0=!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGZWF0dXJlZCI6IkZlYXR1cmVkIiwiRmVhdHVyZWQgYmFubmVyIGltYWdlIjoiRmVhdHVyZWQgYmFubmVyIGltYWdlIiwiU2hvd2Nhc2Ugc29tZXRoaW5nIGFib3V0IHlvdXIgb3JnYW5pc2F0aW9uIHRoYXQgeW91IHdhbnQgam9iIHNlZWtlcnMgdG8ga25vdyBhYm91dC4iOiJTaG93Y2FzZSBzb21ldGhpbmcgYWJvdXQgeW91ciBvcmdhbmlzYXRpb24gdGhhdCB5b3Ugd2FudCBqb2Igc2Vla2VycyB0byBrbm93IGFib3V0LiIsIkZlYXR1cmVkIFVwZGF0ZWQiOiJGZWF0dXJlZCBVcGRhdGVkIn0=!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGZWF0dXJlZCI6IkZlYXR1cmVkIiwiRmVhdHVyZWQgYmFubmVyIGltYWdlIjoiRmVhdHVyZWQgYmFubmVyIGltYWdlIiwiU2hvd2Nhc2Ugc29tZXRoaW5nIGFib3V0IHlvdXIgb3JnYW5pc2F0aW9uIHRoYXQgeW91IHdhbnQgam9iIHNlZWtlcnMgdG8ga25vdyBhYm91dC4iOiJTaG93Y2FzZSBzb21ldGhpbmcgYWJvdXQgeW91ciBvcmdhbmlzYXRpb24gdGhhdCB5b3Ugd2FudCBqb2Igc2Vla2VycyB0byBrbm93IGFib3V0LiIsIkZlYXR1cmVkIFVwZGF0ZWQiOiJGZWF0dXJlZCBVcGRhdGVkIn0=!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGZWF0dXJlZCI6IkZlYXR1cmVkIiwiRmVhdHVyZWQgYmFubmVyIGltYWdlIjoiRmVhdHVyZWQgYmFubmVyIGltYWdlIiwiU2hvd2Nhc2Ugc29tZXRoaW5nIGFib3V0IHlvdXIgb3JnYW5pc2F0aW9uIHRoYXQgeW91IHdhbnQgam9iIHNlZWtlcnMgdG8ga25vdyBhYm91dC4iOiJTaG93Y2FzZSBzb21ldGhpbmcgYWJvdXQgeW91ciBvcmdhbmlzYXRpb24gdGhhdCB5b3Ugd2FudCBqb2Igc2Vla2VycyB0byBrbm93IGFib3V0LiIsIkZlYXR1cmVkIFVwZGF0ZWQiOiJGZWF0dXJlZCBVcGRhdGVkIn0=!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGZWF0dXJlZCI6IkZlYXR1cmVkIiwiRmVhdHVyZWQgYmFubmVyIGltYWdlIjoiRmVhdHVyZWQgYmFubmVyIGltYWdlIiwiU2hvd2Nhc2Ugc29tZXRoaW5nIGFib3V0IHlvdXIgb3JnYW5pc2F0aW9uIHRoYXQgeW91IHdhbnQgam9iIHNlZWtlcnMgdG8ga25vdyBhYm91dC4iOiJTaG93Y2FzZSBzb21ldGhpbmcgYWJvdXQgeW91ciBvcmdhbmlzYXRpb24gdGhhdCB5b3Ugd2FudCBqb2Igc2Vla2VycyB0byBrbm93IGFib3V0LiIsIkZlYXR1cmVkIFVwZGF0ZWQiOiJGZWF0dXJlZCBVcGRhdGVkIn0=!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGZWF0dXJlZCI6IkZlYXR1cmVkIiwiRmVhdHVyZWQgYmFubmVyIGltYWdlIjoiRmVhdHVyZWQgYmFubmVyIGltYWdlIiwiU2hvd2Nhc2Ugc29tZXRoaW5nIGFib3V0IHlvdXIgb3JnYW5pc2F0aW9uIHRoYXQgeW91IHdhbnQgam9iIHNlZWtlcnMgdG8ga25vdyBhYm91dC4iOiJTaG93Y2FzZSBzb21ldGhpbmcgYWJvdXQgeW91ciBvcmdhbmlzYXRpb24gdGhhdCB5b3Ugd2FudCBqb2Igc2Vla2VycyB0byBrbm93IGFib3V0LiIsIkZlYXR1cmVkIFVwZGF0ZWQiOiJGZWF0dXJlZCBVcGRhdGVkIn0=!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGZWF0dXJlZCI6IkZlYXR1cmVkIiwiRmVhdHVyZWQgYmFubmVyIGltYWdlIjoiRmVhdHVyZWQgYmFubmVyIGltYWdlIiwiU2hvd2Nhc2Ugc29tZXRoaW5nIGFib3V0IHlvdXIgb3JnYW5pc2F0aW9uIHRoYXQgeW91IHdhbnQgam9iIHNlZWtlcnMgdG8ga25vdyBhYm91dC4iOiJTaG93Y2FzZSBzb21ldGhpbmcgYWJvdXQgeW91ciBvcmdhbmlzYXRpb24gdGhhdCB5b3Ugd2FudCBqb2Igc2Vla2VycyB0byBrbm93IGFib3V0LiIsIkZlYXR1cmVkIFVwZGF0ZWQiOiJGZWF0dXJlZCBVcGRhdGVkIn0=!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGZWF0dXJlZCI6IkZlYXR1cmVkIiwiRmVhdHVyZWQgYmFubmVyIGltYWdlIjoiRmVhdHVyZWQgYmFubmVyIGltYWdlIiwiU2hvd2Nhc2Ugc29tZXRoaW5nIGFib3V0IHlvdXIgb3JnYW5pc2F0aW9uIHRoYXQgeW91IHdhbnQgam9iIHNlZWtlcnMgdG8ga25vdyBhYm91dC4iOiJTaG93Y2FzZSBzb21ldGhpbmcgYWJvdXQgeW91ciBvcmdhbmlzYXRpb24gdGhhdCB5b3Ugd2FudCBqb2Igc2Vla2VycyB0byBrbm93IGFib3V0LiIsIkZlYXR1cmVkIFVwZGF0ZWQiOiJGZWF0dXJlZCBVcGRhdGVkIn0=!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGZWF0dXJlZCI6IkZlYXR1cmVkIiwiRmVhdHVyZWQgYmFubmVyIGltYWdlIjoiRmVhdHVyZWQgYmFubmVyIGltYWdlIiwiU2hvd2Nhc2Ugc29tZXRoaW5nIGFib3V0IHlvdXIgb3JnYW5pc2F0aW9uIHRoYXQgeW91IHdhbnQgam9iIHNlZWtlcnMgdG8ga25vdyBhYm91dC4iOiJTaG93Y2FzZSBzb21ldGhpbmcgYWJvdXQgeW91ciBvcmdhbmlzYXRpb24gdGhhdCB5b3Ugd2FudCBqb2Igc2Vla2VycyB0byBrbm93IGFib3V0LiIsIkZlYXR1cmVkIFVwZGF0ZWQiOiJGZWF0dXJlZCBVcGRhdGVkIn0=!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGZWF0dXJlZCI6IkZlYXR1cmVkIiwiRmVhdHVyZWQgYmFubmVyIGltYWdlIjoiRmVhdHVyZWQgYmFubmVyIGltYWdlIiwiU2hvd2Nhc2Ugc29tZXRoaW5nIGFib3V0IHlvdXIgb3JnYW5pc2F0aW9uIHRoYXQgeW91IHdhbnQgam9iIHNlZWtlcnMgdG8ga25vdyBhYm91dC4iOiJTaG93Y2FzZSBzb21ldGhpbmcgYWJvdXQgeW91ciBvcmdhbmlzYXRpb24gdGhhdCB5b3Ugd2FudCBqb2Igc2Vla2VycyB0byBrbm93IGFib3V0LiIsIkZlYXR1cmVkIFVwZGF0ZWQiOiJGZWF0dXJlZCBVcGRhdGVkIn0=!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGZWF0dXJlZCI6IkZlYXR1cmVkIiwiRmVhdHVyZWQgYmFubmVyIGltYWdlIjoiRmVhdHVyZWQgYmFubmVyIGltYWdlIiwiU2hvd2Nhc2Ugc29tZXRoaW5nIGFib3V0IHlvdXIgb3JnYW5pc2F0aW9uIHRoYXQgeW91IHdhbnQgam9iIHNlZWtlcnMgdG8ga25vdyBhYm91dC4iOiJTaG93Y2FzZSBzb21ldGhpbmcgYWJvdXQgeW91ciBvcmdhbmlzYXRpb24gdGhhdCB5b3Ugd2FudCBqb2Igc2Vla2VycyB0byBrbm93IGFib3V0LiIsIkZlYXR1cmVkIFVwZGF0ZWQiOiJGZWF0dXJlZCBVcGRhdGVkIn0=!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGZWF0dXJlZCI6IkZlYXR1cmVkIiwiRmVhdHVyZWQgYmFubmVyIGltYWdlIjoiRmVhdHVyZWQgYmFubmVyIGltYWdlIiwiU2hvd2Nhc2Ugc29tZXRoaW5nIGFib3V0IHlvdXIgb3JnYW5pc2F0aW9uIHRoYXQgeW91IHdhbnQgam9iIHNlZWtlcnMgdG8ga25vdyBhYm91dC4iOiJTaG93Y2FzZSBzb21ldGhpbmcgYWJvdXQgeW91ciBvcmdhbmlzYXRpb24gdGhhdCB5b3Ugd2FudCBqb2Igc2Vla2VycyB0byBrbm93IGFib3V0LiIsIkZlYXR1cmVkIFVwZGF0ZWQiOiJGZWF0dXJlZCBVcGRhdGVkIn0=!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGZWF0dXJlZCI6IkZlYXR1cmVkIiwiRmVhdHVyZWQgYmFubmVyIGltYWdlIjoiRmVhdHVyZWQgYmFubmVyIGltYWdlIiwiU2hvd2Nhc2Ugc29tZXRoaW5nIGFib3V0IHlvdXIgb3JnYW5pc2F0aW9uIHRoYXQgeW91IHdhbnQgam9iIHNlZWtlcnMgdG8ga25vdyBhYm91dC4iOiJTaG93Y2FzZSBzb21ldGhpbmcgYWJvdXQgeW91ciBvcmdhbmlzYXRpb24gdGhhdCB5b3Ugd2FudCBqb2Igc2Vla2VycyB0byBrbm93IGFib3V0LiIsIkZlYXR1cmVkIFVwZGF0ZWQiOiJGZWF0dXJlZCBVcGRhdGVkIn0=!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGZWF0dXJlZCI6IuC4iOC4uOC4lOC5gOC4lOC5iOC4mSIsIkZlYXR1cmVkIGJhbm5lciBpbWFnZSI6IuC4o+C4ueC4m+C4oOC4suC4nuC5geC4muC4meC5gOC4meC4reC4o+C5jOC4l+C4teC5iOC5geC4quC4lOC4hyIsIlNob3djYXNlIHNvbWV0aGluZyBhYm91dCB5b3VyIG9yZ2FuaXNhdGlvbiB0aGF0IHlvdSB3YW50IGpvYiBzZWVrZXJzIHRvIGtub3cgYWJvdXQuIjoi4LmB4Liq4LiU4LiH4LiE4LmI4Liy4LiZ4Li04Lii4Lih4LmB4Lil4Liw4Lin4Lix4LiS4LiZ4LiY4Lij4Lij4Lih4Lit4LiH4LiE4LmM4LiB4Lij4LmA4Lie4Li34LmI4Lit4LiU4Li24LiH4LiU4Li54LiU4LmD4LiI4Lic4Li54LmJ4Lir4Liy4LiH4Liy4LiZIiwiRmVhdHVyZWQgVXBkYXRlZCI6IuC4reC4seC4m+C5gOC4lOC4leC4n+C4teC5gOC4iOC4reC4o+C5jOC5geC4peC5ieC4pyJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGZWF0dXJlZCI6IuC4iOC4uOC4lOC5gOC4lOC5iOC4mSIsIkZlYXR1cmVkIGJhbm5lciBpbWFnZSI6IuC4o+C4ueC4m+C4oOC4suC4nuC5geC4muC4meC5gOC4meC4reC4o+C5jOC4l+C4teC5iOC5geC4quC4lOC4hyIsIlNob3djYXNlIHNvbWV0aGluZyBhYm91dCB5b3VyIG9yZ2FuaXNhdGlvbiB0aGF0IHlvdSB3YW50IGpvYiBzZWVrZXJzIHRvIGtub3cgYWJvdXQuIjoi4LmB4Liq4LiU4LiH4LiE4LmI4Liy4LiZ4Li04Lii4Lih4LmB4Lil4Liw4Lin4Lix4LiS4LiZ4LiY4Lij4Lij4Lih4Lit4LiH4LiE4LmM4LiB4Lij4LmA4Lie4Li34LmI4Lit4LiU4Li24LiH4LiU4Li54LiU4LmD4LiI4Lic4Li54LmJ4Lir4Liy4LiH4Liy4LiZIiwiRmVhdHVyZWQgVXBkYXRlZCI6IuC4reC4seC4m+C5gOC4lOC4leC4n+C4teC5gOC4iOC4reC4o+C5jOC5geC4peC5ieC4pyJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGZWF0dXJlZCI6IlNvcm90YW4iLCJGZWF0dXJlZCBiYW5uZXIgaW1hZ2UiOiJHYW1iYXIgc3BhbmR1ayB1bmdndWxhbiIsIlNob3djYXNlIHNvbWV0aGluZyBhYm91dCB5b3VyIG9yZ2FuaXNhdGlvbiB0aGF0IHlvdSB3YW50IGpvYiBzZWVrZXJzIHRvIGtub3cgYWJvdXQuIjoiVGFtcGlsa2FuIHN1YXR1IGhhbCB0ZW50YW5nIG9yZ2FuaXNhc2kgQW5kYSB5YW5nIHBlcmx1IGRpa2V0YWh1aSBvbGVoIHBlbmNhcmkga2VyamEuIiwiRmVhdHVyZWQgVXBkYXRlZCI6IkZpdHVyIERpcGVyYmFydWkifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGZWF0dXJlZCI6IlNvcm90YW4iLCJGZWF0dXJlZCBiYW5uZXIgaW1hZ2UiOiJHYW1iYXIgc3BhbmR1ayB1bmdndWxhbiIsIlNob3djYXNlIHNvbWV0aGluZyBhYm91dCB5b3VyIG9yZ2FuaXNhdGlvbiB0aGF0IHlvdSB3YW50IGpvYiBzZWVrZXJzIHRvIGtub3cgYWJvdXQuIjoiVGFtcGlsa2FuIHN1YXR1IGhhbCB0ZW50YW5nIG9yZ2FuaXNhc2kgQW5kYSB5YW5nIHBlcmx1IGRpa2V0YWh1aSBvbGVoIHBlbmNhcmkga2VyamEuIiwiRmVhdHVyZWQgVXBkYXRlZCI6IkZpdHVyIERpcGVyYmFydWkifQ==!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGZWF0dXJlZCI6IkZlYXR1cmVkIiwiRmVhdHVyZWQgYmFubmVyIGltYWdlIjoiRmVhdHVyZWQgYmFubmVyIGltYWdlIiwiU2hvd2Nhc2Ugc29tZXRoaW5nIGFib3V0IHlvdXIgb3JnYW5pc2F0aW9uIHRoYXQgeW91IHdhbnQgam9iIHNlZWtlcnMgdG8ga25vdyBhYm91dC4iOiJTaG93Y2FzZSBzb21ldGhpbmcgYWJvdXQgeW91ciBvcmdhbmlzYXRpb24gdGhhdCB5b3Ugd2FudCBqb2Igc2Vla2VycyB0byBrbm93IGFib3V0LiIsIkZlYXR1cmVkIFVwZGF0ZWQiOiJGZWF0dXJlZCBVcGRhdGVkIn0=!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGZWF0dXJlZCI6IkZlYXR1cmVkIiwiRmVhdHVyZWQgYmFubmVyIGltYWdlIjoiRmVhdHVyZWQgYmFubmVyIGltYWdlIiwiU2hvd2Nhc2Ugc29tZXRoaW5nIGFib3V0IHlvdXIgb3JnYW5pc2F0aW9uIHRoYXQgeW91IHdhbnQgam9iIHNlZWtlcnMgdG8ga25vdyBhYm91dC4iOiJTaG93Y2FzZSBzb21ldGhpbmcgYWJvdXQgeW91ciBvcmdhbmlzYXRpb24gdGhhdCB5b3Ugd2FudCBqb2Igc2Vla2VycyB0byBrbm93IGFib3V0LiIsIkZlYXR1cmVkIFVwZGF0ZWQiOiJGZWF0dXJlZCBVcGRhdGVkIn0=!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGZWF0dXJlZCI6IlvGkeG6veG6vcSDxIPhua/HmseaxZnhur3hur3GjF0iLCJGZWF0dXJlZCBiYW5uZXIgaW1hZ2UiOiJbxpHhur3hur3Eg8SD4bmvx5rHmsWZ4bq94bq9xowgw5/Eg8SD4LiB4Li14LmJ4LiB4Li14LmJ4bq94bq9xZkgw6zDrG3MgsSDxIPEo+G6veG6vV0iLCJTaG93Y2FzZSBzb21ldGhpbmcgYWJvdXQgeW91ciBvcmdhbmlzYXRpb24gdGhhdCB5b3Ugd2FudCBqb2Igc2Vla2VycyB0byBrbm93IGFib3V0LiI6IlvFoOG4qcO2w7bDtsW1w6fEg8SDxIPFoeG6veG6veG6vSDFocO2w7bDtm3MguG6veG6veG6veG5r+G4qcOsw6zDrOC4geC4teC5icSjIMSDxIPEg8Ofw7bDtsO2x5rHmsea4bmvIMO9w73DvcO2w7bDtseax5rHmsWZIMO2w7bDtsWZxKPEg8SDxIPguIHguLXguYnDrMOsw6zFocSDxIPEg+G5r8Osw6zDrMO2w7bDtuC4geC4teC5iSDhua/huKnEg8SDxIPhua8gw73DvcO9w7bDtsO2x5rHmseaIMW1xIPEg8SD4LiB4Li14LmJ4bmvIMS1w7bDtsO2w58gxaHhur3hur3hur3hur3hur3hur3Et+G6veG6veG6vcWZxaEg4bmvw7bDtsO2IMS34LiB4Li14LmJw7bDtsO2xbUgxIPEg8SDw5/DtsO2w7bHmseax5rhua8uXSIsIkZlYXR1cmVkIFVwZGF0ZWQiOiJbxpHhur3hur3hur3Eg8SDxIPhua/Hmseax5rFmeG6veG6veG6vcaMIMeZxqXGjMSDxIPEg+G5r+G6veG6veG6vcaMXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGZWF0dXJlZCI6IlvGkeG6veG6vcSDxIPhua/HmseaxZnhur3hur3GjF0iLCJGZWF0dXJlZCBiYW5uZXIgaW1hZ2UiOiJbxpHhur3hur3Eg8SD4bmvx5rHmsWZ4bq94bq9xowgw5/Eg8SD4LiB4Li14LmJ4LiB4Li14LmJ4bq94bq9xZkgw6zDrG3MgsSDxIPEo+G6veG6vV0iLCJTaG93Y2FzZSBzb21ldGhpbmcgYWJvdXQgeW91ciBvcmdhbmlzYXRpb24gdGhhdCB5b3Ugd2FudCBqb2Igc2Vla2VycyB0byBrbm93IGFib3V0LiI6IlvFoOG4qcO2w7bDtsW1w6fEg8SDxIPFoeG6veG6veG6vSDFocO2w7bDtm3MguG6veG6veG6veG5r+G4qcOsw6zDrOC4geC4teC5icSjIMSDxIPEg8Ofw7bDtsO2x5rHmsea4bmvIMO9w73DvcO2w7bDtseax5rHmsWZIMO2w7bDtsWZxKPEg8SDxIPguIHguLXguYnDrMOsw6zFocSDxIPEg+G5r8Osw6zDrMO2w7bDtuC4geC4teC5iSDhua/huKnEg8SDxIPhua8gw73DvcO9w7bDtsO2x5rHmseaIMW1xIPEg8SD4LiB4Li14LmJ4bmvIMS1w7bDtsO2w58gxaHhur3hur3hur3hur3hur3hur3Et+G6veG6veG6vcWZxaEg4bmvw7bDtsO2IMS34LiB4Li14LmJw7bDtsO2xbUgxIPEg8SDw5/DtsO2w7bHmseax5rhua8uXSIsIkZlYXR1cmVkIFVwZGF0ZWQiOiJbxpHhur3hur3hur3Eg8SDxIPhua/Hmseax5rFmeG6veG6veG6vcaMIMeZxqXGjMSDxIPEg+G5r+G6veG6veG6vcaMXSJ9!"
        )
      )
      });
  
      export { translations as default };
    