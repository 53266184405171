import { useTranslations } from '@vocab/react';
import {
  Notice,
  Box,
  Column,
  Columns,
  IconNewWindow,
  Stack,
  Text,
  TextLink,
  Secondary,
} from 'braid-design-system';
import { ReadMore } from '@seek/profile-read-ui';

import translations from './.vocab';
import { Section } from '../../common/section/Section';
import { UpdateSuccessAlert, type CompanyProfile } from '../../../';

type CompanyOverviewSectionModel = CompanyProfile['overview'] & {
  name: string;
};
interface Props {
  mode: 'view' | 'edit';
  model: CompanyOverviewSectionModel;
  loading?: boolean;
  trackWebsiteClicked?: () => void;

  /**
   * Only used for 'edit' mode
   */
  showUpdateSuccess?: boolean;
  onEditClick?: () => void;
  onHideUpdateSuccess?: () => void;
}

export function CompanyOverviewSection({
  mode,
  model,
  loading,
  showUpdateSuccess = false,
  onEditClick,
  trackWebsiteClicked,
  onHideUpdateSuccess,
}: Props) {
  const { t } = useTranslations(translations);

  const editMode = mode === 'edit';

  const empty = !model || Object.keys(model).length === 0;

  return (
    <Section
      mode={mode}
      dataEmpty={empty}
      loading={loading}
      heading={t('Company overview')}
      addDataHint={t('Tell job seekers more about your company.')}
      onEditClick={onEditClick}
    >
      {!loading && model && (
        <Stack space={['large', 'medium']}>
          {showUpdateSuccess && (
            <UpdateSuccessAlert
              onClose={() => {
                if (onHideUpdateSuccess) onHideUpdateSuccess();
              }}
              text={t('Company overview updated')}
            />
          )}
          {detailRow({
            editMode,
            label: t('Website'),
            addLabel: t('Add website'),
            value: model.website?.url,
            viewComponent: (
              <Text>
                <TextLink
                  id="website-value"
                  href={model.website?.url ?? ''}
                  onClick={() => {
                    if (trackWebsiteClicked) trackWebsiteClicked();
                  }}
                  target="_blank"
                >
                  {model.name} <IconNewWindow />
                </TextLink>
              </Text>
            ),
          })}
          {detailRow({
            editMode,
            value: model.industry,
            label: t('Industry'),
            addLabel: t('Add industry'),
          })}
          {detailRow({
            editMode,
            value: model.companySize,
            label: t('Company size'),
            addLabel: t('Add company size'),
          })}
          {detailRow({
            editMode,
            value: model.primaryLocation,
            label: t('Primary location'),
            addLabel: 'Add primary location',
          })}
          {detailRow({
            editMode,
            value: model.specialities?.join(', '),
            label: t('Specialities'),
            addLabel: t('Add specialities'),
          })}
          {detailRow({
            editMode,
            value: model.description?.join(' '),
            label: editMode ? t('Description') : undefined,
            addLabel: t('Add description'),
            editComponent: (
              <Stack space="gutter">
                {model.description?.map((p, i) => (
                  <Text tone="neutral" key={i}>
                    {p}
                  </Text>
                ))}
              </Stack>
            ),
            viewComponent: (
              <Box paddingTop="small">
                <ReadMore
                  moreLabel={t('Show more')}
                  lessLabel={t('Show less')}
                  linesToPreview={3}
                >
                  <Stack space="gutter">
                    {model.description?.map((p, i) => (
                      <Secondary key={i}>{p}</Secondary>
                    ))}
                  </Stack>
                </ReadMore>
              </Box>
            ),
          })}
        </Stack>
      )}
    </Section>
  );
}

export function detailRow({
  editMode,
  label,
  addLabel,
  value,
  viewComponent,
  editComponent,
}: {
  editMode: boolean;
  label?: string;
  addLabel: string;
  value?: string;
  viewComponent?: JSX.Element;
  editComponent?: JSX.Element;
}) {
  let valueComponent = (
    <Box>
      <Text>{value}</Text>
    </Box>
  );

  if (editMode && editComponent) {
    valueComponent = editComponent;
  } else if (!editMode && viewComponent) {
    valueComponent = viewComponent;
  }

  if (value || (!editMode && viewComponent && value)) {
    return (
      <Columns space={['medium', 'large']} collapseBelow="tablet">
        {label ? (
          <Column width="1/4">
            <Text weight="strong">{label}</Text>
          </Column>
        ) : null}
        <Column>{valueComponent}</Column>
      </Columns>
    );
  }

  if (editMode && !value) {
    return (
      <Columns space="large">
        <Column width="1/4">
          <Text weight="strong">{label}</Text>
        </Column>
        <Column>
          <Notice tone="info">
            <Text>{addLabel}</Text>
          </Notice>
        </Column>
      </Columns>
    );
  }

  return null;
}
