import { useRouteLoaderData } from 'react-router';
import { CompanyProfilesPage } from '../../paths';
import type { HomePageModel } from './homePageModel';
import { HomePage } from './HomePage';
import { PageLoader } from '@seek/libs-shared';

export function HomePageContainer(): JSX.Element {
  const model = useRouteLoaderData(CompanyProfilesPage.Home) as HomePageModel;

  return model ? <HomePage model={model} /> : <PageLoader />;
}
