import {
  type Country,
  createAbsoluteUrl,
  type Language,
  type Locale,
} from '@seek/melways-sites';
import { type Environment, type Experience, type Zone, slugifyCompany } from '..';

export const buildCommunityGuidelinesURI = ({
  experience,
  locale,
  zone,
}: {
  experience: Experience;
  locale: Locale;
  zone: Zone;
}) => {
  if (experience === 'candidate') {
    switch (zone) {
      case 'asia-1':
        return 'https://help.hk.jobsdb.com/s/article/company-reviews-community-guidelines-DB';
      case 'asia-3':
        return locale === 'th-TH'
          ? 'https://help.th.jobsdb.com/th/s/article/company-reviews-community-guidelines-DB?language=th'
          : 'https://help.th.jobsdb.com/th/s/article/company-reviews-community-guidelines-DB?language=en_US';
      case 'asia-4':
        return locale === 'id-ID'
          ? 'https://help.id.jobstreet.com/id/s/article/Company-reviews-community-guidelines-asia?language=in'
          : 'https://help.id.jobstreet.com/s/article/Company-reviews-community-guidelines-asia?language=en_US';
      case 'asia-5':
        return 'https://help.my.jobstreet.com/s/article/Company-reviews-community-guidelines-asia';
      case 'asia-6':
        return 'https://help.jobstreet.com.ph/s/article/Company-reviews-community-guidelines-asia';
      case 'asia-7':
        return 'https://help.sg.jobstreet.com/s/article/Company-reviews-community-guidelines-asia';
      case 'anz-2':
        return 'https://help.seek.co.nz/s/article/Company-reviews-community-guidelines-employer';
      default:
        return 'https://help.seek.com.au/s/article/Company-reviews-community-guidelines';
    }
  } else {
    switch (zone) {
      case 'asia-1':
        return 'https://help.hk.employer.seek.com/s/article/Company-reviews-community-guidelines-employer-asia';
      case 'asia-3':
        return locale === 'th-TH'
          ? 'https://help.th.employer.seek.com/th/s/article/Company-reviews-community-guidelines-employer-asia?language=th'
          : 'https://help.th.employer.seek.com/s/article/Company-reviews-community-guidelines-employer-asia?language=en_US';
      case 'asia-4':
        return locale === 'id-ID'
          ? 'https://help.id.employer.seek.com/id/s/article/Company-reviews-community-guidelines-employer-asia?language=in'
          : 'https://help.id.employer.seek.com/id/s/article/Company-reviews-community-guidelines-employer-asia';
      case 'asia-5':
        return 'https://help.my.employer.seek.com/s/article/Company-reviews-community-guidelines-employer-asia';
      case 'asia-6':
        return 'https://help.ph.employer.seek.com/s/article/Company-reviews-community-guidelines-employer-asia';
      case 'asia-7':
        return 'https://help.sg.employer.seek.com/s/article/Company-reviews-community-guidelines-employer-asia';
      case 'anz-2':
        return 'https://help.seek.co.nz/employer/s/article/Company-reviews-community-guidelines-employer';
      default:
        return 'https://help.seek.com.au/employer/s/article/Company-reviews-community-guidelines-employer';
    }
  }
};

export const buildEmployerCompanyProfilePromoPageURI = ({
  country,
  environment,
  language,
}: {
  country: Country;
  environment: Environment;
  language: Language;
}) =>
  createAbsoluteUrl({
    country,
    product: 'employer',
    path: '/products/company-profiles',
    staging: environment !== 'production',
    language,
  });

export const buildWriteAReviewURI = ({
  country,
  environment,
  language,
  consolidated,
}: {
  country: Country;
  environment: Environment;
  language: Language;
  consolidated?: boolean;
}) =>
  createAbsoluteUrl({
    country,
    product: 'candidate',
    path: '/companies/write-a-review',
    staging: environment !== 'production',
    language,
    ...(consolidated ? { alternative: 'subDomainRegion' } : {}),
  });

export const buildCandidateCompanyProfileURI = ({
  companyName,
  companyId,
  country,
  language,
  isStaging = false,
}: {
  companyName: string;
  companyId: string;
  country: Country;
  language: Language;
  isStaging?: boolean;
}) => {
  const slug = slugifyCompany(companyName, companyId);

  return createAbsoluteUrl({
    country,
    language,
    staging: isStaging,
    product: 'candidate',
    path: `/companies/${slug}`,
  });
};
