
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIG91dCBtb3JlIGFib3V0IHdvcmtpbmcgYXQgY29tcGFueVByb2ZpbGVNb2RlbE5hbWUuIFJlYWQgY29tcGFueSByZXZpZXdzIGZyb20gcmVhbCBlbXBsb3llZXMsIGV4cGxvcmUgbGlmZSBhbmQgY3VsdHVyZSBhbmQgdmlldyBhbGwgb3BlbiBqb2JzLiI6IkZpbmQgb3V0IG1vcmUgYWJvdXQgd29ya2luZyBhdCB7Y29tcGFueVByb2ZpbGVNb2RlbE5hbWV9LiBSZWFkIGNvbXBhbnkgPFJldmlld3NQYWdlPnJldmlld3M8L1Jldmlld3NQYWdlPiBmcm9tIHJlYWwgZW1wbG95ZWVzLCBleHBsb3JlIDxDdWx0dXJlUGFnZT5saWZlIGFuZCBjdWx0dXJlPC9DdWx0dXJlUGFnZT4gYW5kIHZpZXcgYWxsIG9wZW4gPEpvYnNQYWdlPmpvYnM8L0pvYnNQYWdlPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIG91dCBtb3JlIGFib3V0IHdvcmtpbmcgYXQgY29tcGFueVByb2ZpbGVNb2RlbE5hbWUuIFJlYWQgY29tcGFueSByZXZpZXdzIGZyb20gcmVhbCBlbXBsb3llZXMsIGV4cGxvcmUgbGlmZSBhbmQgY3VsdHVyZSBhbmQgdmlldyBhbGwgb3BlbiBqb2JzLiI6IkZpbmQgb3V0IG1vcmUgYWJvdXQgd29ya2luZyBhdCB7Y29tcGFueVByb2ZpbGVNb2RlbE5hbWV9LiBSZWFkIGNvbXBhbnkgPFJldmlld3NQYWdlPnJldmlld3M8L1Jldmlld3NQYWdlPiBmcm9tIHJlYWwgZW1wbG95ZWVzLCBleHBsb3JlIDxDdWx0dXJlUGFnZT5saWZlIGFuZCBjdWx0dXJlPC9DdWx0dXJlUGFnZT4gYW5kIHZpZXcgYWxsIG9wZW4gPEpvYnNQYWdlPmpvYnM8L0pvYnNQYWdlPi4ifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIG91dCBtb3JlIGFib3V0IHdvcmtpbmcgYXQgY29tcGFueVByb2ZpbGVNb2RlbE5hbWUuIFJlYWQgY29tcGFueSByZXZpZXdzIGZyb20gcmVhbCBlbXBsb3llZXMsIGV4cGxvcmUgbGlmZSBhbmQgY3VsdHVyZSBhbmQgdmlldyBhbGwgb3BlbiBqb2JzLiI6IkZpbmQgb3V0IG1vcmUgYWJvdXQgd29ya2luZyBhdCB7Y29tcGFueVByb2ZpbGVNb2RlbE5hbWV9LiBSZWFkIGNvbXBhbnkgPFJldmlld3NQYWdlPnJldmlld3M8L1Jldmlld3NQYWdlPiBmcm9tIHJlYWwgZW1wbG95ZWVzLCBleHBsb3JlIDxDdWx0dXJlUGFnZT5saWZlIGFuZCBjdWx0dXJlPC9DdWx0dXJlUGFnZT4gYW5kIHZpZXcgYWxsIG9wZW4gPEpvYnNQYWdlPmpvYnM8L0pvYnNQYWdlPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIG91dCBtb3JlIGFib3V0IHdvcmtpbmcgYXQgY29tcGFueVByb2ZpbGVNb2RlbE5hbWUuIFJlYWQgY29tcGFueSByZXZpZXdzIGZyb20gcmVhbCBlbXBsb3llZXMsIGV4cGxvcmUgbGlmZSBhbmQgY3VsdHVyZSBhbmQgdmlldyBhbGwgb3BlbiBqb2JzLiI6IkZpbmQgb3V0IG1vcmUgYWJvdXQgd29ya2luZyBhdCB7Y29tcGFueVByb2ZpbGVNb2RlbE5hbWV9LiBSZWFkIGNvbXBhbnkgPFJldmlld3NQYWdlPnJldmlld3M8L1Jldmlld3NQYWdlPiBmcm9tIHJlYWwgZW1wbG95ZWVzLCBleHBsb3JlIDxDdWx0dXJlUGFnZT5saWZlIGFuZCBjdWx0dXJlPC9DdWx0dXJlUGFnZT4gYW5kIHZpZXcgYWxsIG9wZW4gPEpvYnNQYWdlPmpvYnM8L0pvYnNQYWdlPi4ifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIG91dCBtb3JlIGFib3V0IHdvcmtpbmcgYXQgY29tcGFueVByb2ZpbGVNb2RlbE5hbWUuIFJlYWQgY29tcGFueSByZXZpZXdzIGZyb20gcmVhbCBlbXBsb3llZXMsIGV4cGxvcmUgbGlmZSBhbmQgY3VsdHVyZSBhbmQgdmlldyBhbGwgb3BlbiBqb2JzLiI6IkZpbmQgb3V0IG1vcmUgYWJvdXQgd29ya2luZyBhdCB7Y29tcGFueVByb2ZpbGVNb2RlbE5hbWV9LiBSZWFkIGNvbXBhbnkgPFJldmlld3NQYWdlPnJldmlld3M8L1Jldmlld3NQYWdlPiBmcm9tIHJlYWwgZW1wbG95ZWVzLCBleHBsb3JlIDxDdWx0dXJlUGFnZT5saWZlIGFuZCBjdWx0dXJlPC9DdWx0dXJlUGFnZT4gYW5kIHZpZXcgYWxsIG9wZW4gPEpvYnNQYWdlPmpvYnM8L0pvYnNQYWdlPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIG91dCBtb3JlIGFib3V0IHdvcmtpbmcgYXQgY29tcGFueVByb2ZpbGVNb2RlbE5hbWUuIFJlYWQgY29tcGFueSByZXZpZXdzIGZyb20gcmVhbCBlbXBsb3llZXMsIGV4cGxvcmUgbGlmZSBhbmQgY3VsdHVyZSBhbmQgdmlldyBhbGwgb3BlbiBqb2JzLiI6IkZpbmQgb3V0IG1vcmUgYWJvdXQgd29ya2luZyBhdCB7Y29tcGFueVByb2ZpbGVNb2RlbE5hbWV9LiBSZWFkIGNvbXBhbnkgPFJldmlld3NQYWdlPnJldmlld3M8L1Jldmlld3NQYWdlPiBmcm9tIHJlYWwgZW1wbG95ZWVzLCBleHBsb3JlIDxDdWx0dXJlUGFnZT5saWZlIGFuZCBjdWx0dXJlPC9DdWx0dXJlUGFnZT4gYW5kIHZpZXcgYWxsIG9wZW4gPEpvYnNQYWdlPmpvYnM8L0pvYnNQYWdlPi4ifQ==!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIG91dCBtb3JlIGFib3V0IHdvcmtpbmcgYXQgY29tcGFueVByb2ZpbGVNb2RlbE5hbWUuIFJlYWQgY29tcGFueSByZXZpZXdzIGZyb20gcmVhbCBlbXBsb3llZXMsIGV4cGxvcmUgbGlmZSBhbmQgY3VsdHVyZSBhbmQgdmlldyBhbGwgb3BlbiBqb2JzLiI6IkZpbmQgb3V0IG1vcmUgYWJvdXQgd29ya2luZyBhdCB7Y29tcGFueVByb2ZpbGVNb2RlbE5hbWV9LiBSZWFkIGNvbXBhbnkgPFJldmlld3NQYWdlPnJldmlld3M8L1Jldmlld3NQYWdlPiBmcm9tIHJlYWwgZW1wbG95ZWVzLCBleHBsb3JlIDxDdWx0dXJlUGFnZT5saWZlIGFuZCBjdWx0dXJlPC9DdWx0dXJlUGFnZT4gYW5kIHZpZXcgYWxsIG9wZW4gPEpvYnNQYWdlPmpvYnM8L0pvYnNQYWdlPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIG91dCBtb3JlIGFib3V0IHdvcmtpbmcgYXQgY29tcGFueVByb2ZpbGVNb2RlbE5hbWUuIFJlYWQgY29tcGFueSByZXZpZXdzIGZyb20gcmVhbCBlbXBsb3llZXMsIGV4cGxvcmUgbGlmZSBhbmQgY3VsdHVyZSBhbmQgdmlldyBhbGwgb3BlbiBqb2JzLiI6IkZpbmQgb3V0IG1vcmUgYWJvdXQgd29ya2luZyBhdCB7Y29tcGFueVByb2ZpbGVNb2RlbE5hbWV9LiBSZWFkIGNvbXBhbnkgPFJldmlld3NQYWdlPnJldmlld3M8L1Jldmlld3NQYWdlPiBmcm9tIHJlYWwgZW1wbG95ZWVzLCBleHBsb3JlIDxDdWx0dXJlUGFnZT5saWZlIGFuZCBjdWx0dXJlPC9DdWx0dXJlUGFnZT4gYW5kIHZpZXcgYWxsIG9wZW4gPEpvYnNQYWdlPmpvYnM8L0pvYnNQYWdlPi4ifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIG91dCBtb3JlIGFib3V0IHdvcmtpbmcgYXQgY29tcGFueVByb2ZpbGVNb2RlbE5hbWUuIFJlYWQgY29tcGFueSByZXZpZXdzIGZyb20gcmVhbCBlbXBsb3llZXMsIGV4cGxvcmUgbGlmZSBhbmQgY3VsdHVyZSBhbmQgdmlldyBhbGwgb3BlbiBqb2JzLiI6IkZpbmQgb3V0IG1vcmUgYWJvdXQgd29ya2luZyBhdCB7Y29tcGFueVByb2ZpbGVNb2RlbE5hbWV9LiBSZWFkIGNvbXBhbnkgPFJldmlld3NQYWdlPnJldmlld3M8L1Jldmlld3NQYWdlPiBmcm9tIHJlYWwgZW1wbG95ZWVzLCBleHBsb3JlIDxDdWx0dXJlUGFnZT5saWZlIGFuZCBjdWx0dXJlPC9DdWx0dXJlUGFnZT4gYW5kIHZpZXcgYWxsIG9wZW4gPEpvYnNQYWdlPmpvYnM8L0pvYnNQYWdlPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIG91dCBtb3JlIGFib3V0IHdvcmtpbmcgYXQgY29tcGFueVByb2ZpbGVNb2RlbE5hbWUuIFJlYWQgY29tcGFueSByZXZpZXdzIGZyb20gcmVhbCBlbXBsb3llZXMsIGV4cGxvcmUgbGlmZSBhbmQgY3VsdHVyZSBhbmQgdmlldyBhbGwgb3BlbiBqb2JzLiI6IkZpbmQgb3V0IG1vcmUgYWJvdXQgd29ya2luZyBhdCB7Y29tcGFueVByb2ZpbGVNb2RlbE5hbWV9LiBSZWFkIGNvbXBhbnkgPFJldmlld3NQYWdlPnJldmlld3M8L1Jldmlld3NQYWdlPiBmcm9tIHJlYWwgZW1wbG95ZWVzLCBleHBsb3JlIDxDdWx0dXJlUGFnZT5saWZlIGFuZCBjdWx0dXJlPC9DdWx0dXJlUGFnZT4gYW5kIHZpZXcgYWxsIG9wZW4gPEpvYnNQYWdlPmpvYnM8L0pvYnNQYWdlPi4ifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIG91dCBtb3JlIGFib3V0IHdvcmtpbmcgYXQgY29tcGFueVByb2ZpbGVNb2RlbE5hbWUuIFJlYWQgY29tcGFueSByZXZpZXdzIGZyb20gcmVhbCBlbXBsb3llZXMsIGV4cGxvcmUgbGlmZSBhbmQgY3VsdHVyZSBhbmQgdmlldyBhbGwgb3BlbiBqb2JzLiI6IkZpbmQgb3V0IG1vcmUgYWJvdXQgd29ya2luZyBhdCB7Y29tcGFueVByb2ZpbGVNb2RlbE5hbWV9LiBSZWFkIGNvbXBhbnkgPFJldmlld3NQYWdlPnJldmlld3M8L1Jldmlld3NQYWdlPiBmcm9tIHJlYWwgZW1wbG95ZWVzLCBleHBsb3JlIDxDdWx0dXJlUGFnZT5saWZlIGFuZCBjdWx0dXJlPC9DdWx0dXJlUGFnZT4gYW5kIHZpZXcgYWxsIG9wZW4gPEpvYnNQYWdlPmpvYnM8L0pvYnNQYWdlPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIG91dCBtb3JlIGFib3V0IHdvcmtpbmcgYXQgY29tcGFueVByb2ZpbGVNb2RlbE5hbWUuIFJlYWQgY29tcGFueSByZXZpZXdzIGZyb20gcmVhbCBlbXBsb3llZXMsIGV4cGxvcmUgbGlmZSBhbmQgY3VsdHVyZSBhbmQgdmlldyBhbGwgb3BlbiBqb2JzLiI6IkZpbmQgb3V0IG1vcmUgYWJvdXQgd29ya2luZyBhdCB7Y29tcGFueVByb2ZpbGVNb2RlbE5hbWV9LiBSZWFkIGNvbXBhbnkgPFJldmlld3NQYWdlPnJldmlld3M8L1Jldmlld3NQYWdlPiBmcm9tIHJlYWwgZW1wbG95ZWVzLCBleHBsb3JlIDxDdWx0dXJlUGFnZT5saWZlIGFuZCBjdWx0dXJlPC9DdWx0dXJlUGFnZT4gYW5kIHZpZXcgYWxsIG9wZW4gPEpvYnNQYWdlPmpvYnM8L0pvYnNQYWdlPi4ifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIG91dCBtb3JlIGFib3V0IHdvcmtpbmcgYXQgY29tcGFueVByb2ZpbGVNb2RlbE5hbWUuIFJlYWQgY29tcGFueSByZXZpZXdzIGZyb20gcmVhbCBlbXBsb3llZXMsIGV4cGxvcmUgbGlmZSBhbmQgY3VsdHVyZSBhbmQgdmlldyBhbGwgb3BlbiBqb2JzLiI6IkZpbmQgb3V0IG1vcmUgYWJvdXQgd29ya2luZyBhdCB7Y29tcGFueVByb2ZpbGVNb2RlbE5hbWV9LiBSZWFkIGNvbXBhbnkgPFJldmlld3NQYWdlPnJldmlld3M8L1Jldmlld3NQYWdlPiBmcm9tIHJlYWwgZW1wbG95ZWVzLCBleHBsb3JlIDxDdWx0dXJlUGFnZT5saWZlIGFuZCBjdWx0dXJlPC9DdWx0dXJlUGFnZT4gYW5kIHZpZXcgYWxsIG9wZW4gPEpvYnNQYWdlPmpvYnM8L0pvYnNQYWdlPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIG91dCBtb3JlIGFib3V0IHdvcmtpbmcgYXQgY29tcGFueVByb2ZpbGVNb2RlbE5hbWUuIFJlYWQgY29tcGFueSByZXZpZXdzIGZyb20gcmVhbCBlbXBsb3llZXMsIGV4cGxvcmUgbGlmZSBhbmQgY3VsdHVyZSBhbmQgdmlldyBhbGwgb3BlbiBqb2JzLiI6IkZpbmQgb3V0IG1vcmUgYWJvdXQgd29ya2luZyBhdCB7Y29tcGFueVByb2ZpbGVNb2RlbE5hbWV9LiBSZWFkIGNvbXBhbnkgPFJldmlld3NQYWdlPnJldmlld3M8L1Jldmlld3NQYWdlPiBmcm9tIHJlYWwgZW1wbG95ZWVzLCBleHBsb3JlIDxDdWx0dXJlUGFnZT5saWZlIGFuZCBjdWx0dXJlPC9DdWx0dXJlUGFnZT4gYW5kIHZpZXcgYWxsIG9wZW4gPEpvYnNQYWdlPmpvYnM8L0pvYnNQYWdlPi4ifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIG91dCBtb3JlIGFib3V0IHdvcmtpbmcgYXQgY29tcGFueVByb2ZpbGVNb2RlbE5hbWUuIFJlYWQgY29tcGFueSByZXZpZXdzIGZyb20gcmVhbCBlbXBsb3llZXMsIGV4cGxvcmUgbGlmZSBhbmQgY3VsdHVyZSBhbmQgdmlldyBhbGwgb3BlbiBqb2JzLiI6IkZpbmQgb3V0IG1vcmUgYWJvdXQgd29ya2luZyBhdCB7Y29tcGFueVByb2ZpbGVNb2RlbE5hbWV9LiBSZWFkIGNvbXBhbnkgPFJldmlld3NQYWdlPnJldmlld3M8L1Jldmlld3NQYWdlPiBmcm9tIHJlYWwgZW1wbG95ZWVzLCBleHBsb3JlIDxDdWx0dXJlUGFnZT5saWZlIGFuZCBjdWx0dXJlPC9DdWx0dXJlUGFnZT4gYW5kIHZpZXcgYWxsIG9wZW4gPEpvYnNQYWdlPmpvYnM8L0pvYnNQYWdlPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIG91dCBtb3JlIGFib3V0IHdvcmtpbmcgYXQgY29tcGFueVByb2ZpbGVNb2RlbE5hbWUuIFJlYWQgY29tcGFueSByZXZpZXdzIGZyb20gcmVhbCBlbXBsb3llZXMsIGV4cGxvcmUgbGlmZSBhbmQgY3VsdHVyZSBhbmQgdmlldyBhbGwgb3BlbiBqb2JzLiI6IkZpbmQgb3V0IG1vcmUgYWJvdXQgd29ya2luZyBhdCB7Y29tcGFueVByb2ZpbGVNb2RlbE5hbWV9LiBSZWFkIGNvbXBhbnkgPFJldmlld3NQYWdlPnJldmlld3M8L1Jldmlld3NQYWdlPiBmcm9tIHJlYWwgZW1wbG95ZWVzLCBleHBsb3JlIDxDdWx0dXJlUGFnZT5saWZlIGFuZCBjdWx0dXJlPC9DdWx0dXJlUGFnZT4gYW5kIHZpZXcgYWxsIG9wZW4gPEpvYnNQYWdlPmpvYnM8L0pvYnNQYWdlPi4ifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIG91dCBtb3JlIGFib3V0IHdvcmtpbmcgYXQgY29tcGFueVByb2ZpbGVNb2RlbE5hbWUuIFJlYWQgY29tcGFueSByZXZpZXdzIGZyb20gcmVhbCBlbXBsb3llZXMsIGV4cGxvcmUgbGlmZSBhbmQgY3VsdHVyZSBhbmQgdmlldyBhbGwgb3BlbiBqb2JzLiI6IkZpbmQgb3V0IG1vcmUgYWJvdXQgd29ya2luZyBhdCB7Y29tcGFueVByb2ZpbGVNb2RlbE5hbWV9LiBSZWFkIGNvbXBhbnkgPFJldmlld3NQYWdlPnJldmlld3M8L1Jldmlld3NQYWdlPiBmcm9tIHJlYWwgZW1wbG95ZWVzLCBleHBsb3JlIDxDdWx0dXJlUGFnZT5saWZlIGFuZCBjdWx0dXJlPC9DdWx0dXJlUGFnZT4gYW5kIHZpZXcgYWxsIG9wZW4gPEpvYnNQYWdlPmpvYnM8L0pvYnNQYWdlPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIG91dCBtb3JlIGFib3V0IHdvcmtpbmcgYXQgY29tcGFueVByb2ZpbGVNb2RlbE5hbWUuIFJlYWQgY29tcGFueSByZXZpZXdzIGZyb20gcmVhbCBlbXBsb3llZXMsIGV4cGxvcmUgbGlmZSBhbmQgY3VsdHVyZSBhbmQgdmlldyBhbGwgb3BlbiBqb2JzLiI6IkZpbmQgb3V0IG1vcmUgYWJvdXQgd29ya2luZyBhdCB7Y29tcGFueVByb2ZpbGVNb2RlbE5hbWV9LiBSZWFkIGNvbXBhbnkgPFJldmlld3NQYWdlPnJldmlld3M8L1Jldmlld3NQYWdlPiBmcm9tIHJlYWwgZW1wbG95ZWVzLCBleHBsb3JlIDxDdWx0dXJlUGFnZT5saWZlIGFuZCBjdWx0dXJlPC9DdWx0dXJlUGFnZT4gYW5kIHZpZXcgYWxsIG9wZW4gPEpvYnNQYWdlPmpvYnM8L0pvYnNQYWdlPi4ifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIG91dCBtb3JlIGFib3V0IHdvcmtpbmcgYXQgY29tcGFueVByb2ZpbGVNb2RlbE5hbWUuIFJlYWQgY29tcGFueSByZXZpZXdzIGZyb20gcmVhbCBlbXBsb3llZXMsIGV4cGxvcmUgbGlmZSBhbmQgY3VsdHVyZSBhbmQgdmlldyBhbGwgb3BlbiBqb2JzLiI6IkZpbmQgb3V0IG1vcmUgYWJvdXQgd29ya2luZyBhdCB7Y29tcGFueVByb2ZpbGVNb2RlbE5hbWV9LiBSZWFkIGNvbXBhbnkgPFJldmlld3NQYWdlPnJldmlld3M8L1Jldmlld3NQYWdlPiBmcm9tIHJlYWwgZW1wbG95ZWVzLCBleHBsb3JlIDxDdWx0dXJlUGFnZT5saWZlIGFuZCBjdWx0dXJlPC9DdWx0dXJlUGFnZT4gYW5kIHZpZXcgYWxsIG9wZW4gPEpvYnNQYWdlPmpvYnM8L0pvYnNQYWdlPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIG91dCBtb3JlIGFib3V0IHdvcmtpbmcgYXQgY29tcGFueVByb2ZpbGVNb2RlbE5hbWUuIFJlYWQgY29tcGFueSByZXZpZXdzIGZyb20gcmVhbCBlbXBsb3llZXMsIGV4cGxvcmUgbGlmZSBhbmQgY3VsdHVyZSBhbmQgdmlldyBhbGwgb3BlbiBqb2JzLiI6IkZpbmQgb3V0IG1vcmUgYWJvdXQgd29ya2luZyBhdCB7Y29tcGFueVByb2ZpbGVNb2RlbE5hbWV9LiBSZWFkIGNvbXBhbnkgPFJldmlld3NQYWdlPnJldmlld3M8L1Jldmlld3NQYWdlPiBmcm9tIHJlYWwgZW1wbG95ZWVzLCBleHBsb3JlIDxDdWx0dXJlUGFnZT5saWZlIGFuZCBjdWx0dXJlPC9DdWx0dXJlUGFnZT4gYW5kIHZpZXcgYWxsIG9wZW4gPEpvYnNQYWdlPmpvYnM8L0pvYnNQYWdlPi4ifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIG91dCBtb3JlIGFib3V0IHdvcmtpbmcgYXQgY29tcGFueVByb2ZpbGVNb2RlbE5hbWUuIFJlYWQgY29tcGFueSByZXZpZXdzIGZyb20gcmVhbCBlbXBsb3llZXMsIGV4cGxvcmUgbGlmZSBhbmQgY3VsdHVyZSBhbmQgdmlldyBhbGwgb3BlbiBqb2JzLiI6IkZpbmQgb3V0IG1vcmUgYWJvdXQgd29ya2luZyBhdCB7Y29tcGFueVByb2ZpbGVNb2RlbE5hbWV9LiBSZWFkIGNvbXBhbnkgPFJldmlld3NQYWdlPnJldmlld3M8L1Jldmlld3NQYWdlPiBmcm9tIHJlYWwgZW1wbG95ZWVzLCBleHBsb3JlIDxDdWx0dXJlUGFnZT5saWZlIGFuZCBjdWx0dXJlPC9DdWx0dXJlUGFnZT4gYW5kIHZpZXcgYWxsIG9wZW4gPEpvYnNQYWdlPmpvYnM8L0pvYnNQYWdlPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIG91dCBtb3JlIGFib3V0IHdvcmtpbmcgYXQgY29tcGFueVByb2ZpbGVNb2RlbE5hbWUuIFJlYWQgY29tcGFueSByZXZpZXdzIGZyb20gcmVhbCBlbXBsb3llZXMsIGV4cGxvcmUgbGlmZSBhbmQgY3VsdHVyZSBhbmQgdmlldyBhbGwgb3BlbiBqb2JzLiI6IkZpbmQgb3V0IG1vcmUgYWJvdXQgd29ya2luZyBhdCB7Y29tcGFueVByb2ZpbGVNb2RlbE5hbWV9LiBSZWFkIGNvbXBhbnkgPFJldmlld3NQYWdlPnJldmlld3M8L1Jldmlld3NQYWdlPiBmcm9tIHJlYWwgZW1wbG95ZWVzLCBleHBsb3JlIDxDdWx0dXJlUGFnZT5saWZlIGFuZCBjdWx0dXJlPC9DdWx0dXJlUGFnZT4gYW5kIHZpZXcgYWxsIG9wZW4gPEpvYnNQYWdlPmpvYnM8L0pvYnNQYWdlPi4ifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIG91dCBtb3JlIGFib3V0IHdvcmtpbmcgYXQgY29tcGFueVByb2ZpbGVNb2RlbE5hbWUuIFJlYWQgY29tcGFueSByZXZpZXdzIGZyb20gcmVhbCBlbXBsb3llZXMsIGV4cGxvcmUgbGlmZSBhbmQgY3VsdHVyZSBhbmQgdmlldyBhbGwgb3BlbiBqb2JzLiI6IkZpbmQgb3V0IG1vcmUgYWJvdXQgd29ya2luZyBhdCB7Y29tcGFueVByb2ZpbGVNb2RlbE5hbWV9LiBSZWFkIGNvbXBhbnkgPFJldmlld3NQYWdlPnJldmlld3M8L1Jldmlld3NQYWdlPiBmcm9tIHJlYWwgZW1wbG95ZWVzLCBleHBsb3JlIDxDdWx0dXJlUGFnZT5saWZlIGFuZCBjdWx0dXJlPC9DdWx0dXJlUGFnZT4gYW5kIHZpZXcgYWxsIG9wZW4gPEpvYnNQYWdlPmpvYnM8L0pvYnNQYWdlPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIG91dCBtb3JlIGFib3V0IHdvcmtpbmcgYXQgY29tcGFueVByb2ZpbGVNb2RlbE5hbWUuIFJlYWQgY29tcGFueSByZXZpZXdzIGZyb20gcmVhbCBlbXBsb3llZXMsIGV4cGxvcmUgbGlmZSBhbmQgY3VsdHVyZSBhbmQgdmlldyBhbGwgb3BlbiBqb2JzLiI6IkZpbmQgb3V0IG1vcmUgYWJvdXQgd29ya2luZyBhdCB7Y29tcGFueVByb2ZpbGVNb2RlbE5hbWV9LiBSZWFkIGNvbXBhbnkgPFJldmlld3NQYWdlPnJldmlld3M8L1Jldmlld3NQYWdlPiBmcm9tIHJlYWwgZW1wbG95ZWVzLCBleHBsb3JlIDxDdWx0dXJlUGFnZT5saWZlIGFuZCBjdWx0dXJlPC9DdWx0dXJlUGFnZT4gYW5kIHZpZXcgYWxsIG9wZW4gPEpvYnNQYWdlPmpvYnM8L0pvYnNQYWdlPi4ifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIG91dCBtb3JlIGFib3V0IHdvcmtpbmcgYXQgY29tcGFueVByb2ZpbGVNb2RlbE5hbWUuIFJlYWQgY29tcGFueSByZXZpZXdzIGZyb20gcmVhbCBlbXBsb3llZXMsIGV4cGxvcmUgbGlmZSBhbmQgY3VsdHVyZSBhbmQgdmlldyBhbGwgb3BlbiBqb2JzLiI6IkZpbmQgb3V0IG1vcmUgYWJvdXQgd29ya2luZyBhdCB7Y29tcGFueVByb2ZpbGVNb2RlbE5hbWV9LiBSZWFkIGNvbXBhbnkgPFJldmlld3NQYWdlPnJldmlld3M8L1Jldmlld3NQYWdlPiBmcm9tIHJlYWwgZW1wbG95ZWVzLCBleHBsb3JlIDxDdWx0dXJlUGFnZT5saWZlIGFuZCBjdWx0dXJlPC9DdWx0dXJlUGFnZT4gYW5kIHZpZXcgYWxsIG9wZW4gPEpvYnNQYWdlPmpvYnM8L0pvYnNQYWdlPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIG91dCBtb3JlIGFib3V0IHdvcmtpbmcgYXQgY29tcGFueVByb2ZpbGVNb2RlbE5hbWUuIFJlYWQgY29tcGFueSByZXZpZXdzIGZyb20gcmVhbCBlbXBsb3llZXMsIGV4cGxvcmUgbGlmZSBhbmQgY3VsdHVyZSBhbmQgdmlldyBhbGwgb3BlbiBqb2JzLiI6IkZpbmQgb3V0IG1vcmUgYWJvdXQgd29ya2luZyBhdCB7Y29tcGFueVByb2ZpbGVNb2RlbE5hbWV9LiBSZWFkIGNvbXBhbnkgPFJldmlld3NQYWdlPnJldmlld3M8L1Jldmlld3NQYWdlPiBmcm9tIHJlYWwgZW1wbG95ZWVzLCBleHBsb3JlIDxDdWx0dXJlUGFnZT5saWZlIGFuZCBjdWx0dXJlPC9DdWx0dXJlUGFnZT4gYW5kIHZpZXcgYWxsIG9wZW4gPEpvYnNQYWdlPmpvYnM8L0pvYnNQYWdlPi4ifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIG91dCBtb3JlIGFib3V0IHdvcmtpbmcgYXQgY29tcGFueVByb2ZpbGVNb2RlbE5hbWUuIFJlYWQgY29tcGFueSByZXZpZXdzIGZyb20gcmVhbCBlbXBsb3llZXMsIGV4cGxvcmUgbGlmZSBhbmQgY3VsdHVyZSBhbmQgdmlldyBhbGwgb3BlbiBqb2JzLiI6IkZpbmQgb3V0IG1vcmUgYWJvdXQgd29ya2luZyBhdCB7Y29tcGFueVByb2ZpbGVNb2RlbE5hbWV9LiBSZWFkIGNvbXBhbnkgPFJldmlld3NQYWdlPnJldmlld3M8L1Jldmlld3NQYWdlPiBmcm9tIHJlYWwgZW1wbG95ZWVzLCBleHBsb3JlIDxDdWx0dXJlUGFnZT5saWZlIGFuZCBjdWx0dXJlPC9DdWx0dXJlUGFnZT4gYW5kIHZpZXcgYWxsIG9wZW4gPEpvYnNQYWdlPmpvYnM8L0pvYnNQYWdlPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIG91dCBtb3JlIGFib3V0IHdvcmtpbmcgYXQgY29tcGFueVByb2ZpbGVNb2RlbE5hbWUuIFJlYWQgY29tcGFueSByZXZpZXdzIGZyb20gcmVhbCBlbXBsb3llZXMsIGV4cGxvcmUgbGlmZSBhbmQgY3VsdHVyZSBhbmQgdmlldyBhbGwgb3BlbiBqb2JzLiI6IkZpbmQgb3V0IG1vcmUgYWJvdXQgd29ya2luZyBhdCB7Y29tcGFueVByb2ZpbGVNb2RlbE5hbWV9LiBSZWFkIGNvbXBhbnkgPFJldmlld3NQYWdlPnJldmlld3M8L1Jldmlld3NQYWdlPiBmcm9tIHJlYWwgZW1wbG95ZWVzLCBleHBsb3JlIDxDdWx0dXJlUGFnZT5saWZlIGFuZCBjdWx0dXJlPC9DdWx0dXJlUGFnZT4gYW5kIHZpZXcgYWxsIG9wZW4gPEpvYnNQYWdlPmpvYnM8L0pvYnNQYWdlPi4ifQ==!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIG91dCBtb3JlIGFib3V0IHdvcmtpbmcgYXQgY29tcGFueVByb2ZpbGVNb2RlbE5hbWUuIFJlYWQgY29tcGFueSByZXZpZXdzIGZyb20gcmVhbCBlbXBsb3llZXMsIGV4cGxvcmUgbGlmZSBhbmQgY3VsdHVyZSBhbmQgdmlldyBhbGwgb3BlbiBqb2JzLiI6IkZpbmQgb3V0IG1vcmUgYWJvdXQgd29ya2luZyBhdCB7Y29tcGFueVByb2ZpbGVNb2RlbE5hbWV9LiBSZWFkIGNvbXBhbnkgPFJldmlld3NQYWdlPnJldmlld3M8L1Jldmlld3NQYWdlPiBmcm9tIHJlYWwgZW1wbG95ZWVzLCBleHBsb3JlIDxDdWx0dXJlUGFnZT5saWZlIGFuZCBjdWx0dXJlPC9DdWx0dXJlUGFnZT4gYW5kIHZpZXcgYWxsIG9wZW4gPEpvYnNQYWdlPmpvYnM8L0pvYnNQYWdlPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIG91dCBtb3JlIGFib3V0IHdvcmtpbmcgYXQgY29tcGFueVByb2ZpbGVNb2RlbE5hbWUuIFJlYWQgY29tcGFueSByZXZpZXdzIGZyb20gcmVhbCBlbXBsb3llZXMsIGV4cGxvcmUgbGlmZSBhbmQgY3VsdHVyZSBhbmQgdmlldyBhbGwgb3BlbiBqb2JzLiI6IkZpbmQgb3V0IG1vcmUgYWJvdXQgd29ya2luZyBhdCB7Y29tcGFueVByb2ZpbGVNb2RlbE5hbWV9LiBSZWFkIGNvbXBhbnkgPFJldmlld3NQYWdlPnJldmlld3M8L1Jldmlld3NQYWdlPiBmcm9tIHJlYWwgZW1wbG95ZWVzLCBleHBsb3JlIDxDdWx0dXJlUGFnZT5saWZlIGFuZCBjdWx0dXJlPC9DdWx0dXJlUGFnZT4gYW5kIHZpZXcgYWxsIG9wZW4gPEpvYnNQYWdlPmpvYnM8L0pvYnNQYWdlPi4ifQ==!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIG91dCBtb3JlIGFib3V0IHdvcmtpbmcgYXQgY29tcGFueVByb2ZpbGVNb2RlbE5hbWUuIFJlYWQgY29tcGFueSByZXZpZXdzIGZyb20gcmVhbCBlbXBsb3llZXMsIGV4cGxvcmUgbGlmZSBhbmQgY3VsdHVyZSBhbmQgdmlldyBhbGwgb3BlbiBqb2JzLiI6IlvGkcOsw6zDrOC4geC4teC5icaMIMO2w7bDtseax5rHmuG5ryBtzILDtsO2w7bFmeG6veG6veG6vSDEg8SDxIPDn8O2w7bDtseax5rHmuG5ryDFtcO2w7bDtsWZxLfDrMOsw6zguIHguLXguYnEoyDEg8SDxIPhua8ge2NvbXBhbnlQcm9maWxlTW9kZWxOYW1lfS4gxZjhur3hur3hur3Eg8SDxIPGjCDDp8O2w7bDtm3MgsalxIPEg8SD4LiB4Li14LmJw73DvcO9IDxSZXZpZXdzUGFnZT7FmeG6veG6veG5vcOsw6zhur3hur3FtcWhPC9SZXZpZXdzUGFnZT4gxpLFmcO2w7bDtm3MgiDFmeG6veG6veG6vcSDxIPEg8aaIOG6veG6veG6vW3MgsalxprDtsO2w7bDvcO9w73hur3hur3hur3hur3hur3hur3FoSwg4bq94bq94bq96q2VxqXGmsO2w7bDtsWZ4bq94bq94bq9IDxDdWx0dXJlUGFnZT7GmsOsw6zDrMaS4bq94bq94bq9IMSDxIPEg+C4geC4teC5icaMIMOnx5rHmseaxprhua/Hmseax5rFmeG6veG6veG6vTwvQ3VsdHVyZVBhZ2U+IMSDxIPEg+C4geC4teC5icaMIOG5vcOsw6zDrOG6veG6veG6vcW1IMSDxIPEg8aaxpogw7bDtsO2xqXhur3hur3hur3guIHguLXguYkgPEpvYnNQYWdlPsS1w7bDtsO2w5/FoTwvSm9ic1BhZ2U+Ll0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGaW5kIG91dCBtb3JlIGFib3V0IHdvcmtpbmcgYXQgY29tcGFueVByb2ZpbGVNb2RlbE5hbWUuIFJlYWQgY29tcGFueSByZXZpZXdzIGZyb20gcmVhbCBlbXBsb3llZXMsIGV4cGxvcmUgbGlmZSBhbmQgY3VsdHVyZSBhbmQgdmlldyBhbGwgb3BlbiBqb2JzLiI6IlvGkcOsw6zDrOC4geC4teC5icaMIMO2w7bDtseax5rHmuG5ryBtzILDtsO2w7bFmeG6veG6veG6vSDEg8SDxIPDn8O2w7bDtseax5rHmuG5ryDFtcO2w7bDtsWZxLfDrMOsw6zguIHguLXguYnEoyDEg8SDxIPhua8ge2NvbXBhbnlQcm9maWxlTW9kZWxOYW1lfS4gxZjhur3hur3hur3Eg8SDxIPGjCDDp8O2w7bDtm3MgsalxIPEg8SD4LiB4Li14LmJw73DvcO9IDxSZXZpZXdzUGFnZT7FmeG6veG6veG5vcOsw6zhur3hur3FtcWhPC9SZXZpZXdzUGFnZT4gxpLFmcO2w7bDtm3MgiDFmeG6veG6veG6vcSDxIPEg8aaIOG6veG6veG6vW3MgsalxprDtsO2w7bDvcO9w73hur3hur3hur3hur3hur3hur3FoSwg4bq94bq94bq96q2VxqXGmsO2w7bDtsWZ4bq94bq94bq9IDxDdWx0dXJlUGFnZT7GmsOsw6zDrMaS4bq94bq94bq9IMSDxIPEg+C4geC4teC5icaMIMOnx5rHmseaxprhua/Hmseax5rFmeG6veG6veG6vTwvQ3VsdHVyZVBhZ2U+IMSDxIPEg+C4geC4teC5icaMIOG5vcOsw6zDrOG6veG6veG6vcW1IMSDxIPEg8aaxpogw7bDtsO2xqXhur3hur3hur3guIHguLXguYkgPEpvYnNQYWdlPsS1w7bDtsO2w5/FoTwvSm9ic1BhZ2U+Ll0ifQ==!"
        )
      )
      });
  
      export { translations as default };
    