import { useState } from 'react';
import {
  Actions,
  Alert,
  Box,
  Button,
  Dialog,
  Dropdown,
  Stack,
  Text,
  Textarea,
  TextLink,
} from 'braid-design-system';

import { createForm, type RenderParams } from '@seek/forms-ui';
import { badWords, notHttpUrl, disallowHtml, minLength } from '@seek/validators-js';
import { useTranslations } from '@vocab/react';

import { dropdownRequired } from './dropdownRequired';
import translations from './.vocab';
import { useConfig, buildCommunityGuidelinesURI } from '@seek/libs-shared';
import { FlagReviewPayload } from '../../shared/services/legacyCompanyProfileService/types';

interface FormValues {
  reasonSelection: string;
  issueDescription: string;
}

type FormProps = RenderParams<FormValues>;

interface ComponentProps extends FormProps, Props {}

const { FormProvider, useField } = createForm<FormValues>();

const Form = ({
  model: { companyId, reviewId },
  handleSubmit,
  isOpen = false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  displayThankYouBox = () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClose = () => {},
  flagReview,
}: ComponentProps) => {
  const [isInErrorState, setIsInErrorState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslations(translations);
  const { locale, zone, experience } = useConfig();

  const reportReasons = [
    { text: t('Spam / irrelevant content'), value: 'spam' },
    { text: t('Disagree with the point of view'), value: 'disagree' },
    { text: t('Contains personal information'), value: 'personal_info' },
    { text: t('Offensive and / or abusive'), value: 'offensive' },
    {
      text: t(
        'Contains potentially defamatory and / or business sensitive information',
      ),
      value: 'sensitive',
    },
    {
      text: t('Company response is inappropriate'),
      value: 'response_inappropriate',
    },
    { text: t('Content is biased or fraudulent'), value: 'bias' },
    { text: t('Other'), value: 'others' },
  ];

  const onSubmit = handleSubmit((formValues) => {
    const handlingSubmit = async () => {
      setIsInErrorState(false);
      setIsLoading(true);

      const response = await flagReview(companyId, reviewId, {
        reason: formValues.reasonSelection,
        details: formValues.issueDescription,
      });
      if (response) {
        displayThankYouBox();
      } else {
        setIsInErrorState(true);
      }
      setIsLoading(false);
    };
    handlingSubmit();
  });

  const reasonSelection = useField({
    id: 'reasonSelection',
    validators: [dropdownRequired(t('Please select a reason'))],
  });
  const issueDescription = useField({
    id: 'issueDescription',
    validators: [minLength, badWords, disallowHtml, notHttpUrl],
    validatorProps: { min: 20 },
  });

  return (
    <Dialog
      id="form"
      onClose={() => {
        setIsInErrorState(false);
        onClose();
      }}
      title={t('Help us understand what is happening')}
      open={isOpen}
      width="content"
    >
      <form onSubmit={onSubmit} data-testid="report-review-form">
        <Box style={{ maxWidth: '450px' }}>
          <Stack space="medium">
            <Dropdown
              id="InappropriateReasonDrodown"
              label={t('Why is it inappropriate?')}
              placeholder={t('Select a reason')}
              message={reasonSelection.errorMessage}
              onBlur={reasonSelection.onBlur}
              onChange={reasonSelection.onChange}
              tone={reasonSelection.valid ? 'neutral' : 'critical'}
              value={reasonSelection.value}
              data={{ testid: 'report-review-reason-selection' }}
            >
              {reportReasons.map((reason) => (
                <option key={reason.value} value={reason.value}>
                  {reason.text}
                </option>
              ))}
            </Dropdown>

            <Textarea
              id="IssueTextField"
              label={t('What was the issue with this review?')}
              placeholder={t('Describe the issue')}
              message={issueDescription.errorMessage}
              onBlur={issueDescription.onBlur}
              onChange={issueDescription.onChange}
              tone={issueDescription.valid ? 'neutral' : 'critical'}
              value={issueDescription.value}
              data={{ testid: 'report-review-issue-description' }}
            />

            <Text>
              {t(
                'By submitting this report you agree this is in good faith in the interest of upholding our',
              )}{' '}
              <TextLink href={buildCommunityGuidelinesURI({ locale, zone, experience })}>
                {t('community guidelines')}
              </TextLink>
              .
            </Text>

            <Actions>
              <Button
                loading={isLoading}
                type="submit"
                tone="formAccent"
                data={{
                  testid: 'report-review-submit',
                }}
              >
                {t('Submit')}
              </Button>
            </Actions>

            {isInErrorState ? (
              <Alert tone="critical">
                <Text>
                  {t(
                    "Sorry, we couldn't submit your report. Can we try again?",
                  )}
                </Text>
              </Alert>
            ) : null}
          </Stack>
        </Box>
      </form>
    </Dialog>
  );
};

interface ReportReviewFormModel {
  companyId: string;
  reviewId: string;
}

interface Props {
  model: ReportReviewFormModel;
  displayThankYouBox?: () => void;
  onClose?: () => void;
  isOpen?: boolean;
  flagReview: (
    companyId: string,
    reviewId: string,
    payload: FlagReviewPayload,
  ) => Promise<boolean>;
}

export const ReportReviewForm = ({
  model: { companyId, reviewId },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  displayThankYouBox = () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClose = () => {},
  isOpen = false,
  flagReview,
}: Props) => {
  const config = useConfig();
  return (
    <FormProvider
      initialValues={{ reasonSelection: '', issueDescription: '' }}
      language={config.language}
    >
      {(formProps) => (
        <Form
          {...formProps}
          model={{ companyId, reviewId }}
          displayThankYouBox={displayThankYouBox}
          isOpen={isOpen}
          onClose={onClose}
          flagReview={flagReview}
        />
      )}
    </FormProvider>
  );
};
