import type { Reviews } from '@seek/libs-shared';

export const getMockReviews = (companyId: string, page?: number): Reviews => ({
  name: `SEEK ${companyId}`,
  companyId,
  rating: {
    average: 4.5,
    count: 100,
  },
  reviews: [
    {
      title: `I love my job ${page}`,
      createdAt: '8 Aug 2015',
      pros: 'Carreer',
      cons: 'Technical renew every year here is more text. More text more text more text more text more text more text more text more text more text more text more text more text ',
      overallRating: 5,
      id: '215572',
      companyId,
      jobTitle: 'IT Integration Architect',
      employmentStatus: 'More than 12 years in the role, current employee',
      workLocation: 'Sao Paulo',
      salarySummary: 'Average',
      upvotes: {
        count: 3,
        description: '3 people found this helpful',
      },
      recommended: {
        description: 'Recommended',
        value: true,
      },
      ratings: [
        {
          rating: 5,
          description: 'Benefits & perks',
        },
        {
          rating: 4,
          description: 'Career development',
        },
        {
          rating: 5,
          description: 'Work/Life balance',
        },
        {
          rating: 4,
          description: 'Working environment',
        },
        {
          rating: 4,
          description: 'Management',
        },
        {
          rating: 5,
          description: 'Diversity & equal opportunity',
        },
      ],
      reply: {
        companyLogoUrl:
          'https://image-service-cdn.staging.seek.com.au/065e5cdc21f0bd6bdb63f543be9ea6db8bb92b70/f3c5292cec0e05e4272d9bf9146f390d366481d0',
        createdAt: '2022-07-05T06:38:43.645Z',
        from: 'Manager',
        text: 'Hello thanks for reviewing',
        user: {
          userId: 'userid123',
        },
      },
    },
    {
      title: 'challenges',
      createdAt: '8 Aug 2015',
      pros: 'Brand name',
      cons: 'Less opportunity to grow',
      overallRating: 3,
      id: '214776',
      companyId: '432311',
      jobTitle: 'TBO manager',
      salarySummary: 'Average',
      recommended: {
        description: 'Recommended',
        value: true,
      },
      ratings: [
        {
          rating: 3,
          description: 'Benefits & perks',
        },
        {
          rating: 3,
          description: 'Career development',
        },
        {
          rating: 3,
          description: 'Work/Life balance',
        },
        {
          rating: 4,
          description: 'Working environment',
        },
        {
          rating: 4,
          description: 'Management',
        },
        {
          rating: 4,
          description: 'Diversity & equal opportunity',
        },
      ],
      reply: {
        companyLogoUrl:
          'https://image-service-cdn.staging.seek.com.au/065e5cdc21f0bd6bdb63f543be9ea6db8bb92b70/f3c5292cec0e05e4272d9bf9146f390d366481d0',
        createdAt: '2024-01-11T12:38:43.645Z',
        from: 'Not the manager',
        text: 'Hello thanks for reviewing. Here is a reply from not the manager. Some extra text because this is a really long reply very good thank you for your time. More text more text more text more text more text more text more text.',
        user: {
          userId: 'userid123',
        },
      },
    },
    {
      title: 'Let you work to your best abilities',
      createdAt: '8 Aug 2015',
      pros: 'Work-life balance',
      cons: 'Access to mgmt',
      overallRating: 4,
      id: '212476',
      companyId: '432311',
      jobTitle: 'Cloud Architect',
      employmentStatus: '5 to 6 years in the role, current employee',
      workLocation: 'Melbourne, Victoria, Australia',
      salarySummary: 'High',
      recommended: {
        description: 'Recommended',
        value: true,
      },
      ratings: [
        {
          rating: 1,
          description: 'Benefits & perks',
        },
        {
          rating: 3,
          description: 'Career development',
        },
        {
          rating: 5,
          description: 'Work/Life balance',
        },
        {
          rating: 4,
          description: 'Working environment',
        },
        {
          rating: 3,
          description: 'Management',
        },
        {
          rating: 4,
          description: 'Diversity & equal opportunity',
        },
      ],
      reply: {
        companyLogoUrl:
          'https://image-service-cdn.staging.seek.com.au/065e5cdc21f0bd6bdb63f543be9ea6db8bb92b70/f3c5292cec0e05e4272d9bf9146f390d366481d0',
        createdAt: '2024-01-12T05:38:43.645Z',
        from: 'Staff',
        text: 'Good',
        user: { userId: 'userid123' },
      },
    },
    {
      title: 'Good place to work',
      createdAt: '2 Aug 2015',
      pros: 'flexibility in timings and open culture to discuss ideas',
      cons: "you can't always come up with creative ideas",
      overallRating: 4,
      id: '205163',
      companyId: '432311',
      jobTitle: 'Sr Business Analyst',
      employmentStatus: '1 to 2 years in the role, former employee',
      workLocation: 'Bangalore',
      salarySummary: 'High',
      recommended: {
        description: 'Recommended',
        value: true,
      },
      upvotes: {
        count: 1,
        description: '1 person found this helpful',
      },
      ratings: [
        {
          rating: 4,
          description: 'Benefits & perks',
        },
        {
          rating: 4,
          description: 'Career development',
        },
        {
          rating: 4,
          description: 'Work/Life balance',
        },
        {
          rating: 4,
          description: 'Working environment',
        },
        {
          rating: 4,
          description: 'Management',
        },
        {
          rating: 4,
          description: 'Diversity & equal opportunity',
        },
      ],
      reply: {
        companyLogoUrl:
          'https://image-service-cdn.staging.seek.com.au/065e5cdc21f0bd6bdb63f543be9ea6db8bb92b70/f3c5292cec0e05e4272d9bf9146f390d366481d0',
        createdAt: '2024-01-05T06:38:43.645Z',
        from: 'Bobby Bob Jane the manager',
        text: 'Yes very nice well done thanks',
        user: {
          userId: 'userid123',
        },
      },
    },
  ],
  paging: {
    page,
    perPage: 5,
    total: 3,
    totalPages: 1,
  },
});
