import type { RouteObject } from 'react-router-dom';

import { App } from './App';
import { createCompanyProfilesRoutes } from './companyProfiles/routes';
import type { RouteProps } from './shared/RouteProps';
import { VocabProvider } from '@vocab/react';
import { BraidProvider, Page } from 'braid-design-system';
import { ErrorBoundary } from '@seek/libs-shared';
import theme from 'braid-design-system/themes/seekJobs';
import {
  ApacCandidateFooter,
  ApacCandidateHeader,
} from '@seek/apac-candidate-header-footer';
import { AUTHENTICATION_TYPES } from '@seek/apac-candidate-header-footer/types';
import { MelwaysProvider } from '@seek/melways-react';

export const PATHS = {
  LANGUAGE: '/:language?',
};

export const createRoutes = (props: RouteProps): RouteObject[] => [
  {
    path: PATHS.LANGUAGE,
    errorElement: (
      <MelwaysProvider.Server
        site={props.config.siteName as any}
        url={props.config.absoluteUrl}
        alternative={props.config.consolidated ? 'subDomainRegion' : undefined}
        staging={props.config.environment === 'staging'}
      >
        <VocabProvider language={props.config.locale}>
          <BraidProvider theme={theme}>
            <Page
              footer={
                <ApacCandidateFooter
                  authenticationStatus={AUTHENTICATION_TYPES.AUTH_PENDING}
                  language={props.config.language}
                  brand={props.config.brand}
                  country={props.config.country}
                />
              }
              footerPosition="belowFold"
            >
              <ApacCandidateHeader
                authenticationStatus={AUTHENTICATION_TYPES.AUTH_PENDING}
                language={props.config.language}
                brand={props.config.brand}
                country={props.config.country}
              />
              <ErrorBoundary logger={props.logger} config={props.config} />
            </Page>
          </BraidProvider>
        </VocabProvider>
      </MelwaysProvider.Server>
    ),
    element: <App {...props} />,
    children: [...createCompanyProfilesRoutes(props)],
    id: 'candidate-root',
  },
];
