
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0=!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0=!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IuC4lOC4ueC4o+C4ueC4m+C4oOC4suC4nuC4l+C4seC5ieC4h+C4q+C4oeC4lCIsInBob3RvcyI6IuC4o+C4ueC4m+C4luC5iOC4suC4oiIsIkdhbGxlcnkiOiLguITguKXguLHguIfguKDguLLguJ4iLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IuC4quC4o+C5ieC4suC4h+C4hOC4p+C4suC4oeC5guC4lOC4lOC5gOC4lOC5iOC4meC5g+C4q+C5ieC4geC4seC4muC4quC4oOC4suC4nuC5geC4p+C4lOC4peC5ieC4reC4oeC5geC4peC4sOC4p+C4seC4kuC4meC4mOC4o+C4o+C4oeC5g+C4meC4geC4suC4o+C4l+C4s+C4h+C4suC4meC4guC4reC4h+C4hOC4uOC4kyIsIkdhbGxlcnkgdXBkYXRlZCI6IuC4reC4seC4m+C5gOC4lOC4leC5geC4geC4peC4reC4o+C4teC5geC4peC5ieC4pyJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IuC4lOC4ueC4o+C4ueC4m+C4oOC4suC4nuC4l+C4seC5ieC4h+C4q+C4oeC4lCIsInBob3RvcyI6IuC4o+C4ueC4m+C4luC5iOC4suC4oiIsIkdhbGxlcnkiOiLguITguKXguLHguIfguKDguLLguJ4iLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IuC4quC4o+C5ieC4suC4h+C4hOC4p+C4suC4oeC5guC4lOC4lOC5gOC4lOC5iOC4meC5g+C4q+C5ieC4geC4seC4muC4quC4oOC4suC4nuC5geC4p+C4lOC4peC5ieC4reC4oeC5geC4peC4sOC4p+C4seC4kuC4meC4mOC4o+C4o+C4oeC5g+C4meC4geC4suC4o+C4l+C4s+C4h+C4suC4meC4guC4reC4h+C4hOC4uOC4kyIsIkdhbGxlcnkgdXBkYXRlZCI6IuC4reC4seC4m+C5gOC4lOC4leC5geC4geC4peC4reC4o+C4teC5geC4peC5ieC4pyJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IkxpaGF0IHNlbXVhIGZvdG8iLCJwaG90b3MiOiJmb3RvIiwiR2FsbGVyeSI6IkdhbGVyaSIsIlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIjoiVHVuanVra2FuIGxpbmdrdW5nYW4gZGFuIGJ1ZGF5YSBrZXJqYSBwZXJ1c2FoYWFuIEFuZGEuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsZXJpIGRpcGVyYmFydWkifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IkxpaGF0IHNlbXVhIGZvdG8iLCJwaG90b3MiOiJmb3RvIiwiR2FsbGVyeSI6IkdhbGVyaSIsIlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIjoiVHVuanVra2FuIGxpbmdrdW5nYW4gZGFuIGJ1ZGF5YSBrZXJqYSBwZXJ1c2FoYWFuIEFuZGEuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsZXJpIGRpcGVyYmFydWkifQ==!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0=!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0=!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0=!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0=!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0=!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0=!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0=!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0=!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0=!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0=!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0=!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0=!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0=!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0=!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0=!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0=!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0=!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0=!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IuC4lOC4ueC4o+C4ueC4m+C4oOC4suC4nuC4l+C4seC5ieC4h+C4q+C4oeC4lCIsInBob3RvcyI6IuC4o+C4ueC4m+C4luC5iOC4suC4oiIsIkdhbGxlcnkiOiLguITguKXguLHguIfguKDguLLguJ4iLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IuC4quC4o+C5ieC4suC4h+C4hOC4p+C4suC4oeC5guC4lOC4lOC5gOC4lOC5iOC4meC5g+C4q+C5ieC4geC4seC4muC4quC4oOC4suC4nuC5geC4p+C4lOC4peC5ieC4reC4oeC5geC4peC4sOC4p+C4seC4kuC4meC4mOC4o+C4o+C4oeC5g+C4meC4geC4suC4o+C4l+C4s+C4h+C4suC4meC4guC4reC4h+C4hOC4uOC4kyIsIkdhbGxlcnkgdXBkYXRlZCI6IuC4reC4seC4m+C5gOC4lOC4leC5geC4geC4peC4reC4o+C4teC5geC4peC5ieC4pyJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IuC4lOC4ueC4o+C4ueC4m+C4oOC4suC4nuC4l+C4seC5ieC4h+C4q+C4oeC4lCIsInBob3RvcyI6IuC4o+C4ueC4m+C4luC5iOC4suC4oiIsIkdhbGxlcnkiOiLguITguKXguLHguIfguKDguLLguJ4iLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IuC4quC4o+C5ieC4suC4h+C4hOC4p+C4suC4oeC5guC4lOC4lOC5gOC4lOC5iOC4meC5g+C4q+C5ieC4geC4seC4muC4quC4oOC4suC4nuC5geC4p+C4lOC4peC5ieC4reC4oeC5geC4peC4sOC4p+C4seC4kuC4meC4mOC4o+C4o+C4oeC5g+C4meC4geC4suC4o+C4l+C4s+C4h+C4suC4meC4guC4reC4h+C4hOC4uOC4kyIsIkdhbGxlcnkgdXBkYXRlZCI6IuC4reC4seC4m+C5gOC4lOC4leC5geC4geC4peC4reC4o+C4teC5geC4peC5ieC4pyJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IkxpaGF0IHNlbXVhIGZvdG8iLCJwaG90b3MiOiJmb3RvIiwiR2FsbGVyeSI6IkdhbGVyaSIsIlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIjoiVHVuanVra2FuIGxpbmdrdW5nYW4gZGFuIGJ1ZGF5YSBrZXJqYSBwZXJ1c2FoYWFuIEFuZGEuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsZXJpIGRpcGVyYmFydWkifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IkxpaGF0IHNlbXVhIGZvdG8iLCJwaG90b3MiOiJmb3RvIiwiR2FsbGVyeSI6IkdhbGVyaSIsIlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIjoiVHVuanVra2FuIGxpbmdrdW5nYW4gZGFuIGJ1ZGF5YSBrZXJqYSBwZXJ1c2FoYWFuIEFuZGEuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsZXJpIGRpcGVyYmFydWkifQ==!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0=!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0=!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlvFoOG6veG6veG6veG6veG6veG6vSDEg8SDxIPGmsaaIMal4bipw7bDtsO24bmvw7bDtsO2xaFdIiwicGhvdG9zIjoiW8al4bipw7bDtsO24bmvw7bDtsO2xaFdIiwiR2FsbGVyeSI6IlvHpsSDxIPGmsaa4bq94bq9xZnDvcO9XSIsIlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIjoiW8Wg4bipw7bDtsO2xbXDp8SDxIPEg8Wh4bq94bq94bq9IMO9w73DvcO2w7bDtseax5rHmsWZIMOnw7bDtsO2bcyCxqXEg8SDxIPguIHguLXguYnDvcO9w70nxaEgxbXDtsO2w7bFmcS3IOG6veG6veG6veC4geC4teC5ieG5vcOsw6zDrMWZw7bDtsO24LiB4Li14LmJbcyC4bq94bq94bq94LiB4Li14LmJ4bmvIMSDxIPEg+C4geC4teC5icaMIMOnx5rHmseaxprhua/Hmseax5rFmeG6veG6veG6vS5dIiwiR2FsbGVyeSB1cGRhdGVkIjoiW8emxIPEg8aaxprhur3hur3FmcO9w70gx5rHmsalxozEg8SD4bmv4bq94bq9xoxdIn0=!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlvFoOG6veG6veG6veG6veG6veG6vSDEg8SDxIPGmsaaIMal4bipw7bDtsO24bmvw7bDtsO2xaFdIiwicGhvdG9zIjoiW8al4bipw7bDtsO24bmvw7bDtsO2xaFdIiwiR2FsbGVyeSI6IlvHpsSDxIPGmsaa4bq94bq9xZnDvcO9XSIsIlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIjoiW8Wg4bipw7bDtsO2xbXDp8SDxIPEg8Wh4bq94bq94bq9IMO9w73DvcO2w7bDtseax5rHmsWZIMOnw7bDtsO2bcyCxqXEg8SDxIPguIHguLXguYnDvcO9w70nxaEgxbXDtsO2w7bFmcS3IOG6veG6veG6veC4geC4teC5ieG5vcOsw6zDrMWZw7bDtsO24LiB4Li14LmJbcyC4bq94bq94bq94LiB4Li14LmJ4bmvIMSDxIPEg+C4geC4teC5icaMIMOnx5rHmseaxprhua/Hmseax5rFmeG6veG6veG6vS5dIiwiR2FsbGVyeSB1cGRhdGVkIjoiW8emxIPEg8aaxprhur3hur3FmcO9w70gx5rHmsalxozEg8SD4bmv4bq94bq9xoxdIn0=!"
        )
      )
      });
  
      export { translations as default };
    