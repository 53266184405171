
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZW5lZml0cyAmIHBlcmtzIjoiQmVuZWZpdHMgJiBwZXJrcyIsIkNhcmVlciBkZXZlbG9wbWVudCI6IkNhcmVlciBkZXZlbG9wbWVudCIsIldvcmsvTGlmZSBiYWxhbmNlIjoiV29yay9MaWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIk1hbmFnZW1lbnQiOiJNYW5hZ2VtZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZW5lZml0cyAmIHBlcmtzIjoiQmVuZWZpdHMgJiBwZXJrcyIsIkNhcmVlciBkZXZlbG9wbWVudCI6IkNhcmVlciBkZXZlbG9wbWVudCIsIldvcmsvTGlmZSBiYWxhbmNlIjoiV29yay9MaWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIk1hbmFnZW1lbnQiOiJNYW5hZ2VtZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZW5lZml0cyAmIHBlcmtzIjoi4Liq4Lin4Lix4Liq4LiU4Li04LiB4Liy4Lij4LmB4Lil4Liw4Lic4Lil4Lib4Lij4Liw4LmC4Lii4LiK4LiZ4LmMIiwiQ2FyZWVyIGRldmVsb3BtZW50Ijoi4LiE4Lin4Liy4Lih4LiB4LmJ4Liy4Lin4Lir4LiZ4LmJ4Liy4LiX4Liy4LiH4Lit4Liy4LiK4Li14LieIiwiV29yay9MaWZlIGJhbGFuY2UiOiLguYPguJnguYHguJnguKfguITguLTguJTguITguKfguLLguKHguKrguKHguJTguLjguKXguYPguJnguIrguLXguKfguLTguJXguYHguKXguLDguIHguLLguKPguJfguY3guLLguIfguLLguJnguILguK3guIfguYDguKPguLIiLCJXb3JraW5nIGVudmlyb25tZW50Ijoi4Lia4Lij4Lij4Lii4Liy4LiB4Liy4Lio4LmD4LiZ4LiB4Liy4Lij4LiX4Liz4LiH4Liy4LiZIiwiTWFuYWdlbWVudCI6IuC4geC4suC4o+C4iOC4seC4lOC4geC4suC4oyIsIkRpdmVyc2l0eSAmIGVxdWFsIG9wcG9ydHVuaXR5Ijoi4LiE4Lin4Liy4Lih4Lir4Lil4Liy4LiB4Lir4Lil4Liy4Lii4LmB4Lil4Liw4LmC4Lit4LiB4Liy4Liq4LiX4Li14LmI4LmA4LiX4LmI4Liy4LmA4LiX4Li14Lii4Lih4LiB4Lix4LiZIn0=!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZW5lZml0cyAmIHBlcmtzIjoi4Liq4Lin4Lix4Liq4LiU4Li04LiB4Liy4Lij4LmB4Lil4Liw4Lic4Lil4Lib4Lij4Liw4LmC4Lii4LiK4LiZ4LmMIiwiQ2FyZWVyIGRldmVsb3BtZW50Ijoi4LiE4Lin4Liy4Lih4LiB4LmJ4Liy4Lin4Lir4LiZ4LmJ4Liy4LiX4Liy4LiH4Lit4Liy4LiK4Li14LieIiwiV29yay9MaWZlIGJhbGFuY2UiOiLguYPguJnguYHguJnguKfguITguLTguJTguITguKfguLLguKHguKrguKHguJTguLjguKXguYPguJnguIrguLXguKfguLTguJXguYHguKXguLDguIHguLLguKPguJfguY3guLLguIfguLLguJnguILguK3guIfguYDguKPguLIiLCJXb3JraW5nIGVudmlyb25tZW50Ijoi4Lia4Lij4Lij4Lii4Liy4LiB4Liy4Lio4LmD4LiZ4LiB4Liy4Lij4LiX4Liz4LiH4Liy4LiZIiwiTWFuYWdlbWVudCI6IuC4geC4suC4o+C4iOC4seC4lOC4geC4suC4oyIsIkRpdmVyc2l0eSAmIGVxdWFsIG9wcG9ydHVuaXR5Ijoi4LiE4Lin4Liy4Lih4Lir4Lil4Liy4LiB4Lir4Lil4Liy4Lii4LmB4Lil4Liw4LmC4Lit4LiB4Liy4Liq4LiX4Li14LmI4LmA4LiX4LmI4Liy4LmA4LiX4Li14Lii4Lih4LiB4Lix4LiZIn0=!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZW5lZml0cyAmIHBlcmtzIjoiVHVuamFuZ2FuICYga2V1bnR1bmdhbiIsIkNhcmVlciBkZXZlbG9wbWVudCI6IlBlbmdlbWJhbmdhbiBrYXJpZXIiLCJXb3JrL0xpZmUgYmFsYW5jZSI6Iktlc2VpbWJhbmdhbiBoaWR1cCBkYW4gcGVrZXJqYWFuIiwiV29ya2luZyBlbnZpcm9ubWVudCI6IkxpbmdrdW5nYW4ga2VyamEiLCJNYW5hZ2VtZW50IjoiUGVuZ2Vsb2xhYW4iLCJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSI6IktlYmVyYWdhbWFuICYga2VzZW1wYXRhbiB5YW5nIHNhbWEifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZW5lZml0cyAmIHBlcmtzIjoiVHVuamFuZ2FuICYga2V1bnR1bmdhbiIsIkNhcmVlciBkZXZlbG9wbWVudCI6IlBlbmdlbWJhbmdhbiBrYXJpZXIiLCJXb3JrL0xpZmUgYmFsYW5jZSI6Iktlc2VpbWJhbmdhbiBoaWR1cCBkYW4gcGVrZXJqYWFuIiwiV29ya2luZyBlbnZpcm9ubWVudCI6IkxpbmdrdW5nYW4ga2VyamEiLCJNYW5hZ2VtZW50IjoiUGVuZ2Vsb2xhYW4iLCJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSI6IktlYmVyYWdhbWFuICYga2VzZW1wYXRhbiB5YW5nIHNhbWEifQ==!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZW5lZml0cyAmIHBlcmtzIjoiQmVuZWZpdHMgJiBwZXJrcyIsIkNhcmVlciBkZXZlbG9wbWVudCI6IkNhcmVlciBkZXZlbG9wbWVudCIsIldvcmsvTGlmZSBiYWxhbmNlIjoiV29yay9MaWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIk1hbmFnZW1lbnQiOiJNYW5hZ2VtZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZW5lZml0cyAmIHBlcmtzIjoiQmVuZWZpdHMgJiBwZXJrcyIsIkNhcmVlciBkZXZlbG9wbWVudCI6IkNhcmVlciBkZXZlbG9wbWVudCIsIldvcmsvTGlmZSBiYWxhbmNlIjoiV29yay9MaWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIk1hbmFnZW1lbnQiOiJNYW5hZ2VtZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZW5lZml0cyAmIHBlcmtzIjoiQmVuZWZpdHMgJiBwZXJrcyIsIkNhcmVlciBkZXZlbG9wbWVudCI6IkNhcmVlciBkZXZlbG9wbWVudCIsIldvcmsvTGlmZSBiYWxhbmNlIjoiV29yay9MaWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIk1hbmFnZW1lbnQiOiJNYW5hZ2VtZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZW5lZml0cyAmIHBlcmtzIjoiQmVuZWZpdHMgJiBwZXJrcyIsIkNhcmVlciBkZXZlbG9wbWVudCI6IkNhcmVlciBkZXZlbG9wbWVudCIsIldvcmsvTGlmZSBiYWxhbmNlIjoiV29yay9MaWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIk1hbmFnZW1lbnQiOiJNYW5hZ2VtZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZW5lZml0cyAmIHBlcmtzIjoiQmVuZWZpdHMgJiBwZXJrcyIsIkNhcmVlciBkZXZlbG9wbWVudCI6IkNhcmVlciBkZXZlbG9wbWVudCIsIldvcmsvTGlmZSBiYWxhbmNlIjoiV29yay9MaWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIk1hbmFnZW1lbnQiOiJNYW5hZ2VtZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZW5lZml0cyAmIHBlcmtzIjoiQmVuZWZpdHMgJiBwZXJrcyIsIkNhcmVlciBkZXZlbG9wbWVudCI6IkNhcmVlciBkZXZlbG9wbWVudCIsIldvcmsvTGlmZSBiYWxhbmNlIjoiV29yay9MaWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIk1hbmFnZW1lbnQiOiJNYW5hZ2VtZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZW5lZml0cyAmIHBlcmtzIjoiQmVuZWZpdHMgJiBwZXJrcyIsIkNhcmVlciBkZXZlbG9wbWVudCI6IkNhcmVlciBkZXZlbG9wbWVudCIsIldvcmsvTGlmZSBiYWxhbmNlIjoiV29yay9MaWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIk1hbmFnZW1lbnQiOiJNYW5hZ2VtZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZW5lZml0cyAmIHBlcmtzIjoiQmVuZWZpdHMgJiBwZXJrcyIsIkNhcmVlciBkZXZlbG9wbWVudCI6IkNhcmVlciBkZXZlbG9wbWVudCIsIldvcmsvTGlmZSBiYWxhbmNlIjoiV29yay9MaWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIk1hbmFnZW1lbnQiOiJNYW5hZ2VtZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZW5lZml0cyAmIHBlcmtzIjoiQmVuZWZpdHMgJiBwZXJrcyIsIkNhcmVlciBkZXZlbG9wbWVudCI6IkNhcmVlciBkZXZlbG9wbWVudCIsIldvcmsvTGlmZSBiYWxhbmNlIjoiV29yay9MaWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIk1hbmFnZW1lbnQiOiJNYW5hZ2VtZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZW5lZml0cyAmIHBlcmtzIjoiQmVuZWZpdHMgJiBwZXJrcyIsIkNhcmVlciBkZXZlbG9wbWVudCI6IkNhcmVlciBkZXZlbG9wbWVudCIsIldvcmsvTGlmZSBiYWxhbmNlIjoiV29yay9MaWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIk1hbmFnZW1lbnQiOiJNYW5hZ2VtZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZW5lZml0cyAmIHBlcmtzIjoiQmVuZWZpdHMgJiBwZXJrcyIsIkNhcmVlciBkZXZlbG9wbWVudCI6IkNhcmVlciBkZXZlbG9wbWVudCIsIldvcmsvTGlmZSBiYWxhbmNlIjoiV29yay9MaWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIk1hbmFnZW1lbnQiOiJNYW5hZ2VtZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZW5lZml0cyAmIHBlcmtzIjoiQmVuZWZpdHMgJiBwZXJrcyIsIkNhcmVlciBkZXZlbG9wbWVudCI6IkNhcmVlciBkZXZlbG9wbWVudCIsIldvcmsvTGlmZSBiYWxhbmNlIjoiV29yay9MaWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIk1hbmFnZW1lbnQiOiJNYW5hZ2VtZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZW5lZml0cyAmIHBlcmtzIjoiQmVuZWZpdHMgJiBwZXJrcyIsIkNhcmVlciBkZXZlbG9wbWVudCI6IkNhcmVlciBkZXZlbG9wbWVudCIsIldvcmsvTGlmZSBiYWxhbmNlIjoiV29yay9MaWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIk1hbmFnZW1lbnQiOiJNYW5hZ2VtZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZW5lZml0cyAmIHBlcmtzIjoiQmVuZWZpdHMgJiBwZXJrcyIsIkNhcmVlciBkZXZlbG9wbWVudCI6IkNhcmVlciBkZXZlbG9wbWVudCIsIldvcmsvTGlmZSBiYWxhbmNlIjoiV29yay9MaWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIk1hbmFnZW1lbnQiOiJNYW5hZ2VtZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZW5lZml0cyAmIHBlcmtzIjoiQmVuZWZpdHMgJiBwZXJrcyIsIkNhcmVlciBkZXZlbG9wbWVudCI6IkNhcmVlciBkZXZlbG9wbWVudCIsIldvcmsvTGlmZSBiYWxhbmNlIjoiV29yay9MaWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIk1hbmFnZW1lbnQiOiJNYW5hZ2VtZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZW5lZml0cyAmIHBlcmtzIjoiQmVuZWZpdHMgJiBwZXJrcyIsIkNhcmVlciBkZXZlbG9wbWVudCI6IkNhcmVlciBkZXZlbG9wbWVudCIsIldvcmsvTGlmZSBiYWxhbmNlIjoiV29yay9MaWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIk1hbmFnZW1lbnQiOiJNYW5hZ2VtZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZW5lZml0cyAmIHBlcmtzIjoiQmVuZWZpdHMgJiBwZXJrcyIsIkNhcmVlciBkZXZlbG9wbWVudCI6IkNhcmVlciBkZXZlbG9wbWVudCIsIldvcmsvTGlmZSBiYWxhbmNlIjoiV29yay9MaWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIk1hbmFnZW1lbnQiOiJNYW5hZ2VtZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZW5lZml0cyAmIHBlcmtzIjoiQmVuZWZpdHMgJiBwZXJrcyIsIkNhcmVlciBkZXZlbG9wbWVudCI6IkNhcmVlciBkZXZlbG9wbWVudCIsIldvcmsvTGlmZSBiYWxhbmNlIjoiV29yay9MaWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIk1hbmFnZW1lbnQiOiJNYW5hZ2VtZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZW5lZml0cyAmIHBlcmtzIjoi4Liq4Lin4Lix4Liq4LiU4Li04LiB4Liy4Lij4LmB4Lil4Liw4Lic4Lil4Lib4Lij4Liw4LmC4Lii4LiK4LiZ4LmMIiwiQ2FyZWVyIGRldmVsb3BtZW50Ijoi4LiE4Lin4Liy4Lih4LiB4LmJ4Liy4Lin4Lir4LiZ4LmJ4Liy4LiX4Liy4LiH4Lit4Liy4LiK4Li14LieIiwiV29yay9MaWZlIGJhbGFuY2UiOiLguYPguJnguYHguJnguKfguITguLTguJTguITguKfguLLguKHguKrguKHguJTguLjguKXguYPguJnguIrguLXguKfguLTguJXguYHguKXguLDguIHguLLguKPguJfguY3guLLguIfguLLguJnguILguK3guIfguYDguKPguLIiLCJXb3JraW5nIGVudmlyb25tZW50Ijoi4Lia4Lij4Lij4Lii4Liy4LiB4Liy4Lio4LmD4LiZ4LiB4Liy4Lij4LiX4Liz4LiH4Liy4LiZIiwiTWFuYWdlbWVudCI6IuC4geC4suC4o+C4iOC4seC4lOC4geC4suC4oyIsIkRpdmVyc2l0eSAmIGVxdWFsIG9wcG9ydHVuaXR5Ijoi4LiE4Lin4Liy4Lih4Lir4Lil4Liy4LiB4Lir4Lil4Liy4Lii4LmB4Lil4Liw4LmC4Lit4LiB4Liy4Liq4LiX4Li14LmI4LmA4LiX4LmI4Liy4LmA4LiX4Li14Lii4Lih4LiB4Lix4LiZIn0=!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZW5lZml0cyAmIHBlcmtzIjoi4Liq4Lin4Lix4Liq4LiU4Li04LiB4Liy4Lij4LmB4Lil4Liw4Lic4Lil4Lib4Lij4Liw4LmC4Lii4LiK4LiZ4LmMIiwiQ2FyZWVyIGRldmVsb3BtZW50Ijoi4LiE4Lin4Liy4Lih4LiB4LmJ4Liy4Lin4Lir4LiZ4LmJ4Liy4LiX4Liy4LiH4Lit4Liy4LiK4Li14LieIiwiV29yay9MaWZlIGJhbGFuY2UiOiLguYPguJnguYHguJnguKfguITguLTguJTguITguKfguLLguKHguKrguKHguJTguLjguKXguYPguJnguIrguLXguKfguLTguJXguYHguKXguLDguIHguLLguKPguJfguY3guLLguIfguLLguJnguILguK3guIfguYDguKPguLIiLCJXb3JraW5nIGVudmlyb25tZW50Ijoi4Lia4Lij4Lij4Lii4Liy4LiB4Liy4Lio4LmD4LiZ4LiB4Liy4Lij4LiX4Liz4LiH4Liy4LiZIiwiTWFuYWdlbWVudCI6IuC4geC4suC4o+C4iOC4seC4lOC4geC4suC4oyIsIkRpdmVyc2l0eSAmIGVxdWFsIG9wcG9ydHVuaXR5Ijoi4LiE4Lin4Liy4Lih4Lir4Lil4Liy4LiB4Lir4Lil4Liy4Lii4LmB4Lil4Liw4LmC4Lit4LiB4Liy4Liq4LiX4Li14LmI4LmA4LiX4LmI4Liy4LmA4LiX4Li14Lii4Lih4LiB4Lix4LiZIn0=!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZW5lZml0cyAmIHBlcmtzIjoiVHVuamFuZ2FuICYga2V1bnR1bmdhbiIsIkNhcmVlciBkZXZlbG9wbWVudCI6IlBlbmdlbWJhbmdhbiBrYXJpZXIiLCJXb3JrL0xpZmUgYmFsYW5jZSI6Iktlc2VpbWJhbmdhbiBoaWR1cCBkYW4gcGVrZXJqYWFuIiwiV29ya2luZyBlbnZpcm9ubWVudCI6IkxpbmdrdW5nYW4ga2VyamEiLCJNYW5hZ2VtZW50IjoiUGVuZ2Vsb2xhYW4iLCJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSI6IktlYmVyYWdhbWFuICYga2VzZW1wYXRhbiB5YW5nIHNhbWEifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZW5lZml0cyAmIHBlcmtzIjoiVHVuamFuZ2FuICYga2V1bnR1bmdhbiIsIkNhcmVlciBkZXZlbG9wbWVudCI6IlBlbmdlbWJhbmdhbiBrYXJpZXIiLCJXb3JrL0xpZmUgYmFsYW5jZSI6Iktlc2VpbWJhbmdhbiBoaWR1cCBkYW4gcGVrZXJqYWFuIiwiV29ya2luZyBlbnZpcm9ubWVudCI6IkxpbmdrdW5nYW4ga2VyamEiLCJNYW5hZ2VtZW50IjoiUGVuZ2Vsb2xhYW4iLCJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSI6IktlYmVyYWdhbWFuICYga2VzZW1wYXRhbiB5YW5nIHNhbWEifQ==!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZW5lZml0cyAmIHBlcmtzIjoiQmVuZWZpdHMgJiBwZXJrcyIsIkNhcmVlciBkZXZlbG9wbWVudCI6IkNhcmVlciBkZXZlbG9wbWVudCIsIldvcmsvTGlmZSBiYWxhbmNlIjoiV29yay9MaWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIk1hbmFnZW1lbnQiOiJNYW5hZ2VtZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZW5lZml0cyAmIHBlcmtzIjoiQmVuZWZpdHMgJiBwZXJrcyIsIkNhcmVlciBkZXZlbG9wbWVudCI6IkNhcmVlciBkZXZlbG9wbWVudCIsIldvcmsvTGlmZSBiYWxhbmNlIjoiV29yay9MaWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIk1hbmFnZW1lbnQiOiJNYW5hZ2VtZW50IiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgJiBlcXVhbCBvcHBvcnR1bml0eSJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZW5lZml0cyAmIHBlcmtzIjoiW+G6nuG6veG6veG6veC4geC4teC5ieG6veG6veG6vcaSw6zDrMOs4bmvxaEgJiDGpeG6veG6veG6vcWZxLfFoV0iLCJDYXJlZXIgZGV2ZWxvcG1lbnQiOiJbw4fEg8SDxIPFmeG6veG6veG6veG6veG6veG6vcWZIMaM4bq94bq94bq94bm94bq94bq94bq9xprDtsO2w7bGpW3MguG6veG6veG6veC4geC4teC5ieG5r10iLCJXb3JrL0xpZmUgYmFsYW5jZSI6IlvFtMO2w7bDtsWZxLcvxYHDrMOsw6zGkuG6veG6veG6vSDDn8SDxIPEg8aaxIPEg8SD4LiB4Li14LmJw6fhur3hur3hur1dIiwiV29ya2luZyBlbnZpcm9ubWVudCI6IlvFtMO2w7bDtsWZxLfDrMOsw6zguIHguLXguYnEoyDhur3hur3hur3guIHguLXguYnhub3DrMOsw6zFmcO2w7bDtuC4geC4teC5iW3MguG6veG6veG6veC4geC4teC5ieG5r10iLCJNYW5hZ2VtZW50IjoiW+G5gsSDxIPguIHguLXguYnEg8SDxKPhur3hur1tzILhur3hur3guIHguLXguYnhua9dIiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJb4biKw6zDrOG5veG6veG6vcWZxaHDrMOs4bmvw73DvSAmIOG6veG6vXHMh8eax5rEg8SDxpogw7bDtsalxqXDtsO2xZnhua/Hmsea4LiB4Li14LmJw6zDrOG5r8O9w71dIn0=!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZW5lZml0cyAmIHBlcmtzIjoiW+G6nuG6veG6veG6veC4geC4teC5ieG6veG6veG6vcaSw6zDrMOs4bmvxaEgJiDGpeG6veG6veG6vcWZxLfFoV0iLCJDYXJlZXIgZGV2ZWxvcG1lbnQiOiJbw4fEg8SDxIPFmeG6veG6veG6veG6veG6veG6vcWZIMaM4bq94bq94bq94bm94bq94bq94bq9xprDtsO2w7bGpW3MguG6veG6veG6veC4geC4teC5ieG5r10iLCJXb3JrL0xpZmUgYmFsYW5jZSI6IlvFtMO2w7bDtsWZxLcvxYHDrMOsw6zGkuG6veG6veG6vSDDn8SDxIPEg8aaxIPEg8SD4LiB4Li14LmJw6fhur3hur3hur1dIiwiV29ya2luZyBlbnZpcm9ubWVudCI6IlvFtMO2w7bDtsWZxLfDrMOsw6zguIHguLXguYnEoyDhur3hur3hur3guIHguLXguYnhub3DrMOsw6zFmcO2w7bDtuC4geC4teC5iW3MguG6veG6veG6veC4geC4teC5ieG5r10iLCJNYW5hZ2VtZW50IjoiW+G5gsSDxIPguIHguLXguYnEg8SDxKPhur3hur1tzILhur3hur3guIHguLXguYnhua9dIiwiRGl2ZXJzaXR5ICYgZXF1YWwgb3Bwb3J0dW5pdHkiOiJb4biKw6zDrOG5veG6veG6vcWZxaHDrMOs4bmvw73DvSAmIOG6veG6vXHMh8eax5rEg8SDxpogw7bDtsalxqXDtsO2xZnhua/Hmsea4LiB4Li14LmJw6zDrOG5r8O9w71dIn0=!"
        )
      )
      });
  
      export { translations as default };
    