// istanbull-ignore-file
// this is a mock file for testing
import type { CandidateService, GetCandidateResult } from './candidateService';

export const createCandidateServiceMock = (): CandidateService => {
  const getCandidate = async (): Promise<GetCandidateResult> => ({
    id: 12345,
    firstName: 'Bob',
    lastName: 'Smith',
    email: 'bob.smith@seek.com.au',
  });

  return { getCandidate };
};
