import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import type { RouteObject } from 'react-router-dom';

import type { Logger } from '@seek/libs-shared';

import type { Services } from '../shared/services';

import { CompanyProfileLayout } from './layouts/companyProfileLayout/CompanyProfileLayout';
import { companyProfileLoader } from './layouts/companyProfileLayout/companyProfileLoader';
import { aboutLoader } from './pages/about/aboutLoader';
import { cultureLoader } from './pages/culture/cultureLoader';
import { homePageLoader } from './pages/home/homePageLoader';
import { jobsLoader } from './pages/jobs/jobsLoader';
import { reviewsLoader } from './pages/reviews/reviewsLoader';
import { CompanyProfilesPage } from './paths';
import type { AppConfig } from '../shared/config';
import { SearchPage } from './pages/search/SearchPage';
import { HomePageContainer } from './pages/home/HomePageContainer';

interface RouteProps {
  client: ApolloClient<NormalizedCacheObject>;
  config: AppConfig;
  services: Services;
  logger: Logger;
}

export const createCompanyProfilesRoutes = (
  props: RouteProps,
): RouteObject[] => [
  {
    path: CompanyProfilesPage.Home,
    id: 'company-profiles-root',
    children: [
      {
        index: true,
        element: <HomePageContainer />,
        id: CompanyProfilesPage.Home,
        loader: homePageLoader(props),
      },
      {
        path: CompanyProfilesPage.About,
        id: 'company-profile-root',
        loader: companyProfileLoader(props),
        children: [
          {
            index: true,
            element: <CompanyProfileLayout page={CompanyProfilesPage.About} />,
            loader: aboutLoader(props),
            id: CompanyProfilesPage.About,
          },
          {
            path: CompanyProfilesPage.Culture,
            element: (
              <CompanyProfileLayout page={CompanyProfilesPage.Culture} />
            ),
            loader: cultureLoader(props),
            id: CompanyProfilesPage.Culture,
          },
          {
            path: CompanyProfilesPage.Jobs,
            element: <CompanyProfileLayout page={CompanyProfilesPage.Jobs} />,
            loader: jobsLoader(props),
            id: CompanyProfilesPage.Jobs,
          },
          {
            path: CompanyProfilesPage.Reviews,
            element: (
              <CompanyProfileLayout page={CompanyProfilesPage.Reviews} />
            ),
            loader: reviewsLoader(props),
            id: CompanyProfilesPage.Reviews,
          },
        ],
      },
      {
        path: CompanyProfilesPage.CompanySearch,
        element: <SearchPage />,
        id: CompanyProfilesPage.CompanySearch,
      },
    ],
  },
];
