import type { AppConfig } from '../../config';
import type { GetJobsListResponse } from './types';

import { buildJobSearchUrlForSearch, mapJobsModel } from './mapper';
import { creatRestClient, type Logger, type Jobs } from '@seek/libs-shared';

const DEFAULT_PAGE_SIZE = 10;

interface GetJobsListPayload {
  organisationId: string;
  page: number;
  country: string;
}

export interface SearchService {
  getJobsList: ({
    organisationId,
    page,
    country,
  }: GetJobsListPayload) => Promise<Jobs>;
}

export const createSearchService = ({
  config,
  logger,
}: {
  config: AppConfig;
  logger: Logger;
}): SearchService => {
  const { searchApiBaseUrl } = config;
  const { get } = creatRestClient(searchApiBaseUrl, logger, {
    'User-Agent': 'bx-frontends',
  });

  const getJobsList = async ({
    organisationId,
    page,
    country,
  }: GetJobsListPayload): Promise<Jobs> => {
    const jobsUrl = buildJobSearchUrlForSearch({
      organisationId,
      page,
      pageSize: DEFAULT_PAGE_SIZE,
      country,
      source: 'COMPANY',
    });
    const res = await get<GetJobsListResponse>(jobsUrl);

    return mapJobsModel(res?.data);
  };

  return {
    getJobsList,
  };
};
