import { Box, Stack } from 'braid-design-system';
import { useEffect } from 'react';
import type { ReviewsTabModel } from './reviewsTabModel';

import {
  type Reviews,
  useConfig,
  useAppContext,
  type SortOrder,
} from '@seek/libs-shared';
import { trackViewEvents } from '../../../shared/trackViewEvents';

import type { FlagReviewPayload } from '../../../';
import {
  ReviewsEmptyState,
  ReviewsEmptyStateType,
} from '../../../companyReviews/reviewsEmptyState/ReviewsEmptyState';
import { ReviewsSection } from '../../../companyReviews/reviewsSection/ReviewsSection';
import { ReviewsSummarySection } from '../../../companyReviews/reviewsSummarySection/ReviewsSummarySection';
import { useAuth } from '../../../shared/providers/authProvider';
import { AUTHENTICATION_TYPES } from '@seek/apac-candidate-header-footer/types';
interface ReviewsPageProps {
  model?: ReviewsTabModel;
  showReviewReplyNotice: boolean;
  getReviews: ({
    companyId,
    page,
    sortOrder,
    perPage
  }: {
    companyId: string;
    page: number;
    sortOrder: SortOrder;
    perPage?: number;
  }) => Promise<Reviews>;
  upvoteReview: ({
    reviewId,
    companyId,
  }: {
    reviewId: string;
    companyId: string;
  }) => Promise<void>;
  flagReview: (
    companyId: string,
    reviewId: string,
    payload: FlagReviewPayload,
  ) => Promise<boolean>;
  trackWriteAReviewClicked: () => void;
  trackReviewsPaginationClicked: ({
    pageNumber,
  }: {
    pageNumber: number;
  }) => void;
  trackSortOrderChanged: ({ sortOrder }: { sortOrder: SortOrder }) => void;
  trackSignInClicked: () => void;
  trackRegisterClicked: () => void;
}

export function ReviewsPage({
  model,
  getReviews,
  upvoteReview,
  flagReview,
  trackWriteAReviewClicked,
  trackReviewsPaginationClicked,
  trackSortOrderChanged,
  trackSignInClicked,
  trackRegisterClicked
}: ReviewsPageProps) {
  const config = useConfig();
  const { companyId, companyName, ...context } = useAppContext();
  const { authenticationStatus } = useAuth();

  useEffect(() => {
    if (companyId && companyName&&
      authenticationStatus !== AUTHENTICATION_TYPES.AUTH_PENDING)
      trackViewEvents.reviewsPage({
        config,
        context: { ...context, companyId, companyName },
      });
  }, [companyId, companyName, authenticationStatus]);

  if (!model?.reviews?.paging?.total || model?.reviews?.paging?.total === 0) {
    return (
      <Box paddingY="xxlarge" paddingX={['medium', 'none']}>
        <ReviewsEmptyState
          companyName={model?.reviewSummary?.name}
          trackWriteAReviewClicked={trackWriteAReviewClicked}
          type={ReviewsEmptyStateType.NO_LIST}
        />
      </Box>
    );
  }

  return (
    <Stack space={['none', 'large']}>
      {model?.reviewSummary && (
        <ReviewsSummarySection
          model={model.reviewSummary}
          trackWriteAReviewClicked={trackWriteAReviewClicked}
        />
      )}
      {model?.reviews && (
        <ReviewsSection
          model={model.reviews}
          getReviews={getReviews}
          upvoteReview={upvoteReview}
          flagReview={flagReview}
          trackWriteAReviewClicked={trackWriteAReviewClicked}
          trackReviewsPaginationClicked={trackReviewsPaginationClicked}
          trackSortOrderChanged={trackSortOrderChanged}
          trackSignInClicked={trackSignInClicked}
          trackRegisterClicked={trackRegisterClicked}
          mode="view"
        />
      )}
    </Stack>
  );
}
